import React from 'react';

const DefaultCompanySvg = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
        <path d="M16 84H80C84.4184 84 88 80.4184 88 76V32C88 27.5817 84.4184 24 80 24H44L37.1875 13.7812C36.4456 12.6684 35.1967 12 33.8593 12H16C11.5817 12 8 15.5817 8 20V76C8 80.4184 11.5817 84 16 84Z" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M48 40V64M36 52H60" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>

  );
};

export default DefaultCompanySvg;