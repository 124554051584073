import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICorp, IEmployees} from "./types";

interface CorporationState {
  isLoading: boolean;
  isError: '';
  corp: ICorp;
  employees: IEmployees[];
  search_text: string;
}

export interface ISearchPayload {
  data: IEmployees[],
  search: string,
}

const initialState: CorporationState = {
  isError: "",
  isLoading: false,
  employees: [],
  search_text: "",
  corp: {
    id: 0,
    slug:'',
    preview: "",
    slogan: "",
    is_gradient: false,
    email: "",
    name: "",
    menu_buttons: [],
    images: [],
    videos: [],
    locations: [],
    description: "",
  },
}

export const corpSlice = createSlice({
  name: 'corporation/get',
  initialState,
  reducers: {
    getCorp(state) {
      state.isLoading = true
    },
    getCorpSuccess(state, action: PayloadAction<ICorp>) {
      state.isLoading = false
      state.corp = action.payload
    },
    getEmployees(state, action: PayloadAction<IEmployees[]>) {
      state.isLoading = false
      state.employees = action.payload
    },
    getSearchEmployees(state, {payload}: PayloadAction<ISearchPayload>) {
      state.isLoading = false
      state.employees = payload.data
      state.search_text = payload.search
    },
    getCorpError(state) {
      state.isLoading = false
    },
  }
})

export const CorpReducer = corpSlice.reducer