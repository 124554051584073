import React, {useEffect, useRef, useState} from 'react';
import {Box, Flex, Image, Text, useDisclosure, useOutsideClick} from "@chakra-ui/react";
import PlayGif from "../../assets/gif/play.gif";
import {IContactButton} from "../../models/profile";
import {AiOutlinePlus} from "react-icons/ai";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {getSelectedIndex} from "./CustomReducer/menuButton.slice";
import CustomModal from "./custom-modal";
import {navigateToUrl} from "../../config/helpers";
import {INTERFACE_WIDTH} from "../../config/_variables";
import {addMenuButton} from "./CustomReducer/actions";
import {useLocation} from 'react-router-dom';


interface Props {
  btn: IContactButton | null;
  openMenuButtons: () => void;
  isMenu: boolean;
  className: string;
  index: number;
  isAdmin?: boolean
  card_id?: string
}

const GifPlay: JSX.Element = <Image src={PlayGif} alt='play' rounded='50%' zIndex="2"/>

const CustomCard = ({btn, openMenuButtons, isMenu, className, index, isAdmin=false, card_id = ''}: Props) => {
  const dispatch = useAppDispatch()
  const [isGif, setGif] = useState<null | JSX.Element>(null)
  const {menu_buttons} = useAppSelector(state => state.ProfileReducer.user)
  const location = useLocation()

  // Placement modal parameters
  const {isOpen: isPlacement, onOpen: openPlacement, onClose: closePlacement} = useDisclosure()

  // Additional modal parameters
  const {isOpen, onOpen, onClose} = useDisclosure()


  const openPlacementModal = () => {
    if (isMenu) {
      openPlacement()
      dispatch(getSelectedIndex(index))
    }
  }

  const handleClick = () => {
    if (isMenu) {
      if (btn?.value && btn.value.length > 1) {
        onOpen()
      } else if (btn) {
        navigateToUrl(btn.offer + btn.value.join('').trim(), btn.name === 'Mobile')
      }
    }
    openMenuButtons()
  }

  const ref = useRef<any>(null)
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  // submit button link
  const onSubmit = (button: IContactButton, values: string[], closeModals: () => void) => {
    dispatch(addMenuButton(button, values, Number(btn?.id), closeModals, isAdmin, card_id))
  }


  useEffect(() => {
    if (!isMenu && index === 2) {
      setGif(GifPlay)
    } else setGif(null)
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenu])
  return (
    <Flex
      flexDirection='column' alignItems='center' position='relative'>
      {<Box
        display={location.pathname.includes("user") ? "none" : "block"}
        onClick={openPlacementModal} position='absolute'
        transform={!isMenu ? 'scale(0)' : 'scale(1)'}
        transition='.5s transform'
        zIndex="2"
        top={{sm: '-2px', base: '-5px'}} right={{sm: '2px', base: '-6px'}} cursor='grab'
        color='#0080DD' fontSize='1.8rem'
      ><AiOutlinePlus/></Box>}
      <Flex
        onClick={handleClick}
        className={`custom-btn ${className}`}
        rounded='50%' p='1px' mx={{sm: 2, base: '0'}}
        w='64px' h='61.43px' cursor='pointer'
        bg='linear-gradient(#C8C8C8, #49494990, #29292975, #FFFFFF47)'
        position="relative"
        _after={{
          content: "''",
          position: "absolute",
          top: "1px",
          bottom: "1px",
          left: "1px",
          right: "1px",
          zIndex: "1",
          borderRadius: "50%",
          background: "#000000"
        }}
      >
        {!!isGif ? isGif : <Flex
          justifyContent='center'
          alignItems='center'
          rounded='50%' bg='#000' _active={{bg: '#171717'}}
          w='100%' h='100%' color='#FFFFFF' fontSize='1.4rem'
        >
          <span style={{zIndex: "2"}}>
            {isMenu && btn?.icon}
          </span>
        </Flex>}
      </Flex>
      {isMenu &&
        <Text transition='0.2s' fontFamily='Poppins' textAlign='center' w='full' fontWeight='400' mt={2} fontSize='10px'
              color='#E1E1E1'>{btn?.name}</Text>}

      <Flex w='100%' hidden={!isOpen}>
        <Box
          mx='auto' maxW={INTERFACE_WIDTH} position='fixed'
          left='0' bottom='188px' right='0' px={4} zIndex='30'
        >
          <Box bg='#2E2E2E' w='100%' ref={ref} rounded='16px' px='9px' pb='14.93px'>
            {btn?.value.map((contact, idx) => (
              <Flex
                onClick={() => navigateToUrl(btn?.offer + contact.trim(), btn.name === 'Mobile')}
                key={idx}
                justifyContent='space-between' alignItems='center'
                h='90px' borderBottom='1px solid #3D3D3D' cursor='pointer'
                _active={{bg: '#3D3D3D'}} rounded='10px'
              >
                <Box
                  borderRight='1px solid #3D3D3D' minW='130px'
                  pr={5} pl='2.5' h='33px' noOfLines={1} lineHeight='27px'
                  fontFamily='Poppins' fontSize='18px' color='#1894EE' fontWeight='400'
                >{btn?.type} {idx + 1}</Box>
                <Text fontFamily='Inter' w='60%' noOfLines={1} fontSize='20px' pr='15px' color='#1894EE'
                      fontWeight='400' lineHeight='24.2px'>
                  {contact}
                </Text>
              </Flex>
            ))}
          </Box>
        </Box>
      </Flex>

      <CustomModal
        onSubmit={onSubmit}
        isOpen={isPlacement}
        onClose={closePlacement}
      />

    </Flex>
  );
};

export default CustomCard;