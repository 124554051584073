import { Box, Skeleton } from "@chakra-ui/react";
import React from "react";

const SkeletonBtns = () => {
  return (
    <Box pt="5px" flexWrap="wrap" display="flex" justifyContent="space-between">
      <Skeleton w="49.5%" mb="5px" height="3.5rem" borderRadius={5} />
      <Skeleton w="49.5%" mb="5px" height="3.5rem" borderRadius={5} />
      <Skeleton w="49.5%" mb="5px" height="3.5rem" borderRadius={5} />
      <Skeleton w="49.5%" mb="5px" height="3.5rem" borderRadius={5} />
    </Box>
  );
};

export default SkeletonBtns;
