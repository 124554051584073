import React from 'react';
import {Heading} from "@chakra-ui/react";

const TitleComponents = ({children}: {children: JSX.Element | string}) => {
  return (
    <Heading fontFamily='Poppins' py={2} fontWeight='500' textAlign='center'
             fontSize='16px' textTransform='uppercase' w='full' letterSpacing='5%' mx='auto' color='#FFFFFF'
    >{children}</Heading>
  );
};

export default TitleComponents;