import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import React, { Component, ReactNode } from "react";

type Props = {
  text: string;
  onClick?: () => void;
  icon?: any;
};

class Btn extends Component<Props> {
  render(): ReactNode {
    const { text, onClick, icon } = this.props;
    return (
      <Button
        w="100%"
        borderRadius="10px"
        position="relative"
        fontFamily="Poppins"
        fontWeight="500"
        fontSize="17px"
        color="#424242"
        bg="#EDEDF2"
        h="50px"
        colorScheme=""
        onClick={onClick}
      >
        {icon && (
          <Box position="absolute" zIndex={1} top="25%" left={3}>
            {icon}
          </Box>
        )}
        {text}
      </Button>
    );
  }
}

export default Btn;
