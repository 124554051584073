import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

//local
import { SmsSvg } from "../../assets/svg/SmsSvg";
import { DefButton } from "../ui";
import PRIVATE_API, { PUBLIC_API } from "../../api";
import { useLoginInterface } from "../../redux/loginFormInterface/hook";
import { useAppDispatch } from "../../redux/store";
import { setIsMessageEmail } from "../../redux/loginFormInterface/action";
import { toastCreator } from "../../config/toast";

const SendEmail: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);

  const { isFirstTime, isForgotPsw } = useLoginInterface();
  const dispatch = useAppDispatch();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setEmail(value);
  };

  const updateEmail = () => {
    setLoad(true);
    PRIVATE_API.patch("api/v1/users/profile/", { email })
      .then((res) => {
        resetLink();
      })
      .catch((e) => {
        setLoad(false);
        toastCreator("error", e.message);
      });
  };

  const resetLink = () => {
    setLoad(true);
    PUBLIC_API.post("api/v1/users/profile/reset_link/", {
      email,
    })
      .then(({ data }) => {
        setLoad(false);
        dispatch(setIsMessageEmail(true));
      })
      .catch((e) => {
        setLoad(false);
        toastCreator("error", e.message);
      });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    !!isFirstTime ? updateEmail() : isForgotPsw && resetLink();
  };

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: "1.6rem" }}>
      <InputGroup mb="30px">
        <InputLeftElement
          w="3.2rem"
          minH="100%"
          p="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box pr="10px">
            <SmsSvg />
          </Box>
        </InputLeftElement>
        <Input
          type="email"
          onChange={handleChange}
          required
          bg="#353535"
          border="none"
          borderRadius="30px"
          placeholder="Email"
          fontFamily="Poppins"
          _placeholder={{
            color: "#A8A8A8",
            fontSize: "15px",
          }}
          color="#FFFFFF"
          py="25px"
        />
      </InputGroup>
      <DefButton type="submit" isLoading={load}>
        Send
      </DefButton>
    </form>
  );
};

export default SendEmail;
