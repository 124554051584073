import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IStateMenuBtnAnimate {
  isMenuBtnAnimate: boolean;
}

const initialState: IStateMenuBtnAnimate = {
  isMenuBtnAnimate: false,
};

export const menuBtnAnimateSlice = createSlice({
  name: "menuBtnAnimateSlice",
  initialState,
  reducers: {
    getMenuBtnAnimation(state, action: PayloadAction<boolean>) {
      state.isMenuBtnAnimate = action.payload;
    },
  },
});

export const MenuBtnAnimateReducer = menuBtnAnimateSlice.reducer;
