import { Box } from "@chakra-ui/layout";
import React, { FC } from "react";
import { Btn } from "../ui";
import { CallIconSvg } from "../../assets/svg/CallIconSvg";
import { WhatsappSvg } from "../../assets/svg/WhatsappSvg";
import { EmailIconSvg } from "../../assets/svg/EmailIconSvg";
import {navigateToUrl} from "../../config/helpers";
import {EMAIL_ADDRESS, TELL_NUMBER, WHATSAPP_NUMBER} from "../../config/admin";

const TechnicalSupport: FC = () => {
  const btns = [
    {
      text: "Call",
      onClick: () => navigateToUrl('tel:' + TELL_NUMBER, true),
      icon: <CallIconSvg />,
    },
    {
      text: "WhatsApp",
      onClick: () => navigateToUrl('https://wa.me/' + WHATSAPP_NUMBER),
      icon: <WhatsappSvg />,
    },
    {
      text: "Email",
      onClick: () => navigateToUrl('mailto:'+ EMAIL_ADDRESS),
      icon: <EmailIconSvg />,
    },
  ];

  return (
    <Box pb="10px">
      {btns.map((el, idx) => (
        <Box key={idx} mb="20px">
          <Btn text={el.text} icon={el.icon} onClick={el.onClick} />
        </Box>
      ))}
    </Box>
  );
};

export default TechnicalSupport;
