import React, { FC, useEffect } from "react";
import { Box } from "@chakra-ui/react";

//local
import VideoMobile from "../../../../assets/video/bgVideo.mp4";
import VideoDesctop from "../../../../assets/video/bgVideoComp.mp4";

const VideoComponent: FC = () => {
  useEffect(() => {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let videoSource = document.getElementById(
      "videoSource"
    ) as HTMLSourceElement;
    let heroVideo = document.getElementById("heroVideo") as HTMLMediaElement;

    if (isMobile) {
      videoSource.src = VideoMobile;
    } else {
      videoSource.src = VideoDesctop;
    }

    // Обязательно обновляем видео после изменения src
    heroVideo.load();
  }, []);
  return (
    <Box className="video__hero" height={{ md: "768px", base: "700px" }}>
      <video
        height="100%"
        width="100%"
        autoPlay
        muted
        loop
        playsInline
        id="heroVideo"
      >
        <source src="" type="video/mp4" id="videoSource" />
      </video>
    </Box>
  );
};

export default VideoComponent;
