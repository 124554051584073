import {AppDispatch} from "../../../redux/store";
import {customSlice} from "./customSlice";
import PRIVATE_API from "../../../api";
import {IContactButton, IMenuButtonServer, RootMenuButtons} from "../../../models/profile";
import {profileSlice} from "../../../pages/interface/editing/Profile/ProfileReducer/profileSlice";
import {menuButtonsSLice} from "./menuButton.slice";
import {toastCreator} from "../../../config/toast";
import PRIVATE_API_ADMIN from "../../../api/admin/API";

export const addMenuButton = (button: IContactButton, value: string[], main_id: number, onClose: () => void, isAdmin: boolean, card_id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(customSlice.actions.addButton())
    const result = value.map(el => button.href + (button.type_id === 6 ? el.slice(1) : el))
    const serverData = {
      type_: button.type_id,
      value: result[0],
      name: button.name,
    }
    const {data} = isAdmin ?
      main_id ?
        await PRIVATE_API_ADMIN.put(`api/v1/users/profile/buttons/${main_id}/`, {...serverData})
        : await PRIVATE_API_ADMIN.post(`api/v1/users/profile/buttons/?card_id=${card_id}`, {
          ...serverData,
        // is_company: true
        }) :
      main_id ?
        await PRIVATE_API.put(`api/v1/users/profile/buttons/${main_id}/`, serverData)
        : await PRIVATE_API.post('api/v1/users/profile/buttons/', serverData)
    data.value = JSON.stringify([data.value]);

    dispatch(customSlice.actions.addedButton(null))
    dispatch(menuButtonsSLice.actions.getMenuButtonSuccess({button, data}))
    dispatch(getMenuButtons())
    toastCreator('success')
    onClose()
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(customSlice.actions.addedButton(e.response?.data?.message))
  }
}


export const getMenuButtons = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.getProfile())
    const response = await PRIVATE_API.get<RootMenuButtons>(
      'api/v1/users/profile/buttons/'
    )
    dispatch(menuButtonsSLice.actions.getMenuButtonFromProfile(response.data))
    dispatch(profileSlice.actions.getMenuButtons(response.data))
  } catch (e: any) {
    dispatch(profileSlice.actions.getProfileError(e.response?.data?.message))
  }
}
