export const AccountRequired = () => {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9998 16.5694C19.046 16.5694 20.7047 14.8749 20.7047 12.7847C20.7047 10.6945 19.046 9 16.9998 9C14.9537 9 13.2949 10.6945 13.2949 12.7847C13.2949 14.8749 14.9537 16.5694 16.9998 16.5694Z"
        stroke="#4F8BFF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3665 28.3234C25.3665 23.6064 21.6182 19.7773 17.0006 19.7773C12.3831 19.7773 8.63477 23.6064 8.63477 28.3234"
        stroke="#4F8BFF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.805 6.95L6.345 7.925L4.26 8.795L6.36 9.65L5.79 10.655L3.975 9.335L4.215 11.6H3.09L3.315 9.335L1.5 10.67L0.915 9.65L3 8.78L0.915 7.94L1.47 6.935L3.33 8.27L3.09 5.99H4.23L3.975 8.27L5.805 6.95Z"
        fill="#D72727"
      />
    </svg>
  );
};
