import React, {useState} from 'react';
import {Box} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

//local
import PersonGif from "../../../../components/ui/PersonGif";
import {DefButton, EditInput, EditInputFont} from "../../../../components/ui";
import Cropper from "../../../../components/Cropper";
import {IProfileValue, removeAvatar, uploadAvatar} from "./ProfileReducer/profileSlice";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {addProfile} from "./ProfileReducer/actions";
import {getFileName, toEncryption} from "../../../../config/helpers";
import {INNER_WIDTH} from "../../../../config/_variables";
import { setIsMessageEmail } from '../../../../redux/loginFormInterface/action';
import ChangeEmailModal from './ChangeEmailModal';

const ProfileEdit = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {full_name_font, welcome_text_font} = useAppSelector(state => state.FontReducer)
  const {avatar, full_name, designation, welcome_text, is_gradient} =
    useAppSelector(state => state.ProfileReducer.user)

  const {isLoading} = useAppSelector(state => state.PostProfileReducer)
  const [validation, setValid] = useState(false)

  const [value, setValue] = useState<IProfileValue>({
    full_name,
    welcome_text,
    designation,
    is_gradient,
    avatar,
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length < 61){
      setValue({...value, [e.target.name]: e.target.value})
    }
  }
  const handleGradient = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, is_gradient: e.target.checked})
  }
  
  const cleaner = (name: string) => {
    setValue({...value, [name]: ''})
  }

  const uploader = (payload: string) => {
    dispatch(uploadAvatar(payload))
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!value.full_name || !value.welcome_text) {
      setValid(true)
      toast('Please fill in the required fields', {icon: '⚠'})
    } else {
      setValid(false)
      dispatch(addProfile(
        {
          ...value,
          full_name: toEncryption(value.full_name, full_name_font),
          welcome_text: toEncryption(value.welcome_text, welcome_text_font),
          avatar: avatar.length > 0 ? getFileName(avatar) : "",
        },
        navigate))
    }
  }

  return (
    <Box w='full'>
      <Box w='full' rounded='14px' overflow='hidden' h={{sm: '443px', base: INNER_WIDTH / 6 * 5 + 'px'}}
           position='relative'>
        <PersonGif avatar={avatar} is_gradient={value.is_gradient}/>
      </Box>
      <Cropper
        handleGradient={handleGradient}
        removeImage={() => dispatch(removeAvatar())}
        aspect={6 / 5}
        uploader={uploader}
        is_gradient={value.is_gradient}
        image={avatar}
      />
      <form onSubmit={onSubmit}>
        <EditInputFont
          handleChange={handleChange}
          cleaner={cleaner}
          name={'full_name'}
          placeholder={'Full Name'}
          value={value.full_name}
          default_font={full_name_font}
          validation={validation}
        />
        <EditInput
          handleChange={handleChange}
          cleaner={cleaner}
          name={'designation'}
          placeholder={'Designation'}
          value={value.designation}
        />
        <EditInputFont
          handleChange={handleChange}
          cleaner={cleaner}
          name={'welcome_text'}
          placeholder={'Welcome Text'}
          value={value.welcome_text}
          default_font={welcome_text_font}
          validation={validation}
        />
        <Box my={'10'}>
          <DefButton
            isLoading={isLoading}
            type={"submit"}
          >SAVE</DefButton>
          <Box h={4}/>
          <DefButton
            isDisabled={isLoading}
            border={true}
            handleClick={() => dispatch(setIsMessageEmail(true))}
          >
            Generate New Password
          </DefButton>
        </Box>
      </form>      
      <ChangeEmailModal/>
    </Box>
  );
};

export default ProfileEdit;