import React, {useEffect} from 'react';
import {Box, Heading, Skeleton, SkeletonText, Text} from "@chakra-ui/react";
import AdminHeader from "./AdminHeader";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {getCorporationEmployees, getCorpSlug} from "./reducer/actions";
import UserCard from "./UserCard";

const CompanyDashboard = () => {
  const dispatch = useAppDispatch()
  const {isLoading, employees} = useAppSelector(state => state.CorpReducer)
  useEffect(() => {
    dispatch(getCorpSlug())
    dispatch(getCorporationEmployees())
  }, [])
  return (
    <Box>
      <AdminHeader/>
      <Box mt='52.17px'>
        <Heading
          color='#686868'
          fontFamily='Rubik'
          fontWeight='400'
          fontSize='20px'
        >{employees.length} USERS</Heading>
        <Box mt='33.83px'>
          {isLoading && Array(4).fill(null).map((_, idx) => (
            <Skeleton key={idx} h='81px' rounded='14px' mb='10px' startColor={'#FFFFFF1A'} endColor='#B0ADAD1A'/>
          ))}
          {!isLoading && !employees?.length && <Text fontFamily='Poppins' textAlign='center' color="#838383" fontWeight="500" fontSize="18px"
                               lineHeight="27px">employee list not found</Text>}
          {!isLoading &&
            employees?.map(employee =>
              <UserCard key={employee.id} employee={employee}/>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDashboard;