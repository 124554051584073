import React from 'react';
import {Box, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text, useDisclosure} from "@chakra-ui/react";
import {ILead} from "../../../../../redux/leads/interface";
import LeadCardAdmin from "./LeadCardAdmin";

const LeadsModal = ({leads}: { leads: ILead[] }) => {
  const {onOpen, isOpen, onClose} = useDisclosure()
  return (
    <>
      <Text onClick={onOpen} cursor='pointer' color='#FFFFFF' fontSize='20px' fontWeight='400' fontFamily='Rubik'
      >Leads</Text>

      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size='xl'>
        <ModalOverlay/>
        <ModalContent bg='#1C1C1C' px={{md: '30px', base: '4'}} maxH='90%' minH='20%' py='42px' rounded='31px'>
          <ModalCloseButton color='#A8A8A8'/>
          {!leads?.length &&
            <Text
              my='auto'
              fontFamily='Poppins'
              textAlign='center'
              color="#838383"
              fontWeight="500"
              fontSize="18px"
              lineHeight="27px"
            >leeds empty</Text>}
          <Box w='100%' overflowY='auto' className='unscroll'>
            {leads.map((lead, idx) => <LeadCardAdmin lead={lead} key={lead.id} idx={idx}/>)}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LeadsModal;