import React, {useState} from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Image,
  Progress,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {BsDot} from "react-icons/bs";
import {FaTrash} from "react-icons/fa";
import {ICompanyImages} from "../../../../../models/company";
import Placement from "../../../../../components/ui/placement";
import {formatBytes, useFileDecoder} from "../../../../../config/hooks/file-decoder";
import {DefButton, PlacementInput} from "../../../../../components/ui";
import {PiLinkSimpleHorizontal} from "react-icons/pi";
import {deleteImages, uploadImages} from "./reducer/actions";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store";
import AddPhotoButton from "./AddPhotoButton";
import toast from "react-hot-toast";

interface Props {
  el: ICompanyImages,
  inAdmin: boolean
}

const UploadCard = ({el, inAdmin}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const dispatch = useAppDispatch()
  const [handleChangeFile, , , files, removeImage] = useFileDecoder()
  const {isLoading} = useAppSelector(state => state.UploadFileReducer)

  const [value, setValue] = useState<ICompanyImages>(el)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.trim().length <= 125) {
      setValue({...value, [e.target.name]: e.target.value})
    }
  }

  const cleaner = () => {
  }

  const onsubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!files.length){
      toast("please upload a photo", {icon: '⚠'})
    }else {
      dispatch(uploadImages(files, value, onClose, inAdmin))
    }
  }

  const deleteCard = () => {
    dispatch(deleteImages(Number(value.id), inAdmin))
  }

  return (
    <Box>
      <Flex
        justifyContent='space-between' alignItems='center'
        h='43.75px' mt='33px' >
        <Flex
          onClick={onOpen} cursor='pointer'
          alignItems='center' h='100%'>
          <Box h='100%' position='relative' width='79.06px' mr={10}>
            {typeof el.image === 'string' && <GalleryItems imgStr={el.image}/>}
          </Box>
          <Flex flexDirection='column'>
            <Text fontFamily='Poppins' fontWeight='400' fontSize='18px' lineHeight='27px' color='#FFFFFF'
                  noOfLines={1}>{value.button_name || 'Gallery'}</Text>
            <Breadcrumb separator={<BsDot/>} fontFamily='Inter' fontWeight='400' fontSize='13.75px'
                        letterSpacing='0.69px' lineHeight='27.5px' color='#989898'>
              <BreadcrumbItem><BreadcrumbLink
                href='#'>Images</BreadcrumbLink></BreadcrumbItem>
              <BreadcrumbItem><BreadcrumbLink
                href='#'>{typeof el.image === 'string' && el.image.split(',').length}x</BreadcrumbLink></BreadcrumbItem>
              <BreadcrumbItem><BreadcrumbLink
                href='#'>jpeg</BreadcrumbLink></BreadcrumbItem>
            </Breadcrumb>
          </Flex>
        </Flex>
        <Button
          onClick={deleteCard}
          w='37px' h='37px' bg='transparent' color='#D92C41'
          p='9px 10px' fontSize='2rem'
        ><FaTrash/></Button>
      </Flex>

      <Flex hidden={!isOpen} position='fixed' left='0' right='0' top='0' bottom='0' bg='#000000B2'/>
      <Placement isOpen={isOpen} onClose={onClose} title={'photo'}>
        <form onSubmit={onsubmit}>

          {files.map((file: any, idx: number) => (
            <Box mb={5} ml={2} key={idx}>
              <Flex key={idx} h='41.25px' justifyContent='space-between' alignItems='center'>
                <Flex alignItems='center' w='85%'>
                  <Flex flexDirection='column' w='100%'>
                    <Text fontFamily='Poppins' w='100%' fontWeight='400' fontSize='18px' lineHeight='27px' color='#FFFFFF'
                          noOfLines={1}>{file.name}</Text>
                    <Breadcrumb separator={<BsDot/>} fontFamily='Inter' fontWeight='400' fontSize='13.75px'
                                letterSpacing='0.69px' lineHeight='27.5px' color='#989898'>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{file.type.split('/')[0]}</BreadcrumbLink></BreadcrumbItem>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{formatBytes(file.size)}</BreadcrumbLink></BreadcrumbItem>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{file.type.split('/')[1]}</BreadcrumbLink></BreadcrumbItem>
                    </Breadcrumb>
                  </Flex>
                </Flex>
                <Button
                  onClick={() => removeImage(idx)}
                  w='37px' h='37px' bg='transparent' color='#D92C41'
                  p='9px 10px' fontSize='2rem'
                ><FaTrash/></Button>
              </Flex>
              {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
            </Box>
          ))}

          <PlacementInput
            handleChange={handleChange}
            value={value.button_name}
            name={"button_name"}
            placeholder={"Button Name"}
            cleaner={cleaner}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.button_link}
            name={"button_link"}
            placeholder={"Button link"}
            cleaner={cleaner}
            type='url'
            leftIcon={<PiLinkSimpleHorizontal/>}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.description}
            name={"description"}
            as={"textarea"}
            placeholder={"Description"}
            cleaner={cleaner}
            required={false}
          />
          <Flex pt={'19px'}>
            <AddPhotoButton handleChange={handleChangeFile}/>
            <Box w='18px'/>
            <DefButton
              isLoading={isLoading}
              type={"submit"}
            >{value.id ? 'UPDATE' : 'SAVE'}</DefButton>
          </Flex>
        </form>
      </Placement>


    </Box>
  );
};

type IProps = {
  imgStr: string;
};

const GalleryItems = ({imgStr}: IProps) => {
  // Удаляем экранирующие обратные слеши
  const cleanedString = imgStr.replace(/\\/g, "");

  // Преобразуем строку в массив
  const imgList: Array<string> = JSON.parse(cleanedString);
  return (
    <Box border={!imgList.length ? '1px dashed white': ''} rounded='5px' h='100%'>
      {imgList.map((image, idx) => (
        <Box
          key={idx}
          left={`${idx * 10}px`}
          top={`${idx * 5}px`}
          zIndex={idx} rounded='4px'
          position='absolute'
          h='100%' width='79.06px'>
          <Image
            width='100%'
            h='100%' rounded='4px'
            objectFit='cover'
            src={image}
            alt=''/>
        </Box>
      ))}
    </Box>
  )
}

export default UploadCard;