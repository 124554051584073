import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

//local dependencies
import HomeNav from "./HomeNav";
import { Containers } from "../ui";
import CallPhone from "./CallPhone";
import BasketNav from "./BasketNav";

const Header: FC = () => {
  return (
    <header>
      <Containers>
        <Box position="relative" maxW={{ md: "95%", base: "100%" }} mx="auto">
          <Box
            position={{ md: "absolute", base: "fixed" }}
            top={{ base: "10px", md: "0" }}
            left={{ md: "0", base: "10px" }}
            right={{ md: "0", base: "10px" }}
            bg={{ base: "rgba(31, 31, 31, 1)", md: "transparent" }}
            py={{ md: "35px", base: "5px" }}
            px={{ base: "0.8rem", md: "0" }}
            display="flex"
            justifyContent="space-between"
            alignItems={{ md: "flex-start", base: "center" }}
            maxW="100%"
            borderRadius={{ base: "34px", md: "0" }}
            zIndex="10"
          >
            <HomeNav />
            <Box display="flex" alignItems="flex-start">
              <BasketNav />
              <Box display={{ md: "block", base: "none" }}>
                <CallPhone />
              </Box>
            </Box>
          </Box>
        </Box>
      </Containers>
    </header>
  );
};

export default Header;
