import React, {useEffect, useState} from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {FiEdit} from "react-icons/fi";
import {IEmployees} from "../reducer/types";
import ModalInput from "../../modalInput";
import {BiSolidUserCircle} from "react-icons/bi";
import {MdWorkOff} from "react-icons/md";
import {DefButton} from "../../../../components/ui";
import {INNER_WIDTH} from "../../../../config/_variables";
import Cropper from "../../../../components/Cropper";
import PersonGif from "../../../../components/ui/PersonGif";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {editEmployee, getCorporationEmployees, getEmployeeDetail} from "../reducer/actions";
import {getFileName} from "../../../../config/helpers";
import CustomCard from "../../../../components/custom-buttons/CustomCard";
import Loader from "../../../../components/Loader";


interface Props {
  employee: IEmployees
}

const EditModal = ({employee}: Props) => {
  const dispatch = useAppDispatch()
  const {onOpen, isOpen, onClose} = useDisclosure()
  const [isLoading, setLoading] = useState(false)
  const [value, setValue] = useState<IEmployees>(employee)
  const {user, isLoading: isLoadModal} = useAppSelector(state => state.ProfileReducer)
  const {menu_buttons} = useAppSelector((s) => s.MenuButtonsReducer);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }
  const handleGradient = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, is_gradient: e.target.checked})
  }
  const uploadAvatar = (avatar: string) => {
    setValue({...value, avatar})
  }
  const removeAvatar = () => {
    setValue({...value, avatar: ""})
  }

  const updatePage = () => {
    onClose()
    dispatch(getCorporationEmployees())
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    editEmployee({
      ...value,
      avatar: !!value.avatar ? getFileName(value.avatar) : ""
    }, setLoading, updatePage)
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(getEmployeeDetail(employee.card_id))
    }
  }, [isOpen])
  return (
    <>
      <Box onClick={onOpen} cursor='pointer' color='#0080DD' _active={{color: '#0080DDCC'}} fontSize='12px'
           fontFamily='Rubik'>
        <Box fontSize='1.4rem' mb='5px'><FiEdit/></Box>
        Edit
      </Box>


      <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered={true}>
        <ModalOverlay/>
        <ModalContent bg='#0E0E0E' px={{md: '50px', base: '4'}} pt='42px' pb='56px' rounded='31px'>
          <ModalCloseButton color='#A8A8A8'/>
          <Box w='full' rounded='14px' overflow='hidden' h={{sm: '443px', base: INNER_WIDTH / 6 * 5 + 'px'}}
               position='relative'>
            <PersonGif avatar={value.avatar || ""} is_gradient={value.is_gradient}/>
          </Box>
          <Cropper
            handleGradient={handleGradient}
            removeImage={removeAvatar}
            aspect={6 / 5}
            uploader={uploadAvatar}
            is_gradient={value.is_gradient}
            image={value.avatar || ""}
            inAdmin={true}
          />
          <form onSubmit={onSubmit}>
            <ModalInput
              handleChange={handleChange}
              value={value.full_name}
              type={"text"}
              name={"full_name"}
              icon={<BiSolidUserCircle/>}
              placeholder={"Username"}
            />
            <ModalInput
              handleChange={handleChange}
              value={value.designation}
              type={"text"}
              name={"designation"}
              icon={<MdWorkOff/>}
              placeholder={"Designation"}
            />
            <ModalInput
              handleChange={handleChange}
              value={value.welcome_text}
              type={"text"}
              name={"welcome_text"}
              icon={<MdWorkOff/>}
              placeholder={"Welcome text"}
            />

            <DefButton isLoading={isLoading} isAdmin={true} isRound={true} type='submit'>Save</DefButton>
          </form>

         <ModalFooter justifyContent='center' alignItems='start' pt='40px'>
           {isLoadModal && <Loader/>}
           {menu_buttons.map((btn, index) => (
             <CustomCard
               key={index}
               btn={btn}
               openMenuButtons={() => {}}
               isMenu={true}
               className={`level${index + 1}`}
               isAdmin={true}
               index={index}
               card_id={employee.card_id}
             />
           ))}
         </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditModal;