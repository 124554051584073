import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IVideos} from "../../../../../../models/company";

interface LocationState {
  isError: string | null;
  isLoading: boolean;
  videos: IVideos[];
}

export const defaultVideo: IVideos = {url: '', description: '', title: ''}

const initialState: LocationState = {
  isError: null,
  isLoading: false,
  videos: [],
}

export const videoSLice = createSlice({
  name: 'youtube-link/post',
  initialState,
  reducers: {
    addVideos(state) {
      state.isLoading = true;
    },
    addedVideos(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.isError = action.payload
    },
    getVideosSuccess(state, action: PayloadAction<IVideos>) {
      const found = state.videos.find(el => el.id === action.payload.id)
      if (found) {
        const index = state.videos.indexOf(found)
        state.videos[index] = action.payload
      } else {
        state.videos.push(action.payload)
      }
    },

    addField(state) {
      if (state.videos.every(e => e.url?.trim())) {
        state.videos.push({
          url: "",
          title: "",
          description: "",
        })
      }
    },

    getVideoToChange(state, {payload}: PayloadAction<IVideos[]>) {
      state.videos = []
      state.videos = payload
    },

    resetVideo(state) {
      state.videos = []
    },

    removeVideo(state, action: PayloadAction<number>) {
      state.videos = state.videos.filter(el => el.id !== action.payload)
    },
  }
})

export const VideosReducer = videoSLice.reducer
export const {addField} = videoSLice.actions
