import React from 'react';
import {Box, Progress} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../config/_variables";

const Loader = () => {
  return (
    <Box position='fixed' left='0' top='0' zIndex='30' w='100%'>
      <Progress size='xs' bg='#000' w={INTERFACE_WIDTH} mx='auto' h={2} borderBottomRadius='3px' isIndeterminate/>
    </Box>
  );
};

export default Loader;