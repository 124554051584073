import { Box, Button, Text } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

interface IProps {
  text: string;
  onClickPrev: () => void;
  onClickNext: () => void;
  isToday: boolean;
}

class ShowGetDate extends Component<IProps> {
  render(): ReactNode {
    const { text, onClickNext, onClickPrev, isToday } = this.props;
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={onClickPrev}
          bg="transparent"
          color="white"
          fontSize="30px"
          px="5px"
          colorScheme="#353535"
        >
          <MdKeyboardArrowLeft />
        </Button>
        <Text
          w="clamp(200px, calc(10px + 10vw), 500px)"
          px="4px"
          textAlign="center"
          whiteSpace="nowrap"
          color="#A2D6FC"
          fontSize="clamp(1.00rem, calc(0.92rem + 0.39vw), 1.20rem)"
          fontWeight="400"
        >
          {text}
        </Text>
        <Button
          onClick={onClickNext}
          bg="transparent"
          color="white"
          fontSize="30px"
          px="5px"
          colorScheme="#353535"
          isDisabled={isToday}
        >
          <MdKeyboardArrowRight />
        </Button>
      </Box>
    );
  }
}

export default ShowGetDate;
