import { Box } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";

//local
import { useAppDispatch } from "../../../../redux/store";
import { SectionComponent } from "../../../../components/ui";
import { useShopProducts } from "./ProductsReducer/hooks";
import { getCategoriesAll } from "./CategoriesShopReducer/action";
import { useCateforiesShop } from "./CategoriesShopReducer/hooks";
import ProductMenu from "./ProductMenu";
import ProductTabList from "./ProductTabList";
import { getProductsShop } from "./ProductsReducer/actions";

const ProductComponent: FC = () => {
  const dispatch = useAppDispatch();

  const { categoryProducts, isLoading } = useShopProducts();
  const { categoriesShop, isLoadCategory } = useCateforiesShop();

  useEffect(() => {
    dispatch(getCategoriesAll());
    dispatch(getProductsShop());
  }, [dispatch]);

  return (
    <SectionComponent idName="products">
      <Box pt={{ md: "30px", base: "80px" }} pb="15px" fontFamily="Poppins">
        {categoriesShop.length > 0 && (
          <ProductTabList
            categories={categoriesShop}
            isLoading={isLoadCategory}
          />
        )}
        <ProductMenu
          categoryProducts={categoryProducts}
          isLoading={isLoading}
        />
      </Box>
    </SectionComponent>
  );
};

export default ProductComponent;
