import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface OrderState {
  isLoading: boolean;
  isError: string | null
}

const initialState: OrderState = {
  isError: null,
  isLoading: false,
}

export const orderSlice = createSlice({
  name: 'order-checkout/shop',
  initialState,
  reducers: {
    loadingOrder(state){
      state.isLoading = true
    },
    sendOrder(state, action: PayloadAction<string | null>){
      state.isLoading = false
      state.isError = action.payload
    }
  }
})

export const OrderShopReducer = orderSlice.reducer