import React from 'react';

const LinkedinSvg = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.89626 2.28935C4.89626 3.40672 4.05519 4.3008 2.70471 4.3008H2.67995C1.37967 4.3008 0.539062 3.40672 0.539062 2.28935C0.539062 1.14835 1.40527 0.279297 2.73023 0.279297C4.05519 0.279297 4.87112 1.14835 4.89626 2.28935ZM4.64117 5.88914V17.5261H0.768088V5.88914H4.64117ZM18.6088 17.5261L18.609 10.8538C18.609 7.27946 16.6983 5.61589 14.1496 5.61589C12.0933 5.61589 11.1726 6.74538 10.6585 7.53774V5.88945H6.785C6.83605 6.98141 6.785 17.5265 6.785 17.5265H10.6585V11.0274C10.6585 10.6796 10.6837 10.3327 10.7861 10.0837C11.066 9.38884 11.7033 8.66947 12.7734 8.66947C14.1754 8.66947 14.7359 9.73656 14.7359 11.3003V17.5261H18.6088Z"
            fill="#FFFFFF"/>
    </svg>

  );
};

export default LinkedinSvg;