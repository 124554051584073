import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UploadState {
  isError: string;
  isLoading: boolean;
}

const initialState: UploadState = {
  isError: "",
  isLoading: false,
}

export const uploadSlice = createSlice({
  name: 'upload/file',
  initialState,
  reducers: {
    uploadFile(state){
      state.isLoading = true
    },
    uploadFileSuccess(state){
      state.isLoading = false
      state.isError = ''
    },
    uploadFileError(state, {payload}: PayloadAction<string>){
      state.isLoading = false
      state.isError = payload
    },
  }
})

export const UploadFileReducer = uploadSlice.reducer