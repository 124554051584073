import { PUBLIC_API } from "../../../../../api";
import { AppDispatch } from "../../../../../redux/store";
import { categoryShopSlice } from "./categoryShopSlice";
import { RootCategoriesShop } from "./interfaces";

export const getCategoriesAll = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(categoryShopSlice.actions.getCategoryShopLoading());
    const { data } = await PUBLIC_API.get<RootCategoriesShop>(
      "api/v1/shop/categories/"
    );
    dispatch(categoryShopSlice.actions.getCategoryShopSuccess(data));
  } catch (e: any) {
    dispatch(categoryShopSlice.actions.getCategoryShopError(e.message));
  }
};
