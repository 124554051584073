import React, { FC, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

//local
import { INTERFACE_WIDTH, NAVBAR_HEIGHT } from "../../config/_variables";
import { MainAnimation, Navbar } from "../../components";

type IProps = {
  children: ReactNode;
};

const InterfacePages: FC<IProps> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <Box
      maxW={INTERFACE_WIDTH}
      minHeight="100vh"
      mx="auto"
      position="relative"
      pb={pathname.includes("user") ? "0" : NAVBAR_HEIGHT}
    >
      <MainAnimation />
      {children}
      {pathname.includes("user") ? null : <Navbar />}
    </Box>
  );
};

export default InterfacePages;
