import React, {HTMLInputTypeAttribute, useState} from 'react';
import {Box, Input, InputGroup, InputLeftElement, InputRightElement, useDisclosure} from "@chakra-ui/react";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";


interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type: HTMLInputTypeAttribute;
  name: string;
  icon: JSX.Element;
  placeholder: string;
}
const ModalInput = ({handleChange, name, type, icon, placeholder, value}: Props) => {
  const {onOpen, isOpen, onClose} = useDisclosure()
  return (
    <InputGroup mt={5}>
      <InputLeftElement
        h='100%' w='58px'
        display='flex'
        justifyContent='center'
        alignItems='center'
        color='#4F8BFF'
        fontSize='1.2rem'
      >{icon}</InputLeftElement>
      <Input
        pl='52px'
        border='none'
        onChange={handleChange}
        name={name}
        type={type === "password" ? isOpen ? 'text' : 'password' : type}
        value={value}
        placeholder={placeholder}
        w='100%' h='56px'
        rounded='39px'
        fontSize='16px'
        fontFamily='Rubik'
        fontWeight='400'
        color='#A8A8A8'
        bg='#FFFFFF1A'
        isRequired={true}
      />
      {type === "password" &&
        <InputRightElement color='#CECECE' h='100%' w='58px' alignItems='center' fontSize='1.4rem' cursor='pointer'>
        {isOpen && <Box onClick={onClose}>
          <AiOutlineEye/>
        </Box>}
        {!isOpen && <Box onClick={onOpen}>
          <AiOutlineEyeInvisible/>
        </Box>}
      </InputRightElement>}
    </InputGroup>
  );
};

export default ModalInput;