import React from 'react';

const LinkSvg = () => {
  return (
    <svg width="28" height="11" viewBox="0 0 28 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.48688 1.98481H12.9639V0H7.55879C5.6652 0 3.94539 0.569631 2.69898 1.49528C1.45856 2.42093 0.685547 3.6937 0.685547 5.10443C0.685547 7.92588 3.76562 10.2089 7.55879 10.2089H12.9639V8.273H7.48688C5.14986 8.273 3.25627 6.86672 3.25627 5.13113C3.25627 4.26333 3.72967 3.47564 4.49669 2.90601C5.26371 2.33638 6.31837 1.98481 7.48688 1.98481ZM21.1195 0H15.6964V1.98481H21.2094C23.5464 1.98481 25.44 3.39108 25.44 5.13113C25.44 5.99893 24.9666 6.78662 24.1996 7.3518C23.4326 7.92143 22.3719 8.273 21.2094 8.273H15.6964V10.2089H21.1195C23.0191 10.2089 24.7389 9.63477 25.9793 8.71357C27.2257 7.78792 27.9927 6.51515 27.9927 5.10443C27.9927 2.28742 24.9127 0 21.1195 0Z"
            fill="#FFFFFF"/>
      <path d="M19.7987 4.13477H8.89258V6.10622H19.7987V4.13477Z" fill="#FFFFFF"/>
    </svg>

  );
};

export default LinkSvg;