import { Box, Text, Heading, Image } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { SectionComponent } from "../../../../components/ui";
import ecoImage from "../../../../assets/img/eco.webp";

const Eco: FC = () => {
  return (
    <SectionComponent>
      <Box
        py={{ md: "200px", base: "100px" }}
        maxW={{ lg: "1024px", md: "90%", base: "100%" }}
        mx="auto"
      >
        <Box w={{ base: "95%", md: "55%" }}>
          <Heading
            mb={"12px"}
            lineHeight={{ base: "39.68px", md: "69.44px" }}
            fontWeight={"500"}
            fontSize={{ base: "30px", md: "56" }}
            color={"rgba(255, 255, 255, 1)"}
            textTransform={"uppercase"}
            fontFamily={"Poppins"}
          >
            ECO-FRiendly
          </Heading>
          <Text
            mb={"12px"}
            fontWeight={"300"}
            fontSize={"15px"}
            lineHeight={"18.6px"}
            letterSpacing={"15%"}
            fontFamily={"Poppins"}
            color={"rgba(255, 255, 255, 1)"}
          >
            By leveraging NFC technology, we provide solutions that eliminate
            the need for paper business cards, significantly reducing the
            felling of trees and combating global warming.{" "}
          </Text>
          <Text
            mb={"12px"}
            fontWeight={"300"}
            fontSize={"14px"}
            lineHeight={"17.36px"}
            letterSpacing={"15%"}
            fontFamily={"Poppins"}
            color={"rgba(255, 255, 255, 1)"}
            opacity="0.6"
          >
            Together, let's work towards a sustainable future, preserving our
            planet for generations to come. Partner with us to embrace
            eco-friendly practices and make a difference in the fight against
            climate change.
          </Text>
        </Box>
        <Image
          src={ecoImage}
          alt="Eco"
          w={{ base: "297.09px", md: "506.13px" }}
          h={{ base: "280.12px", md: "419.09px" }}
          position={"absolute"}
          right={{ base: "-20px", md: "0" }}
          top={{ md: "10px" }}
          bottom={{ md: "auto", base: "0" }}
        />
      </Box>
    </SectionComponent>
  );
};

export default Eco;
