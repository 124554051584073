import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";

interface IProps {
  width: number;
  text: string | number;
  isLoading: boolean;
  bg?: string;
}

class CircleTotal extends Component<IProps> {
  render(): ReactNode {
    const { text, width, bg, isLoading } = this.props;
    return (
      <Box
        w={`${width}px`}
        h={`${width}px`}
        bg="linear-gradient(212.26deg, rgba(79, 139, 255, 0.3) 14.07%, rgba(255, 255, 255, 0.21) 88.23%)"
        position="relative"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        _after={{
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: `${width - 27}px`,
          height: `${width - 27}px`,
          background: bg || "#4F8BFF",
          borderRadius: "50%",
          zIndex: "0",
        }}
      >
        {!!isLoading ? (
          <Spinner zIndex="1" />
        ) : (
          <Text zIndex="1" fontWeight="600" fontSize="24px" textAlign="center">
            {text}
          </Text>
        )}
      </Box>
    );
  }
}

export default CircleTotal;
