import React from 'react';

const TiktokSvg = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6242 6.33377C13.9797 7.36303 15.6396 7.96867 17.4325 7.96867V5.08281C16.4317 4.8563 15.5458 4.30059 14.8796 3.52762C13.7394 2.77158 12.9179 1.52431 12.6771 0.0644531H10.1636V14.7079C10.1579 16.415 8.85411 17.7973 7.24629 17.7973C6.29883 17.7973 5.4571 17.3174 4.92402 16.574C3.97209 16.0634 3.3193 15.0153 3.3193 13.8049C3.3193 12.0931 4.62525 10.7051 6.23652 10.7051C6.54524 10.7051 6.84279 10.7562 7.1219 10.8503V7.93291C3.66172 8.00888 0.878906 11.0131 0.878906 14.7079C0.878906 16.5524 1.57189 18.2244 2.69662 19.4461C3.71128 20.1701 4.93274 20.5923 6.24689 20.5923C9.76901 20.5923 12.6243 17.5583 12.6243 13.8156V6.33377H12.6242Z"
            fill="#FFFFFF"/>
    </svg>
  );
};

export default TiktokSvg;