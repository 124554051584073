import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";

//local
import { DefButton, ErrorText } from "../ui";
import { AccountRequired } from "../../assets/svg/AccountRequired";
import { PhoneRequiredSvg } from "../../assets/svg/PhoneRequiredSvg";
import { EmailReqSvg } from "../../assets/svg/EmailReqSvg";
import { ILead } from "../../redux/leads/interface";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { createLead } from "../../redux/leads/action";
import { useLeads } from "../../redux/leads/hook";

interface FormErrors {
  [key: string]: string;
  name: string;
  contact: string;
  email: string;
  comment: string;
}

const LetMeSaveContact: FC = () => {
  const [objContact, setObjContact] = useState({
    name: "",
    contact: "",
    email: "",
    comment: "",
  } as ILead);

  const [errors, setErrors] = useState<FormErrors>({
    name: "",
    contact: "",
    email: "",
    comment: "",
  });

  const dispatch = useAppDispatch();

  const { name, contact, email, comment } = objContact;
  const { card_id } = useAppSelector((s) => s.ProfileReducer.user);
  const { isLoading } = useLeads();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setObjContact({ ...objContact, [name]: value });

    // Perform validation and update errors
    let errorMessage = "";
    if (name === "name" && value.trim() === "") {
      errorMessage = "Name is required";
    } else if (name === "contact" && !/^(?:\+?\d+|\d+)$/.test(value)) {
      errorMessage = "Invalid phone number";
    } else if (
      name === "email" &&
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(value)
    ) {
      errorMessage = "Invalid email address";
    } else {
      setErrors({ ...errors, [name]: "" }); // Clear the error message if within limit
    }

    // Update the errors state
    setErrors({ ...errors, [name]: errorMessage });
  };

  const inputFields = [
    {
      name: "name",
      placeholder: "Name",
      type: "text",
      value: name,
      required: true,
      leftElement: <AccountRequired />,
    },
    {
      name: "contact",
      placeholder: "+971",
      type: "tel",
      value: contact,
      required: true,
      leftElement: <PhoneRequiredSvg />,
      pattern: "^(?:\\+?\\d+|\\d+)$",
    },
    {
      name: "email",
      placeholder: "Email",
      type: "email",
      value: email,
      leftElement: <EmailReqSvg />,
      pattern: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$",
    },
    {
      name: "comment",
      placeholder: "Comment...",
      type: "textarea",
      value: comment,
    },
  ];

  const renderedInputs = inputFields.map((field, idx) => (
    <Box py="1rem" key={idx}>
      {field.type === "textarea" ? (
        <Textarea
          name="comment"
          placeholder="Comment..."
          value={comment}
          onChange={handleChange}
          maxLength={60}
          mt="1rem"
          _placeholder={{
            color: "#5E5E5E",
          }}
          color="#ffffff"
          borderRadius="17px"
          border="none"
          bg="#75757540"
        />
      ) : (
        <InputGroup>
          <InputLeftElement>
            <Box px="10px">{field.leftElement}</Box>
          </InputLeftElement>
          <Input
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            value={field.value}
            required={field.required}
            onChange={handleChange}
            pattern={field.pattern}
            _placeholder={{
              color: "#5E5E5E",
            }}
            color="#ffffff"
            borderRadius="39px"
            border="none"
            bg="#75757540"
            py="25px"
          />
        </InputGroup>
      )}

      {errors[field.name] && <ErrorText children={errors[field.name]} />}
    </Box>
  ));

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const areAllFieldsEmpty = Object.values(errors).every(
      (value) => value === ""
    );
    if (areAllFieldsEmpty) {
      dispatch(createLead(card_id, objContact));
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box fontFamily="Poppins" fontSize="15px" fontWeight="400" py="5px">
        {renderedInputs}
        <DefButton
          type="submit"
          isLoading={isLoading}
          children="Send"
          isRound={true}
        />
      </Box>
    </form>
  );
};

export default LetMeSaveContact;
