import React, {useEffect, useState} from 'react';
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {MdOutlineArrowForwardIos} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {IMember} from "../../../../models/member";
import {useAppSelector} from "../../../../redux/store";
import {allocateText} from "../../../../config/helpers";
import {API_ADDRESS} from "../../../../api";

interface Props {
  el: IMember;
  idx: number;
}

const MemberCard = ({el, idx}: Props) => {
  const navigate = useNavigate()
  const {search_text} = useAppSelector(state => state.MemberReducer)
  const [textArray, setArray] = useState<string[]>([el.title])

  const toDetail = () => {
    navigate(`/member-detail/${idx}`)
  }


  useEffect(() => {
    setArray(allocateText(el.title, search_text))
  }, [search_text])
  
  return (
    <Flex
      onClick={toDetail}
      h='97px' w='100%' rounded='8px' bg='#FFFFFF1A' mb='3.5' cursor='pointer' _active={{bg: '#FFFFFF2D'}}>
      <Flex justifyContent='center' alignItems='center' w='45%' bg='#FFFFFF' rounded='14px'>
        <Image objectFit='cover' w='100%' h='100%' rounded='8px' src={API_ADDRESS + el.logo} alt={el.title}/>
      </Flex>
      <Flex p={3} justifyContent='space-between' alignItems='center' w='55%' h='100%'>
        <Flex
          h='100%' w='100%' flexDirection='column'
          justifyContent='space-between' fontFamily='Poppins'
        >
          <Flex
            fontSize='16px' fontWeight='500' lineHeight='24px'
            letterSpacing='1px' color='#FFFFFF' textTransform='uppercase'
          >{textArray.map(text => (
            <Text key={text} w='auto' bg={text === search_text ? '#b49a03' : 'transparent'} rounded='4px'
            >{text}</Text>
          ))}

          </Flex>
          <Box
            display='flex' fontSize='16px'
            lineHeight='24px' letterSpacing='1px'
          >
            <Text color='#FFFFFF99' fontWeight='500'>Discount</Text>
            <Text color='#FFD600' fontWeight='600' ml={1}>{el.discount}%</Text>
          </Box>
        </Flex>

        <Box color='#636363'><MdOutlineArrowForwardIos/></Box>
      </Flex>
    </Flex>
  );
};

export default MemberCard;