import React from "react";

const BasketIconSvg = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1005_6955)">
        <path
          d="M5.08201 13.1534C5.1718 12.6036 4.79807 12.0853 4.24728 11.9957C3.69648 11.9061 3.17718 12.2791 3.08739 12.8288C2.99759 13.3786 3.37131 13.8969 3.92211 13.9865C4.47291 14.0761 4.99221 13.7031 5.08201 13.1534Z"
          fill="black"
        />
        <path
          d="M10.3789 13.1534C10.4687 12.6036 10.0949 12.0853 9.54415 11.9957C8.99335 11.9061 8.47405 12.2791 8.38426 12.8288C8.29447 13.3786 8.66819 13.8969 9.21899 13.9865C9.76979 14.0761 10.2891 13.7031 10.3789 13.1534Z"
          fill="black"
        />
        <path
          d="M11.7758 11.4034H1.5299C1.27041 11.4034 1.06055 11.194 1.06055 10.935C1.06055 10.676 1.27041 10.4665 1.5299 10.4665H11.4372L14.8166 0.320554C14.8803 0.129159 15.0599 0 15.2618 0H18.5305C18.7899 0 18.9998 0.209465 18.9998 0.468451C18.9998 0.727438 18.7899 0.936903 18.5305 0.936903H15.6004L12.221 11.0829C12.1573 11.2743 11.9777 11.4034 11.7758 11.4034Z"
          fill="black"
        />
        <path
          d="M10.5126 9.71361H2.51558C2.31376 9.71361 2.13406 9.58445 2.07036 9.39373L0.0239939 3.27107C-0.0236117 3.12853 0.000526387 2.97126 0.0883621 2.84879C0.176868 2.72633 0.318344 2.65405 0.469207 2.65405H12.559C12.7099 2.65405 12.8514 2.72633 12.9399 2.84879C13.0284 2.97126 13.0518 3.12786 13.0042 3.27107L10.9579 9.39306C10.8942 9.58445 10.7145 9.71361 10.5126 9.71361ZM2.85351 8.77671H10.174L11.9073 3.59162H1.12026L2.85351 8.77671Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1005_6955">
          <rect width="19" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BasketIconSvg;
