import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILead } from "./interface";

interface ILeadsState {
  leads: ILead[];
  isLoading: boolean;
  error: string;
}

const initialState: ILeadsState = {
  leads: [],
  isLoading: false,
  error: "",
};

export const leadsSlice = createSlice({
  name: "get/leads",
  initialState,
  reducers: {
    getLeadsLoading(state) {
      state.isLoading = true;
    },
    getLeadsSuccess(state, action: PayloadAction<ILead[]>) {
      state.isLoading = false;
      state.leads = action.payload;
      state.error = "";
    },
    getLeadsError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const LeadsReducer = leadsSlice.reducer;
