import React, { FC } from "react";
import {SimpleGrid } from "@chakra-ui/react";

//local
import {useCompanyDetail} from "../../../editing/Company/companyReducer/hooks";
import {SkeletonBtns} from "../../../../../components/skeletons";
import ContactButtonCard from "./ContactButtonCard";

const Buttons:FC = () => {
  const {buttons, isLoading} = useCompanyDetail();

  if (isLoading) {
    return <SkeletonBtns/>;
  }

  console.log(buttons, 'buttons')
  return (
    <>
      {buttons.length > 0 && (
        <SimpleGrid columns={1} spacing={"13px"} mt={5} position='relative'>
          {buttons.map((button) =>
            <ContactButtonCard
              button={button}
              key={button.id}
            />)}
        </SimpleGrid>
      )}
    </>
  );
};


export default Buttons;
