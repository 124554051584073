import React from "react";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";

//local
import ModalMini from "../../../../components/ui/modal";
import { DefButton } from "../../../../components/ui";
import { ILead } from "../../../../redux/leads/interface";
import { useAppDispatch } from "../../../../redux/store";
import { deleteLead } from "../../../../redux/leads/action";
import { createVcfFile, getFormatDate } from "../../../../config/helpers";

interface Props {
  lead: ILead;
}

const LeadCard = ({ lead }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const { name, email, contact, comment, created_date } = lead;

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      rounded="11px"
      mt="10px"
      p="15px"
      bg="#FFFFFF1A"
      w="100%"
      fontFamily="Poppins"
    >
      <Flex w="full" justifyContent="space-between" alignItems="start">
        <Flex
          w={{ sm: "90%", base: "70%" }}
          flexDirection="column"
          alignItems="start"
        >
          <Text
            as="h2"
            color="#FFFFFF"
            fontWeight="600"
            fontSize="18px"
            lineHeight="30px"
          >
            {name}
          </Text>
          <Text
            color="#A3A3A3"
            fontWeight="300"
            fontSize="14px"
            lineHeight="28px"
            maxW="140%"
          >
            {email}
          </Text>
          <Text
            color="#838383"
            fontWeight="300"
            fontSize="14px"
            lineHeight="21px"
            maxW="100%"
          >
            {contact}
          </Text>
        </Flex>
        <Box display="flex">
          <Button
            w="37px"
            h="37px"
            bg="#52525247"
            color="#B6B6B6"
            p="6px 7px"
            fontSize="3rem"
            mr={2}
            onClick={() => createVcfFile(name, contact, email)}
          >
            <AiOutlinePlus />
          </Button>
          <Button
            onClick={onOpen}
            w="37px"
            h="37px"
            bg="#52525247"
            color="#D92C41"
            p="9px 10px"
            fontSize="2rem"
          >
            <FaTrash />
          </Button>
        </Box>
      </Flex>
      <Text
        color="#838383"
        fontWeight="300"
        mt={5}
        fontSize="12px"
        lineHeight="18px"
      >
        {comment}
      </Text>
      <Text
        color="#838383"
        fontWeight="300"
        mt={3}
        fontSize="12px"
        lineHeight="18px"
        textAlign="end"
        w="full"
      >
        {getFormatDate(created_date)}
      </Text>
      <ModalMini isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column" alignItems="center">
          <Text
            color="#FFFFFF"
            fontWeight="400"
            mt={3}
            fontSize="15px"
            lineHeight="22.5px"
            textAlign="center"
            w="70%"
          >
            Are you sure you want to be removed from the list?
          </Text>
          <Flex mt="32px" w="full">
            <DefButton handleClick={onClose} border={true}>
              Cancel
            </DefButton>
            <Box w="20px" />
            <DefButton
              handleClick={() => {
                dispatch(deleteLead(lead.id));
                onClose();
              }}
            >
              Yes
            </DefButton>
          </Flex>
        </Flex>
      </ModalMini>
    </Flex>
  );
};

export default LeadCard;
