import PRIVATE_API from "../../api/index";
import { AppDispatch } from "../store";
import { RootStatistic, StatisticType } from "./interfaces";
import { statisticsSlice } from "./statisticsSlice";

export const getStatistics =
  (type: StatisticType, date: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(statisticsSlice.actions.getStatisticsLoading());
      const { data } = await PRIVATE_API.get<RootStatistic>(
        `api/v1/users/statistics/?type_=${type}&date=${date}`
      );
      dispatch(statisticsSlice.actions.getStatisticsSuccess(data));
    } catch (error: any) {
      dispatch(statisticsSlice.actions.getStatisticsError(error.message));
    }
  };
