import { Outlet } from "react-router-dom";

//local
import { useAuthUser } from "../config/hooks/userAccess";
import { AccessDeniedPage } from "../pages";

const ProtectedUserInterface = () => {
  const isAuth = useAuthUser();
  return isAuth ? <Outlet /> : <AccessDeniedPage />;
};

export default ProtectedUserInterface;
