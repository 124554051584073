import React from 'react';
import {Box, Flex, Skeleton, SkeletonText} from "@chakra-ui/react";

const SkeletonMember = () => {
  return (
    <Flex boxShadow='lg' pr={4} bg='#FFFFFF1A' rounded='8px' mb={4}>
      <Skeleton h='97px' w='45%' mr={3} rounded='8px'/>
      <Box w='50%' h='100%'>
        <SkeletonText mt='2' noOfLines={4} spacing='4' skeletonHeight='2' />
      </Box>
    </Flex>
  );
};

export default SkeletonMember;