import {Flex, Image} from "@chakra-ui/react";
import React, {useEffect} from "react";

//local
import PersonGIF from "../../../assets/gif/Rectangle.gif";
import {useAppDispatch} from "../../../redux/store";
import {API_ADDRESS} from "../../../api";
import {loadImage} from "../../Cropper/UploadFile-Reducer/actions";

interface Props {
  is_gradient: boolean;
  avatar: string;
  inAdmin?: boolean;
}

const PersonGif = ({is_gradient, avatar}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadImage(avatar));
  }, [avatar, dispatch]);

  return (
    <>
      <Image
        objectFit="cover"
        height="100%"
        w="100%"
        src={!avatar ? PersonGIF : API_ADDRESS + avatar.slice(1)}
        alt="Avatar"
      />
      <Flex
        w="full"
        h="420px"
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        bg={
          is_gradient
            ? "linear-gradient(0deg, #000000 4.04%, rgba(0, 0, 0, 0) 82.31%)"
            : "linear-gradient(0deg, #000000 1.5%, rgba(0, 0, 0, 0) 18.31%)"
        }
      />
    </>
  );
};

export default PersonGif;
