import { createSlice } from "@reduxjs/toolkit";
import { IStateCategoryShop } from "./interfaces";

const initialState: IStateCategoryShop = {
  isLoadCategory: false,
  categoriesShop: [],
  error: "",
};

export const categoryShopSlice = createSlice({
  name: "get/categoryShopSlice",
  initialState,
  reducers: {
    getCategoryShopLoading(state) {
      state.isLoadCategory = true;
    },
    getCategoryShopSuccess(state, action) {
      state.categoriesShop = action.payload;
      state.isLoadCategory = false;
      state.error = "";
    },
    getCategoryShopError(state, action) {
      state.error = action.payload;
      state.isLoadCategory = false;
      state.categoriesShop = [];
    },
  },
});

export const CategoryShopReducer = categoryShopSlice.reducer;
