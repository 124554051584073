import React, {useEffect} from 'react';
import {Box, Flex, Image, SimpleGrid, Text} from "@chakra-ui/react";
import TitleComponents from "../../../../components/ui/text/TitleComponents";
import Slider from 'react-slick'
import {MdLocationOn} from "react-icons/md";
import {BiArrowBack} from "react-icons/bi";
import {INTERFACE_WIDTH} from "../../../../config/_variables";
import {useNavigate, useParams} from "react-router-dom";
import {getMembersDetail} from "../MemberReducer/actions";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import DetailSkeleton from "./detail.skeleton";
import {resetMemberDetail} from "../MemberReducer/memberDetail.slice";
import {API_ADDRESS} from "../../../../api";
import ContactButtonCard from "../../home/Companies/Buttons/ContactButtonCard";
import {ErrorText} from "../../../../components/ui";


const MemberDetail = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {memberId} = useParams()

  const {member, isLoading, buttons, isError} = useAppSelector(state => state.MemberDetailReducer)

  const goBack = () => {
    navigate('/member')
  }

  useEffect(() => {
    dispatch(getMembersDetail(Number(memberId)))
    return () => {
      dispatch(resetMemberDetail())
    }
  }, [])


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Box/>,
    prevArrow: <Box/>,
  };


  return (
    <Box w='full' pt='79px' h='100%'>
      <Box bg='#000' position='fixed' zIndex='2' top='0' left='0' right='0' pt={6} pb={1}>
        <Flex maxW={INTERFACE_WIDTH} mx='auto' justifyContent='space-between' alignItems='center' px='10px' bg='#000'>
          <Box
            bg='#000'
            onClick={goBack}
            color='white' fontSize='1.8rem'
            cursor='pointer'><BiArrowBack/>
          </Box>
          {!!member?.title && <TitleComponents>{member?.title}</TitleComponents>}
          <Box w='26px'/>
        </Flex>
      </Box>
      <Box borderTopRadius='15px' overflowY='auto' h='300px' bg='#151515' minH='100vh' className='unscroll'>
        {!!isError && <Box w='100%' textAlign='center'><ErrorText>{isError}</ErrorText></Box>}
        {isLoading && <DetailSkeleton/>}
        {!isLoading && <Box borderTopRadius='25px' pt='30px' minH='100%' px='2.5'>

          <Flex
            bg='#FFFFFF1A' rounded='8px' p={3}
            alignItems='start'
          >
            <Flex
              justifyContent='center' alignItems='center'
              w='60px' h='60px' bg='#FFFFFF'
              rounded='50%' mr='15px' mt={2}>
              {!!member.logo && <Image
                w='100%' h='100%'
                rounded='50%' objectFit='cover'
                src={API_ADDRESS + member.logo} alt='scs'
              />}
            </Flex>
            <Flex flexDirection='column' w='70%' alignItems='start' fontFamily='Poppins'>
              <Text
                fontSize='16px' fontWeight='500' lineHeight='24px' noOfLines={1}
                letterSpacing='1px' color='#FFFFFF' textTransform='uppercase'
              >{member?.title}</Text>
              <Text
                fontSize='14px' fontWeight='300' lineHeight='21px'
                color='#FFFFFF' mt='6px'
              >{member?.description}</Text>
            </Flex>
          </Flex>

          <Box id='member-slider' mt='14px'>
            <Slider {...settings}>
              {member?.images?.map(el => (
                <Box key={el.id} h='230px' rounded='8px' w='100%'>
                  <Image w='100%' rounded='10px' h='100%' objectFit='cover' src={API_ADDRESS + el.file} alt=''/>
                </Box>
              ))}
            </Slider>
          </Box>

          <SimpleGrid columns={1} spacing='13px' mt='60px' position='relative'>
            {buttons?.map((button) =>
              <ContactButtonCard
                button={button}
                key={button.id}
              />)}
          </SimpleGrid>


          {member?.locations?.map(el => (
            <Flex
              key={el.id}
              px='22px' py='12px' mt='14px' minH='97px'
              border='1px solid #2E2E2E' rounded='8px'
              justifyContent='space-between'
            >
              <Box fontWeight='Poppins' color='white'>
                <Text fontWeight='500' fontSize='13px' lineHeight='19.5px'>{el.tower}</Text>
                <Text fontWeight='300' fontSize='12px' lineHeight='18px' my='5px'>{el.city}</Text>
                <Text fontWeight='300' fontSize='12px' lineHeight='18px'>{el.city}</Text>
              </Box>
              <Flex
                justifyContent='center' alignItems='center'
                w='66px' h='66px' rounded='50%' bg='#FFFFFF1A'
                color='#FFFFFF' fontSize='2rem'><MdLocationOn/>
              </Flex>
            </Flex>
          ))}

        </Box>}
      </Box>
    </Box>
  );
};

export default MemberDetail;