import { Image } from "@chakra-ui/image";
import React, { FC } from "react";
import Slider from "react-slick";

//local
import { API_ADDRESS } from "../../../../api";
import { Box } from "@chakra-ui/layout";

type IProps = {
  imgStr: string;
};

const ImageSlide: FC<IProps> = ({ imgStr }) => {
  // Удаляем экранирующие обратные слеши
  const cleanedString = imgStr.replace(/\\/g, "");

  // Преобразуем строку в массив
  const imgList: Array<any> = JSON.parse(cleanedString);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 4500,
    cssEase: "linear",
  };

  return (
    <Box className="company_slides" pb="2rem">
      {imgList.length > 0 && (
        <Slider {...settings}>
          {imgList.map((el, idx) => (
            <Box key={idx}>
              <Image
                objectFit="cover"
                h="100%"
                w="100%"
                src={API_ADDRESS + el}
                alt={el}
                borderRadius={8}
                border="none"
              />
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default ImageSlide;
