import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IContactButton, IMenuButtonServer} from "../../../models/profile";
import {allButtonData} from "./data";


export interface IPayloadBtn {
  data: IMenuButtonServer;
  button: IContactButton;
}

interface MenuButtonState {
  isError: string;
  isLoading: boolean;
  menu_buttons: null[] | IContactButton[];
  selected_index: number;
}

const initialState: MenuButtonState = {
  isError: '',
  isLoading: false,
  menu_buttons: [null, null, null],
  selected_index: 0,
}

export const menuButtonsSLice = createSlice({
  name: 'menu-buttons/get',
  initialState,
  reducers: {
    getMenuButton() {

    },
    getSelectedIndex(state, action: PayloadAction<number>) {
      state.selected_index = action.payload
    },
    getMenuButtonSuccess(state, {payload}: PayloadAction<IPayloadBtn>) {      
      state.menu_buttons[state.selected_index] = {
        ...payload.button,
        name: payload.data.name || payload.button.name,
        id: payload.data.id,
        value: JSON.parse(JSON.stringify([payload.data.value]))
      }
    },
    getMenuButtonFromProfile(state, {payload}: PayloadAction<IMenuButtonServer[]>) {
      let result: IContactButton[] = new Array(3).fill(null)
      for (let i = 0; i < allButtonData.length; i++) {
        payload.forEach((el,idx) => {
          if (el.type === allButtonData[i].type_id) {
            result[idx] = {
              ...allButtonData[i],
              name: el.name || allButtonData[i].name,
              id: el.id,
              value: JSON.parse(JSON.stringify([el.value]))
            }
          }
        })
      }
      state.menu_buttons = result
    }

  }
})

export const MenuButtonsReducer = menuButtonsSLice.reducer
export const {getSelectedIndex} = menuButtonsSLice.actions
