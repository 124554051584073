import {AppDispatch} from "../../../../redux/store";
import {PUBLIC_API} from "../../../../api";
import {toastCreator} from "../../../../config/toast";
import {IProductCart} from "../../../../models/products";
import {orderSlice} from "./slice";
import {PaymentMethodType} from "../CheckoutForm";

export interface IOrderData {
  full_name: string,
  phone_number: string,
  email: string,
  payment_method: PaymentMethodType;
}

export const sendOrders = (data: IOrderData, basket: IProductCart[]) => async (dispatch: AppDispatch) => {
  try {
    const order_items = basket.map(el => {
      return {
        quantity: el.quantity,
        product: el.id,
        total_price: el.price * el.quantity
      }
    })

    dispatch(orderSlice.actions.loadingOrder())
    await PUBLIC_API.post('api/v1/shop/order/', {...data, order_items})
    dispatch(orderSlice.actions.sendOrder(null))
    toastCreator('success', 'orders sent successfully')
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(orderSlice.actions.sendOrder(e.response?.data?.message))
  }
}