import React from 'react';

const CompanyDetailSvg = () => {
  return (
    <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.2321 9.91196H0.653898C0.291041 9.91196 0 9.6177 0 9.25083L0 3.85095C0 3.48408 0.291041 3.18982 0.653898 3.18982H31.2359C31.595 3.18982 31.8898 3.48408 31.8898 3.85095V9.25465C31.886 9.6177 31.595 9.91196 31.2321 9.91196Z" fill="#0080DD"/>
      <path d="M31.2321 11.5645H0.653898C0.291041 11.5645 0 11.8441 0 12.1928L0 23.4991C0 23.8478 0.291041 24.1275 0.653898 24.1275H31.2321C31.595 24.1275 31.886 23.8478 31.886 23.4991V12.1928C31.886 11.8441 31.595 11.5645 31.2321 11.5645ZM20.5716 14.1777H11.3144V13.1828H20.5716V14.1777Z" fill="#0080DD"/>
      <rect x="12.0303" y="0.872559" width="8.74954" height="1.15902" fill="#0080DD"/>
      <path d="M31.2321 9.91196H0.653898C0.291041 9.91196 0 9.6177 0 9.25083L0 3.85095C0 3.48408 0.291041 3.18982 0.653898 3.18982H31.2359C31.595 3.18982 31.8898 3.48408 31.8898 3.85095V9.25465C31.886 9.6177 31.595 9.91196 31.2321 9.91196Z" fill="#0080DD"/>
      <path d="M31.2321 11.5645H0.653898C0.291041 11.5645 0 11.8441 0 12.1928L0 23.4991C0 23.8478 0.291041 24.1275 0.653898 24.1275H31.2321C31.595 24.1275 31.886 23.8478 31.886 23.4991V12.1928C31.886 11.8441 31.595 11.5645 31.2321 11.5645ZM20.5716 14.1777H11.3144V13.1828H20.5716V14.1777Z" fill="#0080DD"/>
      <rect x="12.0283" y="0.872681" width="8.74954" height="1.15902" fill="#0080DD"/>
      <path d="M31.2341 9.91196H0.655851C0.292995 9.91196 0.00195312 9.6177 0.00195312 9.25083V3.85095C0.00195312 3.48408 0.292995 3.18982 0.655851 3.18982H31.2379C31.597 3.18982 31.8918 3.48408 31.8918 3.85095V9.25465C31.888 9.6177 31.597 9.91196 31.2341 9.91196Z" fill="#0080DD"/>
      <path d="M31.2341 11.5645H0.655851C0.292994 11.5645 0.00195312 11.8441 0.00195312 12.1928V23.4991C0.00195312 23.8478 0.292994 24.1275 0.655851 24.1275H31.2341C31.5969 24.1275 31.888 23.8478 31.888 23.4991V12.1928C31.888 11.8441 31.5969 11.5645 31.2341 11.5645ZM20.5736 14.1777H11.3164V13.1828H20.5736V14.1777Z" fill="#0080DD"/>
      <rect x="12.0303" y="0.872681" width="8.74954" height="1.15902" fill="#0080DD"/>
      <path d="M31.2341 9.91196H0.655851C0.292995 9.91196 0.00195312 9.6177 0.00195312 9.25083V3.85095C0.00195312 3.48408 0.292995 3.18982 0.655851 3.18982H31.2379C31.597 3.18982 31.8918 3.48408 31.8918 3.85095V9.25465C31.888 9.6177 31.597 9.91196 31.2341 9.91196Z" fill="#0080DD"/>
      <path d="M31.2341 11.5645H0.655851C0.292994 11.5645 0.00195312 11.8441 0.00195312 12.1928V23.4991C0.00195312 23.8478 0.292994 24.1275 0.655851 24.1275H31.2341C31.5969 24.1275 31.888 23.8478 31.888 23.4991V12.1928C31.888 11.8441 31.5969 11.5645 31.2341 11.5645ZM20.5736 14.1777H11.3164V13.1828H20.5736V14.1777Z" fill="#0080DD"/>
      <rect x="12.0303" y="0.872681" width="8.74954" height="1.15902" fill="#0080DD"/>
      <path d="M31.2341 9.91196H0.655851C0.292995 9.91196 0.00195312 9.6177 0.00195312 9.25083V3.85095C0.00195312 3.48408 0.292995 3.18982 0.655851 3.18982H31.2379C31.597 3.18982 31.8918 3.48408 31.8918 3.85095V9.25465C31.888 9.6177 31.597 9.91196 31.2341 9.91196Z" fill="#0080DD"/>
      <path d="M31.2341 11.5645H0.655851C0.292994 11.5645 0.00195312 11.8441 0.00195312 12.1928V23.4991C0.00195312 23.8478 0.292994 24.1275 0.655851 24.1275H31.2341C31.5969 24.1275 31.888 23.8478 31.888 23.4991V12.1928C31.888 11.8441 31.5969 11.5645 31.2341 11.5645ZM20.5736 14.1777H11.3164V13.1828H20.5736V14.1777Z" fill="#0080DD"/>
      <rect x="12.0303" y="0.872681" width="8.74954" height="1.15902" fill="#0080DD"/>
    </svg>

  );
};

export default CompanyDetailSvg;