import React from "react";
import { Button } from "@chakra-ui/react";

interface Props {
  type?: "button" | "submit";
  children: string | JSX.Element;
  handleClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}
const SubmitButton = ({
  children,
  handleClick,
  isLoading,
  type = "button",
  isDisabled = false,
}: Props) => {
  return (
    <Button
      onClick={handleClick}
      type={type}
      w="100%"
      color="rgba(36, 93, 206, 1)"
      bg="rgba(255, 255, 255, 1)"
      borderRadius="15px"
      h={{ md: "65px", base: "58px" }}
      fontSize={{ md: "20px", base: "16px" }}
      fontWeight="600"
      isDisabled={isDisabled}
      isLoading={isLoading}
      transition=".4s"
      _hover={{
        boxShadow: "0 0 15px #0b98ff",
        background: "rgba(36, 93, 206, 1)",
        color: "rgba(255, 255, 255, 1)",
      }}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
