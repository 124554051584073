import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { WhatsappSvg } from "../../../../assets/svg/WhatsappSvg";
import { TELL_NUMBER } from "../../../../config/admin";

interface IProps {
  maxWComponent: string;
  maxWBtn: string;
}

const CallBackWhatsapp: FC<IProps> = ({ maxWComponent, maxWBtn }) => {
  return (
    <Box maxW={maxWComponent} color="#ffffff">
      <Text
        fontWeight="600"
        fontSize={{ xl: "56px", md: "47px", base: "32px" }}
        lineHeight={{ xl: "64px", md: "58px", base: "35px" }}
      >
        Leave a request
      </Text>
      <Text fontWeight="500" fontSize="16px" lineHeight="27px">
        For an individual consultation
      </Text>
      <Box
        mt="20px"
        maxW={maxWBtn}
        mx="auto"
        bg="white"
        borderRadius="50px"
        color="#282B3F"
        fontWeight="600"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        overflow="hidden"
        cursor="pointer"
        border="none"
        className="leaverequest"
        onClick={() => window.open(`https://wa.me/${TELL_NUMBER}`)}
      >
        <Text
          w={{ lg: "70%", base: "60%" }}
          fontSize={{ lg: "20px", base: "16px" }}
          textAlign="center"
          bg="#ffffff"
        >
          Message
        </Text>
        <Box p="0.5rem">
          <Box
            bg="#1BC95C"
            w={{ md: "52px", base: "48px" }}
            h={{ md: "52px", base: "48px" }}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <WhatsappSvg width="40" color="#ffffff" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CallBackWhatsapp;
