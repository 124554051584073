import React, {useState} from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem, BreadcrumbLink,
  Button,
  Flex, Progress,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {PiLinkSimpleHorizontal} from "react-icons/pi";
import UploadSvg from "../../../../../assets/svg/UploadSvg";
import Placement from "../../../../../components/ui/placement";
import {DefButton, PlacementInput} from "../../../../../components/ui";
import {formatBytes, useFileDecoder} from "../../../../../config/hooks/file-decoder";
import {BsDot} from "react-icons/bs";
import {FaTrash} from "react-icons/fa";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store";
import {ICompanyImages} from "../../../../../models/company";
import {uploadImages} from "./reducer/actions";
import UploadCard from "./UploadCard";
import AddPhotoButton from "./AddPhotoButton";
import toast from "react-hot-toast";
interface Props {
  inAdmin: boolean
}
const UploadPhoto = ({inAdmin}: Props) => {
  const dispatch = useAppDispatch()
  const {isLoading} = useAppSelector(state => state.UploadFileReducer)
  const {images} = useAppSelector(state => state.GalleryReducer)

  const [handleChangeFile, file, binaryData, files, removeImage] = useFileDecoder()
  const {isOpen, onOpen, onClose} = useDisclosure()

  const [value, setValue] = useState<ICompanyImages>({
    button_name: "",
    button_link:"",
    description: "",
    image: ""
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.trim().length <= 124) {
      setValue({...value, [e.target.name]: e.target.value})
    }
  }

  const cleaner = () => {
  }

  const onsubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!files.length){
      toast("please upload a photo", {icon: '⚠'})
    }else {
      dispatch(uploadImages(files, value, onClose, inAdmin))
    }
  }

  return (
    <Box mt='62px'>
      {images.map(el => (
        <UploadCard el={el} key={el.id} inAdmin={inAdmin}/>
      ))}
      <Box mt='43px'>
        <Flex
          onClick={onOpen} flexDirection='column' alignItems='center'
          cursor='pointer' w='100%' h='201px' justifyContent='center'
          outline='none' rounded='14px' bg='transparent'
          border="1px solid #7D7D7D"
          _active={{bg: '#2E2E2E'}}
        >
          <UploadSvg/>
          <Text opacity={'0.5'}
                color={'white'} fontFamily='Poppins' fontWeight='400' fontSize='16px'
                lineHeight='24px' letterSpacing='0.5px'
          >{'Upload Photo'}</Text>
        </Flex>
        <Flex hidden={!isOpen} position='fixed' left='0' right='0' top='0' bottom='0' bg='#000000B2'/>
        <Placement isOpen={isOpen} onClose={onClose} title={'photo'}>
          <form onSubmit={onsubmit}>
            {files.map((file: any, idx: number) => (
              <Box mb={5} ml={2} key={idx}>
                <Flex key={idx} h='41.25px' w='100%' justifyContent='space-between' alignItems='center'>
                  <Flex alignItems='center' w='85%'>
                    <Flex flexDirection='column' w='100%'>
                      <Text fontFamily='Poppins' w='100%' fontWeight='400' fontSize='18px' lineHeight='27px' color='#FFFFFF'
                            noOfLines={1}>{file.name}</Text>
                      <Breadcrumb separator={<BsDot/>} fontFamily='Inter' fontWeight='400' fontSize='13.75px'
                                  letterSpacing='0.69px' lineHeight='27.5px' color='#989898'>
                        <BreadcrumbItem><BreadcrumbLink
                          href='#'>{file.type.split('/')[0]}</BreadcrumbLink></BreadcrumbItem>
                        <BreadcrumbItem><BreadcrumbLink
                          href='#'>{formatBytes(file.size)}</BreadcrumbLink></BreadcrumbItem>
                        <BreadcrumbItem><BreadcrumbLink
                          href='#'>{file.type.split('/')[1]}</BreadcrumbLink></BreadcrumbItem>
                      </Breadcrumb>
                    </Flex>
                  </Flex>
                  <Button
                    onClick={() => removeImage(idx)}
                    w='37px' h='37px' bg='transparent' color='#D92C41'
                    p='9px 10px' fontSize='2rem'
                  ><FaTrash/></Button>
                </Flex>
                {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
              </Box>
            ))}
            <PlacementInput
              handleChange={handleChange}
              value={value.button_name}
              name={"button_name"}
              placeholder={"Button Name"}
              cleaner={cleaner}
              required={false}
            />
            <PlacementInput
              handleChange={handleChange}
              value={value.button_link}
              name={"button_link"}
              placeholder={"Button link"}
              cleaner={cleaner}
              type='url'
              leftIcon={<PiLinkSimpleHorizontal/>}
              required={false}
            />
            <PlacementInput
              handleChange={handleChange}
              value={value.description}
              name={"description"}
              as={"textarea"}
              placeholder={"Description"}
              cleaner={cleaner}
              required={false}
            />
            <Flex pt={'19px'}>
              <AddPhotoButton handleChange={handleChangeFile}/>
              <Box w='18px'/>
              <DefButton
                isLoading={isLoading}
                type={"submit"}
              >SAVE</DefButton>
            </Flex>
          </form>
        </Placement>
      </Box>
    </Box>
  );
};

export default UploadPhoto;
