import React from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";
import { INTERFACE_WIDTH } from "../../../config/_variables";
import TitleComponents from "../text/TitleComponents";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  title: string;
  viewvBtn?: boolean;
  message?: string;
  isMessage?: boolean;
  bg?: string;
  colorIcon?: string;
}

function Placement({
  isOpen,
  onClose,
  children,
  title,
  viewvBtn,
  message,
  isMessage,
  bg,
  colorIcon,
}: Props) {
  const line = (
    <Box py="10px" onClick={onClose}>
      <Box bg="#525252" maxW="130px" h="4px" mx="auto" borderRadius="17px" />
    </Box>
  );

  return (
    <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay bg="#000000B2" />
      <DrawerContent
        borderTopRadius="24px"
        w={INTERFACE_WIDTH}
        maxH="80vh"
        mx="auto"
        bg={bg ? bg : "#2E2E2E"}
      >
        <DrawerHeader
          position="relative"
          py={!!isMessage ? "0" : "15px"}
          mb={!!isMessage ? 0 : 2}
        >
          {!!isMessage && line}
          <TitleComponents>{title}</TitleComponents>
          <Text
            fontSize="15px"
            fontWeight="300"
            letterSpacing="5%"
            lineHeight="22px"
            textAlign="center"
            color="#ffffff"
          >
            {message}
          </Text>
          <Button
            display={!!viewvBtn ? "none" : ""}
            onClick={onClose}
            bg="transparent"
            variant="none"
            position="absolute"
            right={4}
            top={4}
            px={2}
            fontSize="2rem"
            color="#FFFFFF"
          >
            <IoCloseOutline
              style={{ color: colorIcon ? colorIcon : "#ffffff" }}
            />
          </Button>
        </DrawerHeader>
        <DrawerBody className="unscroll" px={4}>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Placement;
