import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMember} from "../../../../models/member";
import {IContactButton} from "../../../../models/profile";
import {allButtonData} from "../../../../components/custom-buttons/CustomReducer/data";

interface MemberDetailState {
  member: Partial<IMember>,
  buttons: IContactButton[],
  isLoading: boolean;
  isError: null | string;
}

const initialState: MemberDetailState = {
  member: {},
  buttons: [],
  isLoading: false,
  isError: null,
}

export const memberDetailSlice = createSlice({
  name: 'members/detail',
  initialState,
  reducers: {
    getDetail(state) {
      state.isLoading = true
    },
    getDetailSuccess(state, action: PayloadAction<IMember>) {
      state.buttons = [];
      state.isLoading = false
      state.member = action.payload;
      for (let i = 0; i < allButtonData.length; i++) {
        action.payload.buttons.forEach(el => {
          if (el.type === allButtonData[i].type_id) {
            state.buttons.push({
              ...allButtonData[i],
              id: el.id,
              value: [el.value]
            })
          }
        })
      }
    },
    getDetailError(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.isError = action.payload
    },

    resetMemberDetail(state){
      state.member = {}
    },
  }
})

export const MemberDetailReducer = memberDetailSlice.reducer
export const {resetMemberDetail, getDetailSuccess} = memberDetailSlice.actions