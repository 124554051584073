import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootMenuButtons, RootProfile} from "../../../../../models/profile";
import {getLocalStorage} from "../../../../../config/helpers";

export interface IProfileValue {
  full_name: string;
  designation: string;
  welcome_text: string;
  avatar: string;
  is_gradient: boolean;
}

interface ProfileState {
  user: RootProfile;
  isError: string;
  isLoading: boolean;
}

const initialState: ProfileState = {
  user: {
    id: 0,
    avatar: "",
    full_name: "",
    designation: "",
    is_gradient: false,
    welcome_text: getLocalStorage('wlkm') || "Welcome",
    card_id: "",
    contact: "",
    email: "",
    is_first_time: null,
    menu_buttons: [],
    vip: '',
  },
  isError: "",
  isLoading: false,
}

export const profileSlice = createSlice({
  name: 'profile/get',
  initialState,
  reducers: {
    getProfile(state){
      state.isLoading = true;
    },
    getProfileSuccess(state, {payload}: PayloadAction<RootProfile>){
      state.user = payload;
      state.isLoading = false;
      state.isError = "";
    },
    getProfileError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.isError = action.payload
    },
    uploadAvatar(state, {payload}: PayloadAction<string>){
      state.user.avatar = payload
    },
    getMenuButtons(state, {payload}: PayloadAction<RootMenuButtons>){
      state.user.menu_buttons = payload
    },
    removeAvatar(state){
      state.user.avatar = ""
    },
    changeEmailUser(state, action: PayloadAction<string>){
      state.user.email = action.payload
    }
  }
})

export const ProfileReducer = profileSlice.reducer
export const {uploadAvatar, removeAvatar} = profileSlice.actions
