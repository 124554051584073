import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

//local
import { SectionComponent } from "../../../../components/ui";
import CallbackInComponent from "./CallbackInComponent";

const Callback: FC = () => {
  return (
    <SectionComponent idName="contacts">
      <Box pt={{ md: "40px", base: "80px" }} pb="40px" maxW="1024px" mx="auto">
        <CallbackInComponent />
      </Box>
    </SectionComponent>
  );
};

export default Callback;
