export const UnlockIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 11.9163V10.833C9.75 9.03467 10.2917 7.58301 13 7.58301C15.7083 7.58301 16.25 9.03467 16.25 10.833V11.9163"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9996 15.8166C13.3586 15.8166 13.6496 15.5256 13.6496 15.1666C13.6496 14.8076 13.3586 14.5166 12.9996 14.5166C12.6406 14.5166 12.3496 14.8076 12.3496 15.1666C12.3496 15.5256 12.6406 15.8166 12.9996 15.8166Z"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.709 18.417H10.2923C8.12565 18.417 7.58398 17.8753 7.58398 15.7087V14.6253C7.58398 12.4587 8.12565 11.917 10.2923 11.917H15.709C17.8757 11.917 18.4173 12.4587 18.4173 14.6253V15.7087C18.4173 17.8753 17.8757 18.417 15.709 18.417Z"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9993 23.8337C18.9824 23.8337 23.8327 18.9834 23.8327 13.0003C23.8327 7.01724 18.9824 2.16699 12.9993 2.16699C7.01626 2.16699 2.16602 7.01724 2.16602 13.0003C2.16602 18.9834 7.01626 23.8337 12.9993 23.8337Z"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
