import React, { FC, useRef } from "react";
import Slider from "react-slick";
import { Box, Image } from "@chakra-ui/react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

//local
import { IGalleryImage } from "../../../../models/galleryShop";

type IProps = {
  gallery: IGalleryImage[];
};

const GallerySlider: FC<IProps> = ({ gallery }) => {
  const slider = useRef<Slider | null>(null);

  const next = () => {
    if (slider.current) {
      slider.current.slickNext();
    }
  };

  const previous = () => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  };

  const PreviosBtn = () => {
    return (
      <button className="button" style={{ width: "100%" }} onClick={previous}>
        <MdOutlineArrowBackIos />
      </button>
    );
  };

  const NextBtn = () => {
    return (
      <button className="button" style={{ width: "100%" }} onClick={next}>
        <MdOutlineArrowForwardIos />
      </button>
    );
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      className="gallerery_shop"
      borderRadius={{ base: "20px", md: "39px" }}
      maxW={{ lg: "1024px", md: "90%", base: "100%" }}
      mx="auto"
      py={{ base: "0", md: "25px" }}
      px={{ md: "20px", base: "5px" }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      zIndex="2"
    >
      <Box display="flex" justifyContent="center">
        <PreviosBtn />
      </Box>
      <Box w="80%" zIndex="2">
        <Slider ref={slider} {...settings}>
          {gallery.map((el) => (
            <Box
              key={el.id}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                mx="auto"
                borderRadius={{ base: "10px", md: "20px" }}
                h={{ base: "250px", md: "200px", xl: "350px" }}
                src={el.image}
                alt={el.image}
              />
            </Box>
          ))}
        </Slider>
      </Box>
      <Box display="flex" justifyContent="center">
        <NextBtn />
      </Box>
    </Box>
  );
};

export default GallerySlider;
