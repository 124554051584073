import { Button } from "@chakra-ui/button";
import React, { FC } from "react";

type IProps = {
  text: string;
  onClick: () => void;
};

const BtnGray: FC<IProps> = ({ text, onClick }) => {
  return (
    <Button
      onClick={onClick}
      border="none"
      bg="#75757540"
      color="#FFFFFF"
      rounded="5px"
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="16px"
      colorScheme="black"
      p="0.2rem 1rem"
    >
      {text}
    </Button>
  );
};

export default BtnGray;
