import React from 'react';

const SocialNetworkSvg = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM18 23.82C14.79 23.82 12.18 21.21 12.18 18C12.18 14.79 14.79 12.18 18 12.18C21.21 12.18 23.82 14.79 23.82 18C23.82 21.21 21.21 23.82 18 23.82ZM26.88 10.32C26.805 10.5 26.7 10.665 26.565 10.815C26.415 10.95 26.25 11.055 26.07 11.13C25.89 11.205 25.695 11.25 25.5 11.25C25.095 11.25 24.72 11.1 24.435 10.815C24.3 10.665 24.195 10.5 24.12 10.32C24.045 10.14 24 9.945 24 9.75C24 9.555 24.045 9.36 24.12 9.18C24.195 8.985 24.3 8.835 24.435 8.685C24.78 8.34 25.305 8.175 25.785 8.28C25.89 8.295 25.98 8.325 26.07 8.37C26.16 8.4 26.25 8.445 26.34 8.505C26.415 8.55 26.49 8.625 26.565 8.685C26.7 8.835 26.805 8.985 26.88 9.18C26.955 9.36 27 9.555 27 9.75C27 9.945 26.955 10.14 26.88 10.32Z"
            fill="#0080DD"/>
    </svg>
  );
};

export default SocialNetworkSvg;