import React from 'react';

const MenuProfileSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 12C15.5114 12 17.75 9.76142 17.75 7C17.75 4.23858 15.5114 2 12.75 2C9.98858 2 7.75 4.23858 7.75 7C7.75 9.76142 9.98858 12 12.75 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.3401 22C21.3401 18.13 17.4902 15 12.7502 15C8.01015 15 4.16016 18.13 4.16016 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MenuProfileSvg;