import React from 'react';

const TelegramSvg = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.15397 6.94169C7.27014 4.6658 10.6817 3.1654 12.3887 2.44048C17.2625 0.370683 18.2753 0.0111375 18.9354 -0.000861462C19.0805 -0.00334608 19.4051 0.0333898 19.6154 0.207595C19.793 0.354691 19.8418 0.553397 19.8652 0.69286C19.8886 0.832323 19.9177 1.15002 19.8945 1.39826C19.6304 4.23166 18.4876 11.1075 17.9062 14.281C17.6602 15.6238 17.1758 16.074 16.7068 16.1181C15.6877 16.2139 14.9138 15.4304 13.9267 14.7697C12.382 13.7359 11.5094 13.0924 10.0101 12.0836C8.27737 10.9178 9.40062 10.277 10.3881 9.22979C10.6465 8.95574 15.137 4.78549 15.2239 4.40718C15.2347 4.35987 15.2448 4.18351 15.1422 4.09038C15.0396 3.99726 14.8881 4.0291 14.7789 4.05443C14.6239 4.09033 12.1564 5.75557 7.37618 9.05016C6.67577 9.54123 6.04136 9.78049 5.47296 9.76795C4.84633 9.75413 3.64096 9.4062 2.74489 9.1088C1.64582 8.74403 0.772304 8.55117 0.848367 7.93167C0.887986 7.609 1.32319 7.27901 2.15397 6.94169Z"
            fill="#FFFFFF"/>
    </svg>
  );
};

export default TelegramSvg;