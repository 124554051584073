import React, {FC, useRef, useState} from "react";

//local
import ProductComponent from "../components/Product/ProductComponent";
import AboutCompany from "../components/About/AboutCompany";
import Eco from "./Eco/index";
import Locations from "./Locations/index";
import Hero from "./Hero";
import Callback from "../components/Callback";
import Gallery from "./Gallerery";
import Solution from "./Solution";
import OurClients from "../components/OurClients";
import Features from "./Features";
import {Box} from "@chakra-ui/react";

const HomeStorePage: FC = () => {
  const [heightComponents, setHeight] = useState(0)
  const ref = useRef<any>(null)
  window.addEventListener('scroll', () => {
    setHeight(ref.current?.offsetHeight)
  })
  return (
    <>
      <Box ref={ref}>
        <Hero height={heightComponents}/>
        <ProductComponent/>
      </Box>
      <Solution/>
      <Gallery/>
      <Features/>
      <Eco/>
      <OurClients/>
      <Locations/>
      <AboutCompany/>
      <Callback/>
    </>
  );
};

export default HomeStorePage;
