import React, { useEffect } from 'react';
import { Flex, Text} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";

//local
import MenuProfileSvg from "../../../assets/svg/MenuProfileSvg";
import MenuLeadsSvg from "../../../assets/svg/MenuLeadsSvg";
import MenuStatisticSvg from "../../../assets/svg/MenuStatisticSvg";
import {INTERFACE_WIDTH, NAVBAR_HEIGHT} from "../../../config/_variables";
import MenuMemberSvg from "../../../assets/svg/MenuMemberSvg";
import { useAppDispatch } from '../../../redux/store';
import { getProfile } from '../../../pages/interface/editing/Profile/ProfileReducer/actions';
import { useAuthUser } from '../../../config/hooks/userAccess';

const navbar = [
  {
    icon: <MenuProfileSvg/>,
    name: 'Profile',
    path: '/profile',
  },
  {
    icon: <MenuLeadsSvg/>,
    name: 'Leads',
    path: '/leads',
  },
  {
    icon: <MenuStatisticSvg/>,
    name: 'Analytics',
    path: '/analytics',
  },
  {
    icon: <MenuMemberSvg/>,
    name: 'Member',
    path: '/member',
  },
]

const Navbar = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAuthUser();

  useEffect(() => {
    !!isAuth && dispatch(getProfile());
  }, [isAuth]);
  
  return (
    <Flex position='fixed' zIndex='20' bottom='0' left='0' right='0'>
      <Flex justifyContent='space-between' mx='auto' w={INTERFACE_WIDTH} bg='#1D1D1D' borderTop='1px solid #2E2E2E'
            className='navbar'>
        {navbar.map(nav => (
          <Flex
            key={nav.name}
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            w='25%'
            h={NAVBAR_HEIGHT} cursor='pointer'
            // _active={{bg: '#171717'}}
          >
            <NavLink to={nav.path} className='navbar--link'>
              <Flex className='nav-icon' rounded='16px' w='64px' h='32px' justifyContent='center' alignItems='center'>{nav.icon}</Flex>
                <Text fontFamily='Poppins' fontWeight='400' mt={1} letterSpacing='0.5px'
                    fontSize='12px'>{nav.name}</Text>
            </NavLink>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Navbar;