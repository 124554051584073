import { Box, Button, Flex } from "@chakra-ui/react";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//local
import { useAppDispatch } from "../../../redux/store";
import { EditTypes } from "../../../models/staticWords";
import { resetCompanyToChange } from "../editing/Company/companyReducer/companySlice";

const ProfileBtns: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateTo = async (page: EditTypes) => {
    if (page === "company") await dispatch(resetCompanyToChange());
    navigate(`/edit/${page}`);
  };

  return (
    <>
      {!pathname.includes("user") && (
        <Flex w="100%" alignItems="center" my="25px">
          <Button
            onClick={() => navigateTo("company")}
            w="100%"
            h="42px"
            variant="none"
            bg="#0080dd"
            color="#FFFFFF"
            _active={{ bg: "#FFFFFF33" }}
            rounded="27px"
            fontFamily="Poppins"
            fontWeight="500"
            fontSize="14px"
          >
            Add Company
          </Button>
          <Box width="1px" height="23.52px" mx="15px" bg="#414141"></Box>
          <Button
            onClick={() => navigateTo("profile")}
            w="100%"
            h="42px"
            variant="none"
            bg="#0080dd"
            color="#FFFFFF"
            _active={{ bg: "#FFFFFF33" }}
            rounded="27px"
            fontFamily="Poppins"
            fontWeight="500"
            fontSize="14px"
          >
            Edit Profile
          </Button>
        </Flex>
      )}
    </>
  );
};

export default ProfileBtns;
