import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

//local
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { DefButton } from "../../../../components/ui";
import { SmsSvg } from "../../../../assets/svg/SmsSvg";
import { Placement } from "../../../../components";
import { useLoginInterface } from "../../../../redux/loginFormInterface/hook";
import { setIsMessageEmail } from "../../../../redux/loginFormInterface/action";
import PRIVATE_API, { PUBLIC_API } from "../../../../api";
import { toastCreator } from "../../../../config/toast";
import { profileSlice } from "./ProfileReducer/profileSlice";
import ModalMini from "../../../../components/ui/modal";
import MessageAfterEmail from "../../../../components/messageAfterEmail/MessageAfterEmail";

const ChangeEmailModal: FC = () => {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.ProfileReducer.user);
  const [emailUser, setEmailUser] = useState<string>(email);
  const [load, setLoad] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);

  const { isMessageEmail } = useLoginInterface();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailUser(event.target.value);
  };

  const updateEmail = () => {
    setLoad(true);
    PRIVATE_API.patch("api/v1/users/profile/", { email: emailUser })
      .then((res) => {
        dispatch(profileSlice.actions.changeEmailUser(emailUser));
        resetLink();
      })
      .catch((e) => {
        setLoad(false);
        toastCreator("error", "Such an email exists!");
      });
  };

  const resetLink = () => {
    setLoad(true);
    PUBLIC_API.post("api/v1/users/profile/reset_link/", {
      email: emailUser,
    })
      .then((res) => {
        setLoad(false);
        dispatch(setIsMessageEmail(false));
        setIsSend(true);
      })
      .catch((e) => {
        setLoad(false);
        toastCreator("error", "Error sending message!");
      });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    email === emailUser ? resetLink() : updateEmail();
  };
  return (
    <>
      <ModalMini
        isOpen={isSend}
        onClose={() => setIsSend(false)}
        children={
          <MessageAfterEmail
            text="A link to create a new password has been sent to your email address, please check."
            onClose={() => setIsSend(false)}
          />
        }
      />
      <Placement
        isOpen={isMessageEmail}
        title=""
        onClose={() => dispatch(setIsMessageEmail(false))}
        children={
          <>
            <form onSubmit={handleSubmit}>
              <InputGroup mb="30px">
                <InputLeftElement
                  w="3.2rem"
                  minH="100%"
                  p="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box pr="10px">
                    <SmsSvg />
                  </Box>
                </InputLeftElement>
                <Input
                  type="email"
                  required
                  bg="#353535"
                  border="none"
                  value={emailUser}
                  onChange={handleChange}
                  borderRadius="30px"
                  placeholder="Email"
                  fontFamily="Poppins"
                  _placeholder={{
                    color: "#A8A8A8",
                    fontSize: "15px",
                  }}
                  color="#FFFFFF"
                  py="25px"
                />
              </InputGroup>
              <DefButton type="submit" isLoading={load}>
                Generate new password
              </DefButton>
            </form>
          </>
        }
      />
    </>
  );
};

export default ChangeEmailModal;
