import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface SliceState {
  isLoading: boolean;
  isError: string | null
}

const initialState: SliceState = {
  isError: null,
  isLoading: false,
}

export const callbackSlice = createSlice({
  name: 'callback/shop',
  initialState,
  reducers: {
    loadingCallback(state){
      state.isLoading = true
    },
    sendCallback(state, action: PayloadAction<string | null>){
      state.isLoading = false
      state.isError = action.payload
    }
  }
})

export const CallbackShopReducer = callbackSlice.reducer