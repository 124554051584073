import { Box, Image, Text } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

//local
import { IProductShop } from "../../../../models/products";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { addCart } from "../../BasketPage/CartReducer/slice";
import BasketIconSvg from "../../../../assets/svg/BasketIconSvg";

interface IProps {
  product: IProductShop;
}

const ProductCard: FC<IProps> = ({ product }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isHover, setHover] = useState(false);
  const { cart } = useAppSelector((state) => state.CartShopReducer);
  const found = cart.some((el) => el.id === product.id);
  const addToCart = () => {
    if (!found) {
      dispatch(addCart(product));
    } else {
      navigate("/basket");
    }
  };

  return (
    <Box
      py="10px"
      px="6px"
      fontFamily="Poppins"
      zIndex="2"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Image
        src={product.image}
        w="100%"
        transform={isHover ? "scale(1.04)" : ""}
        transition=".2s"
        objectFit="cover"
        alt={`${product.image}_photo`}
        borderRadius="10px"
      />
      <Box
        my="15px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          px="3.5px"
          maxW="95%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          fontWeight="300"
          fontSize="14px"
          textTransform="uppercase"
          color="rgba(255, 255, 255, 1)"
        >
          {product.title}
        </Text>
        <Box
          onClick={addToCart}
          bg={found ? "rgba(255, 255, 255, 1)" : "rgba(0, 132, 255, 1)"}
          borderRadius="15.5px"
          minW="139px"
          p="5px 10px"
          color={found ? "rgba(0, 132, 255, 1)" : "rgba(255, 255, 255, 1)"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fontSize="16px"
          cursor="pointer"
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <BasketIconSvg />
          <Text fontWeight="700" fontSize="14px">
            {found ? "Added" : `AED ${product.price}`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
