import React from 'react';

const FacebookSvg = () => {
  return (
    <svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.18837 6.30916V4.67172C6.18837 3.87591 6.7078 3.68842 7.06897 3.68842C7.4342 3.68842 9.30496 3.68842 9.30496 3.68842V0.184375L6.22489 0.171875C2.80801 0.171875 2.03292 2.78428 2.03292 4.45922V6.30499H0.0566406V8.77573V10.3965H2.04915C2.04915 15.0338 2.04915 20.6253 2.04915 20.6253H6.03416C6.03416 20.6253 6.03416 14.9755 6.03416 10.3965H8.99249L9.13452 8.78823L9.35366 6.30499H6.18837V6.30916Z"
            fill="#FFFFFF"/>
    </svg>
  );
};

export default FacebookSvg;