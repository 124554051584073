import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import Slider from "react-slick";

//local
import { useCompanyDetail } from "../../editing/Company/companyReducer/hooks";
import YoutubeVideo from "./YoutubeVideo";

const Videos = () => {
  const {
    company: { videos },
    isLoading,
  } = useCompanyDetail();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  if (!!isLoading) {
    return null;
  }

  return (
    <>
      {videos.length > 0 && (
        <Box
          mt="30px"
          fontFamily="Poppins"
          color="#FFFFFF"
          className="video__youtube company_slides"
        >
          <Text fontWeight={500} fontSize={18} lineHeight="27px">
            Video
          </Text>
          <Box bg="#000" borderRadius={20}  mt="10px">
            <Slider {...settings}>
              {videos.map((el) => (
                <YoutubeVideo key={el.id} video={el} />
              ))}
            </Slider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Videos;
