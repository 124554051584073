import React, {useState} from 'react';
import {Box, Flex, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import Placement from "../../../ui/placement";
import {DefButton, CustomInput, PlacementInput} from "../../../ui";
import {useAppSelector} from "../../../../redux/store";
import {IContactButton} from "../../../../models/profile";

interface Props {
  onSubmit: (button: IContactButton, values: string[], closeModals: () => void) => void
  btn: IContactButton
  title: string;
  parentClose: () => void;
}

const ButtonCard = ({btn, title, parentClose, onSubmit}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {isError, isLoading} = useAppSelector(state => state.AddCustomReducer)
  const {isLoading: loadingCompanyButton} = useAppSelector(state => state.ButtonsReducer)
  const [values, setValues] = useState<string[]>([btn.type_input === 'url' ? "https://" : ""])
  const [type_name, setName] = useState<string>(btn.name)
  const removeInput = (index: number) => {
    setValues(values.filter((_, idx) => idx !== index))
  }
  const addInput = () => {
    if (values.every(el => el.trim())) {
      setValues([...values, btn.type_input === 'url' ? "https://" : ""])
    }
  }
  const handleChange = (index: number, value: string) => {
    setValues(values.fill(value, index, index + 1))
  }

  const handleTypeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length < 16){
      setName(e.target.value)
    }
  }

  const closeModals = () => {
    onClose()
    parentClose()
  }
  const onSend = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit({...btn, name: type_name}, values, closeModals)
  }

  return (
    <>
      <Tooltip label={btn.type}>
        <Flex
          onClick={onOpen}
          rounded='50%' p='1px'
          w='70px' h='70px' cursor='pointer'
          bg='linear-gradient(#000000, #FFFFFF00, #1A1A1A)'
        >
          <Flex
            justifyContent='center' alignItems='center' flexDirection='column'
            rounded='50%' bg='#1A1A1ACC' _active={{bg: '#131313'}}
            w='100%' h='100%'
          >
            <Box color='#0080DD' fontSize='1.6rem'>{btn.icon}</Box>
            {title.toUpperCase() === "WEB-LINKS" && <Text
              color='#FFFFFF' fontFamily='Poppins'
              fontWeight='300' lineHeight='15px'
              fontSize='10px' pt='5.6px'
            >{btn.name.split('.')[0]}</Text>}
          </Flex>
        </Flex>
      </Tooltip>
      <Flex hidden={!isOpen} position='fixed' left='0' right='0' top='0' bottom='0' bg='#000000B2'/>
      <Placement isOpen={isOpen} onClose={onClose} title={btn.type}>
        <form onSubmit={onSend}>
          <PlacementInput
            name={'type_name'}
            placeholder={'Button name'}
            handleChange={handleTypeName}
            cleaner={() => {}}
            value={type_name}/>
          {values.map((value, index) => (
            <CustomInput
              key={index}
              index={index}
              addInput={addInput}
              removeInput={removeInput}
              handler={handleChange}
              name={btn.name.toLowerCase()}
              isLast={values[values.length - 1] === value}
              placeholder={values.length === 1 ? btn.placeholder : btn.placeholder + ` ${index + 1}`}
              value={value}
              leftIcon={btn.icon}
              type={btn.type_input}
              cleaner={(name) => {
              }}
            />
          ))}
          <DefButton
            isLoading={isLoading || loadingCompanyButton}
            type={'submit'}
            isRound={true}
          >SAVE</DefButton>
        </form>
      </Placement>
    </>
  );
};

export default ButtonCard;