import { Box, Skeleton, SkeletonText, Stack } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

const SkeletonCompany = () => {
  const { pathname } = useLocation();
  return (
    <Stack pb="1.4rem">
      <Box
        display={pathname.includes("user") ? "none" : "flex"}
        justifyContent="flex-end"
        pb="4px"
        pr="12px"
      >
        <Skeleton w="60px" height="18px" borderRadius={15} />
      </Box>
      <Skeleton minH="230px" borderRadius={8} />
      <Skeleton h="20px" w="50%" borderRadius={8} />
      <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
    </Stack>
  );
};

export default SkeletonCompany;
