import { Box, Image, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { LineVectorSvg } from "../../../../assets/svg/LineVectorSvg";
import { NFCBifIcon } from "../../../../assets/svg/NFCBifIcon";
import AboutPhoto from "../../../../assets/img/BackgroundAbout.webp";
import { SectionComponent } from "../../../../components/ui";

const AboutCompany: FC = () => {
  return (
    <SectionComponent idName="about">
      <Box fontFamily="Poppins" color="#ffffff" pt={{ md: "0", base: "80px" }}>
        <Box display="flex" flexDir={{ base: "column", md: "row" }} pb="10px">
          <Box
            fontSize="calc(40px + 2 * ((100vw - 220px) / (1280 - 320)))"
            pr="2rem"
            maxW="329px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontWeight="400" lineHeight="56px">
                About
              </Text>
              <LineVectorSvg />
            </Box>
            <Text fontWeight="400" lineHeight="56px">
              Company
            </Text>
          </Box>
          <Text textAlign="justify" fontWeight="300">
            Our company is a pioneering software developer specializing in NFC
            technology. We cater to businesses of all sizes, including those
            with minimal staffing. By harnessing the power of NFC, we offer
            solutions that eliminate the need for paper business cards, enabling
            efficient customer communication even with a small team.
          </Text>
        </Box>
        <Text textAlign="justify" fontWeight="300">
          Partner with us to optimize your operations, enhance customer
          engagement, and embrace a paperless approach while maximizing your
          resources.
        </Text>
        <Box
          my="25px"
          borderRadius={{ md: "20px", base: "10px" }}
          overflow="hidden"
          position="relative"
        >
          <Box className="background__gradient"></Box>
          <Box
            w="100%"
            display="flex"
            bg="rgba(255, 255, 255, 0.1)"
            flexDir={{ base: "column", md: "row" }}
            justifyContent={{ md: "space-between" }}
          >
            <Box pl="2rem" pr={{ base: "2rem", md: "0" }} zIndex={1} py="2rem">
              <Box py="10px" maxW="540px" minH={{ md: "155px", base: "90px" }}>
                <NFCBifIcon />
              </Box>
              <Text
                fontWeight="300"
                fontSize="15px"
                letterSpacing="1px"
                textAlign="justify"
                maxW="500px"
                my="2rem"
              >
                What truly sets our NFC cards apart is our proprietary software
                solution. Developed as a result of extensive research and
                engineering, our software is one-of-a-kind, with no equivalent
                in the market.
              </Text>
              <Text
                fontWeight="300"
                fontSize="14px"
                letterSpacing="1px"
                textAlign="justify"
                maxW={{ md: "632px", base: "100%" }}
                opacity="60%"
              >
                It represents the culmination of years of improvement,
                addressing and eliminating the shortcomings of previous software
                versions.
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              p="1.5rem"
              position="relative"
              maxW={{ md: "440x", base: "100%" }}
            >
              <Image
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                zIndex={2}
                src={AboutPhoto}
                alt={AboutPhoto}
                opacity="0.5"
              />
              <Text
                maxW="90%"
                zIndex={3}
                textAlign={{ md: "right", base: "center" }}
                fontSize="calc(40px + 2 * ((100vw - 220px) / (1280 - 320)))"
                fontWeight="500"
              >
                software & DATA server
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default AboutCompany;
