import React from 'react';
import {Button} from "@chakra-ui/react";

interface Props {
  children: any;
  handleClick?: () => void;
  border?: boolean;
  isDisabled?: boolean
  isLoading?: boolean
}
const WhiteButton = ({children, handleClick, isDisabled, isLoading}: Props) => {
  return (
    <Button
      isLoading={isLoading}
      onClick={handleClick}
      isDisabled={isDisabled}
      w='full' h='50px' mb={2}
      bg='#CACACA'
      color='#0080DD'
      _active={{bg: '#CACACA66'}}
      rounded='14px'
      fontFamily='Poppins'
      fontWeight='500'
      fontSize='17px'
    >
      {children}
    </Button>
  );
};

export default WhiteButton;