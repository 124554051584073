import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";

const PrivacyPolicyText: FC = () => {
  const EmailNFC = () => {
    return (
      <a
        href="mailto:info@nfc-bc.com"
        style={{
          padding: "0 5px",
          color: "#0080DD",
          fontWeight: "500",
        }}
      >
        info@nfc-bc.com
      </a>
    );
  };

  const BoldText = ({ text }: { text: string }) => {
    return (
      <Text mt="10px" fontWeight="500" fontSize="15px">
        {text}
      </Text>
    );
  };

  return (
    <Box
      color="#ffffff"
      pb="1.5rem"
      textAlign="justify"
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="14px"
      lineHeight="22px"
    >
      <Text fontSize="15px">
        By confirming the submission of the application, you consent to the
        processing of personal data and agree to the privacy policy. Privacy
        policy:
      </Text>
      <BoldText text="1. General Conditions" />
      <Text>
        1.1. This Privacy Policy (hereinafter referred to as the Policy) defines
        the procedure for processing and protecting information about
        individuals (hereinafter referred to as Users) that can be obtained by
        the NFC For Electronic Cards Trading CO. L.L.C. (hereinafter referred to
        as the Company) when the User uses the services provided through this
        software (hereinafter referred to as the software).
      </Text>
      <Text>
        1.2. The purpose of this Policy is to ensure proper protection of
        information about Users, including their personal data, from
        unauthorized access and disclosure.
      </Text>
      <Text>
        1.3. The relations related to the collection, storage, dissemination and
        protection of information provided by the User are governed by this
        Policy and the current legislation of the UAE.
      </Text>
      <Text>
        1.4. By using the software and providing their personal data using the
        form for providing their personal data: name and mobile phone, the User
        expresses his full consent to the terms of this Policy.
      </Text>
      <Text>
        1.5. If the User does not agree with the terms of this Policy, the use
        of the Software must be immediately terminated.
      </Text>
      <BoldText text="2. Collection of personal data" />
      <Text>
        2.1. When voluntarily submitting an application through a Software, the
        User provides the Company with information about his Name and mobile
        phone.
      </Text>
      <Text>
        2.2. The name is used to address the User personally, the phone number
        for contacting the User, sending additional materials, news from the
        Company.
      </Text>
      <Text>
        2.3. The User's personal data is not transferred to third parties, under
        any circumstances, except for the cases described in paragraph 2.4 of
        this Policy and related to compliance with legal requirements.
      </Text>
      <Text>
        2.4. The User's personal data may be transferred to Companies operating
        on our behalf and performing the function of business support, in
        connection with which your personal information may be partially
        disclosed.
      </Text>
      <BoldText text="3. Terms of personal data storage" />
      <Text>
        3.1 By accepting the terms of this Policy, the User agrees that:
      </Text>
      <Text>
        3.1.1 The Company stores the User's personal information for the entire
        duration of its operation.
      </Text>
      <BoldText text="4. Modification and deletion of personal data" />
      <Text>
        4.1. The User can refuse to receive mailing letters and delete his
        contact details from the database at any time by writing a letter
        requesting the cancellation of the mailing to our e-mail:
        <EmailNFC />
      </Text>
      <BoldText text="5. Changing the Privacy Policy" />
      <Text>
        5.1. The Company has the right to make changes to this Privacy Policy
        with mandatory notification of the User about these changes. When making
        changes in the current version, the date of the last update is
        indicated. The new version of the Policy comes into force from the
        moment it is posted in the software.
      </Text>
      <BoldText text="6. Feedback. Questions and suggestions" />
      <Text>
        6.1. Send all suggestions or questions about this Policy to our e-mail:
        <EmailNFC />
      </Text>
    </Box>
  );
};

export default PrivacyPolicyText;
