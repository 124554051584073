import { Box, SimpleGrid, Skeleton } from "@chakra-ui/react";
import React, { FC } from "react";

const ProductShopCardSkeleton: FC = () => {
  return (
    <SimpleGrid
      spacing={{ xl: "46px", md: "30px", base: "24px" }}
      columns={{ lg: 3, sm: 2, base: 1 }}
      my="1rem"
      position="relative"
      borderRadius="20px"
      overflow="hidden"
      p={{ lg: "2rem", md: "1.5rem", sm: "1rem", base: "0.8rem" }}
    >
      <Box className="background__gradient"></Box>
      {Array.from({ length: 6 }, (_, idx) => (
        <Box key={idx} zIndex="2" py="10px" px="6px">
          <Skeleton
            w="100%"
            h="69%"
            minH="174px"
            my="10px"
            borderRadius="10px"
          />
          <Box my="15px" display="flex" justifyContent="space-between">
            <Skeleton w="40%" h="20px" />
            <Skeleton w="40%" h="20px" />
          </Box>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default ProductShopCardSkeleton;
