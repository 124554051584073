export const StoreBasketSvg = () => {
  return (
    <svg
      width="39"
      height="31"
      viewBox="0 0 29 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54162 19.5224C7.67489 18.7064 7.1202 17.9371 6.30269 17.8041C5.48518 17.6711 4.71442 18.2247 4.58115 19.0407C4.44788 19.8566 5.00257 20.6259 5.82008 20.7589C6.63759 20.8919 7.40835 20.3383 7.54162 19.5224Z"
        fill="white"
      />
      <path
        d="M15.4039 19.5226C15.5372 18.7067 14.9825 17.9374 14.165 17.8044C13.3475 17.6713 12.5767 18.225 12.4435 19.0409C12.3102 19.8569 12.8649 20.6261 13.6824 20.7592C14.4999 20.8922 15.2707 20.3385 15.4039 19.5226Z"
        fill="white"
      />
      <path
        d="M17.4772 16.9253H2.26987C1.88473 16.9253 1.57324 16.6144 1.57324 16.23C1.57324 15.8456 1.88473 15.5347 2.26987 15.5347H16.9746L21.9903 0.475776C22.0848 0.191701 22.3515 0 22.6511 0H27.5026C27.8877 0 28.1992 0.310893 28.1992 0.695288C28.1992 1.07968 27.8877 1.39058 27.5026 1.39058H23.1536L18.138 16.4495C18.0434 16.7336 17.7767 16.9253 17.4772 16.9253Z"
        fill="white"
      />
      <path
        d="M15.6032 14.4172H3.73369C3.43414 14.4172 3.16743 14.2255 3.07289 13.9424L0.0356124 4.855C-0.0350451 4.64344 0.000781278 4.41002 0.131149 4.22825C0.262513 4.04648 0.472495 3.93921 0.69641 3.93921H18.6404C18.8644 3.93921 19.0743 4.04648 19.2057 4.22825C19.3371 4.41002 19.3719 4.64244 19.3012 4.855L16.264 13.9414C16.1694 14.2255 15.9027 14.4172 15.6032 14.4172ZM4.23526 13.0266H15.1006L17.6731 5.33078H1.66273L4.23526 13.0266Z"
        fill="white"
      />
    </svg>
  );
};
