import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductShop } from "../../../../../models/products";
import { ICategoryShop } from "../CategoriesShopReducer/interfaces";

interface IProductsState {
  isLoading: boolean;
  isError: string | null;
  local_products: IProductShop[];
  categoryProducts: ICategoryShop;
}

const initialState: IProductsState = {
  isError: null,
  isLoading: false,
  local_products: [],
  categoryProducts: { id: 0, title: "", products: [] },
};

export const productsSlice = createSlice({
  name: "get-products/shop",
  initialState,
  reducers: {
    getProducts(state) {
      state.isLoading = true;
    },
    getProductsSuccess(state, action: PayloadAction<ICategoryShop>) {
      state.isLoading = false;
      state.categoryProducts = action.payload;
      state.local_products = action.payload.products;
      state.isError = null;
    },
    getProductsError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = action.payload;
    },
    filterProducts(state, action: PayloadAction<ICategoryShop>) {
      state.categoryProducts = action.payload;
    },
  },
});

export const ProductsShopReducer = productsSlice.reducer;
export const { filterProducts } = productsSlice.actions;
