import React, {useState} from 'react';
import {Box, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import ModalInput from "../modalInput";
import {DefButton} from "../../../components/ui";
import {useNavigate} from "react-router-dom";
import EmailAdminSvg from "../modalInput/EmailAdminSvg";
import {onSendEmail} from "../AuthActions";
import CreatePassword from "./index";

interface Props {
  isOpen: boolean;
  onClose: () => void
}

const CreatePasswordEmail = ({isOpen, onClose}: Props) => {
  const [isLoading, setLoading] = useState(false)
  const {onOpen: onOpenNext, isOpen: isOpenNext, onClose: onCloseNext} = useDisclosure()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const closeModals = () => {
    onClose()
    onCloseNext()
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSendEmail(email, setLoading, onOpenNext)
  }
  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={() => {

      }} isCentered={true} size='xl'>
        <ModalOverlay bg='#151515'/>
        <ModalContent bg='#0E0E0E' px={{md: '53px', base: '4'}} pt='42px' pb='66px' rounded='31px'>
          <ModalCloseButton onClick={onClose} color='#A8A8A8'/>
          <form onSubmit={onSubmit}>
            <ModalInput
              handleChange={handleChange}
              value={email}
              type={'email'}
              name={'email'}
              icon={<EmailAdminSvg/>}
              placeholder={"Email"}
            />
            <Box mt='29px'>
              <DefButton isLoading={isLoading} isRound={true} isAdmin={true} type='submit'>Next</DefButton>
            </Box>
          </form>
        </ModalContent>
      </Modal>
      <CreatePassword isOpen={isOpenNext} onClose={closeModals}/>
    </>
  );
};

export default CreatePasswordEmail;