import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ADDRESS } from "../api";
import { IGalleryImage } from "../models/galleryShop";
import { IOurClient } from "../models/ourClientShop";

export const shopApi = createApi({
  reducerPath: "shopApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_ADDRESS }),
  endpoints: (builder) => ({
    getGalleryList: builder.query<IGalleryImage[], void>({
      query: () => "api/v1/shop/gallery/",
    }),
    getOurClientList: builder.query<IOurClient[], void>({
      query: () => "api/v1/shop/clients_logo",
    }),
  }),
});

export const { useGetGalleryListQuery, useGetOurClientListQuery } = shopApi;
