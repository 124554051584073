import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";

export const loginFormAction = createAction<boolean>("login/loginFormAction");
export const supportAction = createAction<boolean>("login/supportAction");
export const techSupportAction = createAction<boolean>(
  "login/techSupportAction"
);
export const modalFAQAction = createAction<boolean>("login/modalFAQAction");
export const firstTimeAction = createAction<boolean>("login/firstTimeAction");
export const forgotPswAction = createAction<boolean>("login/forgotPswAction");
export const messageAfterAction = createAction<boolean>(
  "login/messageAfterAction"
);
export const exchangeSaveAction = createAction<boolean>(
  "lead/exchangeSaveAction"
);
export const privacyPolicyAction = createAction<boolean>(
  "login/privacyPolicyAction"
);

export const setIsOpenLogin = (isOpen: boolean) => (dispatch: AppDispatch) => {
  dispatch(loginFormAction(isOpen));
};

export const setIsOpenSupport =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch(supportAction(isOpen));
  };

export const setIsTechSupport =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch(techSupportAction(isOpen));
  };

export const setIsOpenFAQ = (isOpen: boolean) => (dispatch: AppDispatch) => {
  dispatch(modalFAQAction(isOpen));
};

export const setIsFirstTime = (isOpen: boolean) => (dispatch: AppDispatch) => {
  dispatch(firstTimeAction(isOpen));
};

export const setIsForgotPsw = (isOpen: boolean) => (dispatch: AppDispatch) => {
  dispatch(forgotPswAction(isOpen));
};

export const setIsMessageEmail =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch(messageAfterAction(isOpen));
  };

export const setIsExchangeContact =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch(exchangeSaveAction(isOpen));
  };

export const setIsPrivacyPolicy =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch(privacyPolicyAction(isOpen));
  };
