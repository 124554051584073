import React, {useEffect, useState} from 'react';
import {Box, Flex, Input, InputGroup, InputRightElement, Text, useDisclosure} from "@chakra-ui/react";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {DefButton, ErrorText, PlacementInput} from "../../../../../components/ui";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store";
import Placement from "../../../../../components/ui/placement";
import {PiLinkSimpleHorizontal} from "react-icons/pi";
import {IYoutubeVideo} from "../companyReducer/types";
import {IVideos} from "../../../../../models/company";
import {addField} from "./reducer/youtube.slice";
import {addVideo, addVideoAdmin, removeVideo} from "./reducer/actions";

interface Props {
  name: string;
  placeholder: string;
  error?: string | undefined
  isLast: boolean;
  video: IVideos;
  inAdmin: boolean
}

const YoutubeInput = (
  {name, placeholder, video, error, isLast = true, inAdmin}
    : Props) => {
  const dispatch = useAppDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [validate, setValidate] = useState(false)
  const [value, setValue] = useState<IVideos>({...video})
  const {isLoading} = useAppSelector(state => state.VideosReducer)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.trim().length <= 125) {
      setValue({...value, [e.target.name]: e.target.value})
    }
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (inAdmin) {
      dispatch(addVideoAdmin(value, onClose))
    } else {
      dispatch(addVideo(value, onClose))
    }
  }
  const removeField = (id: number) => {
    dispatch(removeVideo(id, inAdmin))
  }

  const addVideoField = () => {
    dispatch(addField())
  }

  useEffect(() => {
    const array_link = video.url?.trim()
    setValidate(!array_link.includes(name) && !array_link.includes("youtu.be"))
  }, [video.url])


  useEffect(() => {
    setValue({...video})
  }, [isOpen])


  const cleaner = () => {
  }
  return (
    <Box mb='23px'>
      <InputGroup position='relative'>
        <Input
          onClick={onOpen}
          value={video.title}
          cursor='grab'
          isReadOnly={true}
          variant='none'
          name={name}
          type={"text"}
          placeholder={placeholder}
          h='56px' w='100%'
          borderTopRadius='4px'
          borderBottomRadius='0'
          border='none' bg={'rgba(46,46,46,0.38)'}
          fontSize='16px' letterSpacing='0.5px'
          fontWeight='400' fontFamily={"Poppins"}
          lineHeight='24px' color='#FFFFFF' pr={12}
          _placeholder={{color: 'white', opacity: '0.38'}}
        />
        {<InputRightElement display='flex' alignItems='center' h='full'>
          <Box
            onClick={() => isLast ? !validate && addVideoField() : removeField(Number(value.id))}
            color={isLast ? '#0080DD' : '#DC2626'}
            _active={{opacity: '0.2'}}
            opacity={isLast && validate ? '0.2' : 1}
            fontSize='1.8rem'
            cursor='pointer' mr={2}
          >
            {isLast ? <AiOutlinePlus/> : <AiOutlineMinus/>}
          </Box>
        </InputRightElement>}
      </InputGroup>
      <ErrorText>{validate && !!video.url?.trim().length ? error : ''}</ErrorText>

      <Flex hidden={!isOpen} position='fixed' left='0' right='0' top='0' bottom='0' bg='#000000B2'/>
      <Placement isOpen={isOpen} onClose={onClose} title={"Youtube video"}>
        <form onSubmit={onSubmit}>
          <PlacementInput
            name={"url"}
            type={"url"}
            leftIcon={<PiLinkSimpleHorizontal/>}
            placeholder={"video link"}
            cleaner={cleaner}
            handleChange={handleChange}
            value={value.url}
          />
          <PlacementInput
            name={"title"}
            value={value.title}
            placeholder={"video title"}
            cleaner={cleaner}
            handleChange={handleChange}
            required={false}
          />
          <PlacementInput
            as='textarea' value={value.description}
            name={"description"}
            placeholder={"video description"}
            cleaner={cleaner}
            handleChange={handleChange}
            required={false}
          />

          <Box
            fontFamily='Poppins' mt='27px' mb='10px' px={3}
            color='#797777' fontSize='15px' fontWeight='400' lineHeight='22.5px'
          >
            <Text color='#FFFFFF'>NOTICE:</Text>
            <Flex mt='10px'>
              <Text mx={2} color='#797777'>1. Erase </Text>
              <Text color='#0080DD'>youtu.be/</Text>
            </Flex>
            <Flex>
              <Text mx={2} color='#797777'>2. Edit link- write </Text>
              <Text color='#0080DD'>youtube.com/embed/</Text>
              <Text color='#797777'>2abcd </Text>
            </Flex>
          </Box>
          <DefButton isLoading={isLoading} type='submit' isRound={true}>{value.id ? 'Update' : 'SAVE'}</DefButton>
        </form>
      </Placement>

    </Box>

  );
};

export default YoutubeInput;