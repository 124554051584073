import React, { FC } from "react";
import { Box, Image } from "@chakra-ui/react";

//local
import Line2 from "../../../../assets/img/Lines2.webp";
import { SectionComponent } from "../../../../components/ui";
import { useGetGalleryListQuery } from "../../../../redux/shopApi";
import GallerySlider from "./GallerySlider";

const Gallery: FC = () => {
  const { data } = useGetGalleryListQuery();

  return (
    <SectionComponent idName="gallery">
      <Box
        position="relative"
        overflow="hidden"
        borderRadius={{ md: "20px", base: "10px" }}
        mt={{ md: "25px", base: "80px" }}
        mb="25px"
      >
        <Box className="background__gradient"></Box>
        {data?.length && <GallerySlider gallery={data} />}
      </Box>
      <Image
        src={Line2}
        alt={`${Line2}_photo`}
        position="absolute"
        left="0"
        zIndex="0"
        top="-60%"
        height="200%"
      />
    </SectionComponent>
  );
};

export default Gallery;
