import { createSlice } from "@reduxjs/toolkit";
import { IStateStatistics } from "./interfaces";

const initialState: IStateStatistics = {
  isLoading: false,
  statistics: {
    by_date: 0,
    total: 0,
  },
  error: "",
};

export const statisticsSlice = createSlice({
  name: "get/statistics",
  initialState,
  reducers: {
    getStatisticsLoading(state) {
      state.isLoading = true;
    },
    getStatisticsSuccess(state, action) {
      state.statistics = action.payload;
      state.isLoading = false;
      state.error = "";
    },
    getStatisticsError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const StatisticsReducer = statisticsSlice.reducer;
