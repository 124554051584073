import { AppDispatch } from "../../../../../redux/store";
import PRIVATE_API, { PUBLIC_API } from "../../../../../api";
import { IProfileValue, profileSlice } from "./profileSlice";
import { postProfileSlice } from "./postProfile.slice";
import { RootProfile } from "../../../../../models/profile";
import {
  changeTitle,
  logout,
  toLocalStorage,
  useEncryption,
} from "../../../../../config/helpers";
import { getChoseFont } from "../../FontReducer/fonts-slice";
import { menuButtonsSLice } from "../../../../../components/custom-buttons/CustomReducer/menuButton.slice";
import { getCompanies } from "../../Company/companyReducer/actions";
import { toastCreator } from "../../../../../config/toast";
import toast from "react-hot-toast";

export const addProfile =
  (data: IProfileValue, navigate: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(postProfileSlice.actions.addProfile());
      await PRIVATE_API.patch("api/v1/users/profile/", data);
      dispatch(postProfileSlice.actions.addProfileSuccess());
      toLocalStorage("wlcm", data.welcome_text);
      dispatch(getProfile());
      navigate("/profile");
      toast.success("Profile successfully updated");
    } catch (e: any) {
      toastCreator("error", e.response?.data?.message || e.detail);
      dispatch(
        postProfileSlice.actions.addProfileError(
          e.response?.data?.message || e.detail
        )
      );
    }
  };

export const getProfile = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.getProfile());
    const { data } = await PRIVATE_API.get<RootProfile>(
      "/api/v1/users/profile/"
    );
    dispatch(getCompanies(data.card_id));
    dispatch(getProfileSimilar(data));
  } catch (e: any) {
    if (e.response?.data?.message?.includes("token")) logout();
    dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
  }
};

export const getUserById =
  (card_id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.getProfile());
      const { data } = await PUBLIC_API.get<RootProfile>(
        `/api/v1/users/${card_id}/profile/`
      );
      logout();
      dispatch(getCompanies(data.card_id));
      dispatch(getProfileSimilar(data));
    } catch (e: any) {
      dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
    }
  };

export const getProfileSimilar =
  (data: RootProfile) => async (dispatch: AppDispatch) => {
    try {
      await toLocalStorage("wlcm", data.welcome_text);
      const menu_buttons = data.menu_buttons.map((el) => {
        return { ...el, value: JSON.parse(JSON.stringify([el.value])) };
      });
      const [full_name, fontName] = useEncryption(data.full_name);
      const [welcome_text, fontWelcome] = useEncryption(data.welcome_text);

      await dispatch(
        profileSlice.actions.getProfileSuccess({
          ...data,
          welcome_text,
          avatar: data.avatar ? data.avatar : "",
          full_name,
          menu_buttons,
        })
      );

      changeTitle(data.full_name);

      dispatch(getChoseFont({ name: "full_name", font: fontName }));
      dispatch(getChoseFont({ name: "welcome_text", font: fontWelcome }));
      dispatch(
        menuButtonsSLice.actions.getMenuButtonFromProfile(data.menu_buttons)
      );
    } catch (e: any) {
      dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
    }
  };
