import React from 'react';

const StatisticsSvg = () => {
  return (
    <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="11.3688" width="7.57898" height="24.6317" fill="#0080DD"/>
      <rect x="22.7363" width="7.57898" height="36.0002" fill="#0080DD"/>
      <rect x="11.3672" y="17.0533" width="7.57898" height="18.9475" fill="#0080DD"/>
    </svg>
  );
};

export default StatisticsSvg;