import React from 'react';
import {
  Box,
  Container, Drawer,
  DrawerContent, Flex,
} from "@chakra-ui/react";
import TitleComponents from "../../../components/ui/text/TitleComponents";
import {EditTypes} from "../../../models/staticWords";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import { useAppDispatch } from '../../../redux/store';
import { resetAllFields } from './Company/companyReducer/actions';

interface Props {
  children: JSX.Element;
  title: EditTypes;
}
const Editing = ({children, title}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const goBack = async() => {
    await dispatch(resetAllFields())
    navigate('/profile')
  }
  return (
    <Box w='full' pt={6} h='100%'>
      <Flex justifyContent='space-between' position='relative' alignItems='center' px='10px'>
        <Box
          onClick={goBack}
          color='white' fontSize='1.8rem'
          cursor='pointer'><BiArrowBack/>
        </Box>
        <TitleComponents>{title}</TitleComponents>
        <Box w='26px'/>
      </Flex>
      <Drawer placement={'bottom'} onClose={goBack} isOpen={true} >
        <DrawerContent bg='#151515' borderTopRadius='15px' pt='20.74px' mt={1} w={INTERFACE_WIDTH} mx='auto' h='90%' transition='.1s'>
          <Container maxW='container.xl' overflowY='auto' className='unscroll' h='full'>
            {children}
          </Container>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Editing;