import {
  Box, Image,
  Text,
} from "@chakra-ui/react";
import React, {FC} from "react";

//local
import CheckoutProductCard from "./CheckoutProductCard";
import {useAppSelector} from "../../../redux/store";
import CheckoutForm from "./CheckoutForm";
import {SectionComponent} from "../../../components/ui";
import Gradient from "../../../assets/img/CheckoutGradient.webp"

const CheckoutPage: FC = () => {
  const {cart} = useAppSelector(state => state.CartShopReducer)

  return (
    <SectionComponent>
      <Box pt={{ md: "100px", base: "90px" }} fontFamily="Poppins" color="white" pb='55px'>
        <Text fontSize="36px" fontWeight="400">
          Checkout
        </Text>
        <Box position='relative' overflow='hidden' mt='20px' 
             borderRadius={{md:"40px", base:"22px"}}
            >
          <Image
            src={Gradient} alt={Gradient}
            position='absolute' w='100%' h='100%'
            top='0' left='0' bottom='0' right='0'
            zIndex='1'
            opacity='.4'/>
          <Box
            px={{lg: '45px', md: '30px', base: '10px'}} py='45px'
            display="flex"
            zIndex='2' backdropFilter='blur(15px)'
            flexDirection={{md: 'row', base: 'column-reverse'}}
            position='relative'
            justifyContent="space-between"
          >
            <Box maxW={{md: '351px', base: '400px'}} mr={{lg: '54px', md: '20px', base: '0'}} mx='auto'
                 mt={{md: '0', base: '56px'}}>
              <CheckoutForm/>
            </Box>
            <Box w="100%">
              <Box
                display="flex"
                justifyContent="space-between"
                px="1rem"
                fontSize="12px"
                color="rgba(255, 255, 255, 1)"
                opacity="50%"
              >
                <Text>product</Text>
                <Box w="50%" display={{xl: 'flex', md: 'none', sm: 'flex', base: 'none'}}
                     justifyContent="space-between">
                  <Text>price</Text>
                  <Text>quantity</Text>
                  <Text>total</Text>
                </Box>
              </Box>
              {cart?.map(el => (
                <CheckoutProductCard
                  key={el.id}
                  product={el}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default CheckoutPage;
