import { Box, Button, Text, VStack } from "@chakra-ui/react";
import React, { FC, useState } from "react";

//local
import { Containers } from "../../../../components/ui";
import VideoComponent from "./VideoComponent";
import TextDot from "./TextDot";

interface Props {
  height: number;
}

const Hero: FC<Props> = ({ height }) => {
  const handleScroll = () => {
    const galleryElement = document.getElementById("products");
    if (galleryElement) {
      galleryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isShow, setShow] = useState(false);
  window.addEventListener("scroll", () => {
    // console.log('scrollY: ',window.scrollY)
    setShow(window.scrollY > height);
  });

  return (
    <>
      <VideoComponent />
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        zIndex="2"
        width="100%"
        bg="rgba(0, 0, 0, 0.5)"
        fontFamily="Poppins"
        color="rgba(255, 255, 255, 1)"
      >
        <Containers>
          <Box
            display="flex"
            flexDir="column"
            h={{ md: "768px", base: "700px" }}
            justifyContent="center"
            pt={{ md: "40px", base: "60px" }}
            maxW={{ md: "95%", base: "100%" }}
            mx="auto"
          >
            <VStack spacing={4} align="start">
              <Text
                fontSize={{ base: "30px", md: "38px", xl: "48px" }}
                fontWeight="bold"
                lineHeight={{ base: "35px", md: "45px", xl: "60px" }}
                color="brandColor"
              >
                Goodbye Paper, Hello Future!
              </Text>
              <Box>
                <Text
                  fontSize={{ base: "24px", md: "26px", xl: "32px" }}
                  lineHeight={{ base: "30px", md: "36px", xl: "40px" }}
                >
                  <span style={{ fontWeight: "600" }}>
                    Instant NFC Business Cards!
                  </span>
                  <br />
                  Save trees and money!
                </Text>
                <Text
                  fontSize={{ md: "20px", base: "14px" }}
                  lineHeight={{ md: "42px", base: "28px" }}
                  mt="20px"
                >
                  Craft Your Digital Identity in Just{" "}
                  <span style={{ fontWeight: "600" }}>40</span>
                  {` `}
                  Seconds!
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  my={{ md: "10px", base: "15px" }}
                  pt={{ md: "30px", base: "20px" }}
                  fontSize="14px"
                >
                  <TextDot />
                  <Text letterSpacing="2px" textAlign="left">
                    <span style={{ fontWeight: "600" }}>
                      Digital Handshake: {` `}
                    </span>
                    A unique
                    <br className="br_text" />
                    URL that sets you apart!
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  my={{ md: "10px", base: "15px" }}
                  fontSize="14px"
                >
                  <TextDot />
                  <Text letterSpacing="2px" textAlign="left">
                    <span style={{ fontWeight: "600" }}>
                      Tap & Connect: {` `}
                    </span>
                    Swift, stylish,
                    <br className="br_text" />
                    and impactful every time.
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  my={{ md: "10px", base: "15px" }}
                  pb={{ md: "30px", base: "20px" }}
                  fontSize="14px"
                >
                  <TextDot />
                  <Text letterSpacing="2px" textAlign="left">
                    <span style={{ fontWeight: "600" }}>
                      Eco-Friendly: {` `}
                    </span>
                    Preserve nature
                    <br className="br_text" />
                    by ditching paper cards!
                  </Text>
                </Box>
                <Text fontSize="18px">Upgrade & Network Sustainably!</Text>
              </Box>
              <Button
                colorScheme="blue"
                maxW={{ base: "250px", md: "300px" }}
                w="100%"
                bg="rgba(0, 132, 255, 1)"
                onClick={handleScroll}
              >
                To ORDER
              </Button>
            </VStack>
          </Box>
        </Containers>
      </Box>
      <Box
        bg="#1F1F1F"
        py={{ md: 4, base: 2 }}
        position="fixed"
        bottom={isShow ? "0" : "-100%"}
        transition="1.2s"
        zIndex="1000"
        left="0"
        w="100%"
      >
        <Containers>
          <Button
            colorScheme="blue"
            maxW={{ base: "250px", md: "300px" }}
            bg="rgba(0, 132, 255, 1)"
            w="100%"
            onClick={handleScroll}
          >
            To ORDER
          </Button>
        </Containers>
      </Box>
    </>
  );
};

export default Hero;
