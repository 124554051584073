import { AppDispatch } from "../../../../../redux/store";
import { PUBLIC_API } from "../../../../../api";
import { productsSlice } from "./slice";
import { RootProductsShop } from "../../../../../models/products";

export const getProductsShop = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(productsSlice.actions.getProducts());
    const { data } = await PUBLIC_API.get<RootProductsShop>(
      `api/v1/shop/products/`
    );
    dispatch(
      productsSlice.actions.getProductsSuccess({
        id: 0,
        title: "",
        products: data,
      })
    );
  } catch (e: any) {
    dispatch(productsSlice.actions.getProductsError(e.response?.data?.message));
  }
};
