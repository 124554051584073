import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface CustomState {
  isLoading: boolean;
  isError: string | null;
}

const initialState: CustomState = {
  isLoading: false,
  isError: "",
}

export const customSlice = createSlice({
  name: 'custom-buttons/post',
  initialState,
  reducers: {
    addButton(state){
      state.isLoading = true
    },
    addedButton(state, action: PayloadAction<string | null>){
      state.isLoading = false
      state.isError = action.payload
    },
  }
})

export const AddCustomReducer = customSlice.reducer