import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface LocationState {
  isError: string | null;
  isLoading: boolean;
  locations: number[];
}

const initialState: LocationState = {
  isError: null,
  isLoading: false,
  locations: [],
}

export const locationSLice = createSlice({
  name: 'location/post',
  initialState,
  reducers: {
    addLocation(state) {
      state.isLoading = true;
    },
    addedLocation(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const LocationReducer = locationSLice.reducer
