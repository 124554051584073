import React from 'react';
import {DefButton} from "../../../../../components/ui";
import {Box, Flex, SimpleGrid, useDisclosure} from "@chakra-ui/react";
import {AiOutlinePlus} from "react-icons/ai";
import Location from "../location";
import {HiMinusSm} from "react-icons/hi";
import CustomModal from "../../../../../components/custom-buttons/custom-modal";
import {IContactButton} from "../../../../../models/profile";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store";
import {addCompanyButtons, addCompanyButtonsAdmin, removeButton} from "./reducer/actions";
interface Props {
  inAdmin: boolean
}
const ButtonLinks = ({inAdmin}: Props) => {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const dispatch = useAppDispatch()
  const {buttons} = useAppSelector(state => state.ButtonsReducer)

  const deleteButton = (id: number) => {
    dispatch(removeButton(id, inAdmin))
  }

  const onSubmit = (button: IContactButton, values: string[], closeModals: () => void) => {
    if (inAdmin){
      dispatch(addCompanyButtonsAdmin(button, values, closeModals))
    }else {
      dispatch(addCompanyButtons(button, values, closeModals))
    }
  }

  return (
    <Box mb='28px'>
      <Flex mt='34px' mb='2.5'>
        <DefButton handleClick={onOpen}>
          <Box fontSize='1.2rem' mr={2}><AiOutlinePlus/></Box>
          Add Button
        </DefButton>
        <Box w='18px'/>
        <Location inAdmin={inAdmin}/>
      </Flex>
      <SimpleGrid columns={2} spacing={'9.51px'}>
        {buttons.map(button => (
          <Flex
            key={button.id}
            bg='#FFFFFF0D' rounded='8px' h='63.73px' position='relative'
            justifyContent='center' alignItems='center'
          >
            <Flex
              onClick={() => deleteButton(Number(button.id))}
              position='absolute' right='6.39px' zIndex='2' top='8.49px'
              justifyContent='center' alignItems='center'
              color='#D9D9D9' bg='#DC2626' w='19.9px' h='20px'
              rounded='50%' fontSize='1rem'
              _active={{opacity: 0.7}} cursor='pointer'
            ><HiMinusSm/>
            </Flex>
            <Box color='#D1D1D1' fontSize='1.4rem'>{button.icon}</Box>
          </Flex>
        ))}
      </SimpleGrid>
      <CustomModal
        onSubmit={onSubmit}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default ButtonLinks;