import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import React, {FC} from "react";
import {DeleteIconSvg} from "../../../assets/svg/DeleteIconSvg";
import {IProductCart} from "../../../models/products";
import {useAppDispatch} from "../../../redux/store";
import {decreaseQuantity, increaseQuantity, removeCart} from "./CartReducer/slice";

interface IProps {
  product: IProductCart
}

const BasketCard: FC<IProps> = ({product}) => {
  const dispatch = useAppDispatch()
  const sum_price = product.quantity * product.price

  const deleteCart = () => {
    dispatch(removeCart(product.id))
  }

  const increaseCart = () => {
    dispatch(increaseQuantity(product.id))
  }
  const decreaseCart = () => {
    dispatch(decreaseQuantity(product.id))
  }


  return (
    <Box
      bg="#303030"
      my="20px"
      fontFamily="Poppins"
      borderRadius="14px"
      display="flex"
      alignItems={{lg: 'center', base: 'start'}}
      justifyContent="space-between"
      flexDirection={{lg: 'row', base: 'column'}}
      p="1rem"
    >
      <Box display="flex" alignItems="center">
        <Image src={product.image} alt={`${product.image}_photo`} maxW="131px"/>
        <Box pl="1rem">
          <Text color="#ffffff" fontWeight="400" fontSize={{md: '18px', base: '16px'}}>
            {product.title}
          </Text>
          <Text
            opacity="50%" mt='20px'
            color="rgba(255, 255, 255, 1)"
            fontWeight="300"
            fontSize={{md: '16px', base: '14px'}}
          >
            {product.description}
          </Text>
        </Box>
      </Box>


      <Flex w={{lg: '55%', base: '100%'}} mt={{lg: '0', base: '20px'}} alignItems='center' justifyContent='space-between'>
        <Text opacity="50%" fontSize={{sm: '16px', base: '12px'}} color="rgba(255, 255, 255, 1)">{`${product.price} AED`}</Text>
        <Box display="flex" alignItems="center">
          <Button
            onClick={decreaseCart}
            borderRadius="8px"
            fontSize={{md: '18px', base: '14px'}}
            bg="rgba(255, 255, 255, 0.1)"
            color="#ffffff"
            colorScheme="rgba(255, 255, 255, 0.9)"
            h="27px"
            isDisabled={product.quantity === 1}
          >
            -
          </Button>
          <Text mx="10px" color="#ffffff" fontSize={{sm: '16px', base: '12px'}}>
            {product?.quantity}
          </Text>
          <Button
            onClick={increaseCart}
            borderRadius="8px"
            fontSize={{sm: '18px', base: '14px'}}
            bg="rgba(255, 255, 255, 0.1)"
            color="#ffffff"
            colorScheme="rgba(255, 255, 255, 0.9)"
            h="27px"
          >
            +
          </Button>
        </Box>
        <Text fontSize={{sm: '16px', base: '12px'}} color="#ffffff">{`${sum_price} AED`}</Text>
        <Box
          onClick={deleteCart}
          cursor="pointer">
          <DeleteIconSvg/>
        </Box>
      </Flex>
    </Box>
  );
};

export default BasketCard;
