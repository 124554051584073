import React from 'react';

const ShareSvg = () => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8298 4.74856L15.1686 14.0334L13.9098 11.26L13.6219 10.6255L12.9679 10.8661C7.09148 13.0282 3.88721 16.7317 2.16059 19.9096C1.62276 20.8995 1.22905 21.8368 0.941741 22.6586C0.221705 17.9926 1.62516 14.3316 3.59081 11.5845C5.97514 8.25224 9.20099 6.25273 10.5423 5.51868L11.1528 5.18454L10.8652 4.55078L9.67921 1.93789L20.8298 4.74856Z" stroke="#4F8BFF" strokeWidth="1.5"/>
    </svg>
  );
};

export default ShareSvg;