import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";

//local dependencieas
import {privateInterfaceRoutes, routesAdmin, routesInterface, routesStore} from "./routes";
import ProtectedUserInterface from "./ProtectedUserInterface";
import { InterfacePages, NotFoundPage, StorePages } from "../pages";
import Admin from "../pages/admin";
import ProtectAdminPage from "./ProtectAdminPage";

const RoutesRender = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scroll(0, 0), [pathname]);

  return (
    <Box position="relative" bg="#000">
      <Toaster position="top-center" reverseOrder={true} />
      <Routes>
        <Route>
          {routesInterface.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>
        <Route element={<ProtectedUserInterface />}>
          {privateInterfaceRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>
        <Route>
          {routesStore.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<StorePages>{route.element}</StorePages>}
            />
          ))}
        </Route>
        <Route element={<ProtectAdminPage/>}>
          {routesAdmin.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<Admin>{route.element}</Admin>}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Box>
  );
};

export default RoutesRender;
