import PRIVATE_API, { PUBLIC_API } from "../../api";
import { AppDispatch } from "../store";
import { ILead, RootLeads } from "./interface";
import { leadsSlice } from "./leadsSlice";
import { setIsExchangeContact } from "../loginFormInterface/action";
import { toastCreator } from "../../config/toast";

export const getLeads = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(leadsSlice.actions.getLeadsLoading());
    const { data } = await PRIVATE_API.get<RootLeads[]>("api/v1/users/leads/");
    dispatch(leadsSlice.actions.getLeadsSuccess(data));
  } catch (error: any) {
    dispatch(leadsSlice.actions.getLeadsError(error.message));
  }
};

export const deleteLead = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(leadsSlice.actions.getLeadsLoading());
    await PRIVATE_API.delete(`api/v1/users/leads/${id}/`);
    toastCreator("success", "Data deleted successfully");
    dispatch(getLeads());
  } catch (error: any) {
    dispatch(leadsSlice.actions.getLeadsError(error.message));
    toastCreator("error", "Error when deleting");
  }
};

export const createLead =
  (c_id: string, objContact: ILead) => async (dispatch: AppDispatch) => {
    try {
      dispatch(leadsSlice.actions.getLeadsLoading());
      await PUBLIC_API.post(`api/v1/users/leads/create/${c_id}/`, objContact);
      toastCreator("success", "Data sent successfully");
      dispatch(leadsSlice.actions.getLeadsSuccess([]));
      dispatch(setIsExchangeContact(false));
    } catch (error: any) {
      dispatch(leadsSlice.actions.getLeadsError(error.message));
      toastCreator("error", "Sending error");
    }
  };
