import React, { FC, ReactNode } from "react";

//local dependencies
import { Footer, Header } from "../../components";

interface IProps {
  children: ReactNode;
}

const StorePages: FC<IProps> = ({ children }) => {
  return (
    <div className="layout-store">
      <Header />
      <main className="main">{children}</main>
      <Footer />
    </div>
  );
};

export default StorePages;
