export const InstagramIconSvg = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0044 2.74951H8.99587C5.08287 2.74951 2.75012 5.08226 2.75012 8.99526V17.993C2.75012 21.9168 5.08287 24.2495 8.99587 24.2495H17.9936C21.9066 24.2495 24.2394 21.9168 24.2394 18.0038V8.99526C24.2501 5.08226 21.9174 2.74951 18.0044 2.74951ZM13.5001 17.6705C11.1996 17.6705 9.32912 15.8 9.32912 13.4995C9.32912 11.199 11.1996 9.32851 13.5001 9.32851C15.8006 9.32851 17.6711 11.199 17.6711 13.4995C17.6711 15.8 15.8006 17.6705 13.5001 17.6705ZM19.8641 7.99551C19.8104 8.12451 19.7351 8.24276 19.6384 8.35026C19.5309 8.44701 19.4126 8.52226 19.2836 8.57601C19.1546 8.62976 19.0149 8.66201 18.8751 8.66201C18.5849 8.66201 18.3161 8.55451 18.1119 8.35026C18.0151 8.24276 17.9399 8.12451 17.8861 7.99551C17.8324 7.86651 17.8001 7.72676 17.8001 7.58701C17.8001 7.44726 17.8324 7.30751 17.8861 7.17851C17.9399 7.03876 18.0151 6.93126 18.1119 6.82376C18.3591 6.57651 18.7354 6.45826 19.0794 6.53351C19.1546 6.54426 19.2191 6.56576 19.2836 6.59801C19.3481 6.61951 19.4126 6.65176 19.4771 6.69476C19.5309 6.72701 19.5846 6.78076 19.6384 6.82376C19.7351 6.93126 19.8104 7.03876 19.8641 7.17851C19.9179 7.30751 19.9501 7.44726 19.9501 7.58701C19.9501 7.72676 19.9179 7.86651 19.8641 7.99551Z"
        fill="white"
      />
    </svg>
  );
};
