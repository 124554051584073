export const LineVectorSvg = () => {
  return (
    <svg
      width="34"
      height="39"
      viewBox="0 0 34 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L33 37.5111L1 1ZM33 37.5111V2.17778V37.5111ZM33 37.5111L2 37.5111L33 37.5111Z"
        fill="white"
      />
      <path
        d="M1 1L33 37.5111M33 37.5111V2.17778M33 37.5111L2 37.5111"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
