import React from 'react';
import {Box} from "@chakra-ui/react";
import {YoutubeInput} from "../../../../../components/ui";
import {useAppSelector} from "../../../../../redux/store";
import {defaultVideo} from "./reducer/youtube.slice";
interface Props {
  inAdmin: boolean
}
const YoutubeLink = ({inAdmin}: Props) => {
  const {videos} = useAppSelector(state => state.VideosReducer)

  return (
    <Box>
      {[...videos, defaultVideo].map((video, index) =>
        <YoutubeInput
          key={index}
          name={'youtube'}
          video={video}
          placeholder={`Youtube Video ${!!videos.length ? index + 1 : ''}`}
          isLast={videos.length === index}
          error={"link must be from youtube"}
          inAdmin={inAdmin}
        />
      )}
    </Box>
  );
};

export default YoutubeLink;