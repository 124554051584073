import {IMenuButtonServer} from "../../../../../../models/profile";
import {AppDispatch} from "../../../../../../redux/store";
import PRIVATE_API from "../../../../../../api";
import {getCompanyLocationId} from "../../companyReducer/addCompany.slice";
import {locationSLice} from "./location.slice";
import {ILocation} from "../../../../../../models/company";
import {toastCreator} from "../../../../../../config/toast";
import PRIVATE_API_ADMIN from "../../../../../../api/admin/API";


export const addLocations = (data: ILocation, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(locationSLice.actions.addLocation())
    const response = data.id ?
      await PRIVATE_API.put<ILocation>(`api/v1/users/locations/${data.id}/`, {
        main_branch: data.main_branch,
        tower_name: data.tower_name,
        street: data.street,
        city_country: data.city_country,
        map_link: data.map_link,
      }) :
      await PRIVATE_API.post<ILocation>('api/v1/users/locations/',data)
    dispatch(locationSLice.actions.addedLocation(null))
    dispatch(getCompanyLocationId(Number(response.data.id)))
    toastCreator('success')
    onClose()
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(locationSLice.actions.addedLocation(e.response?.data?.message))
  }
}


export const addLocationsAdmin = (data: ILocation, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(locationSLice.actions.addLocation())
    const response = data.id ?
      await PRIVATE_API_ADMIN.put<ILocation>(`api/v1/users/locations/${data.id}/`, {
        main_branch: data.main_branch,
        tower_name: data.tower_name,
        street: data.street,
        city_country: data.city_country,
        map_link: data.map_link,
      }) :
      await PRIVATE_API_ADMIN.post<ILocation>('api/v1/users/locations/',data)
    dispatch(locationSLice.actions.addedLocation(null))
    dispatch(getCompanyLocationId(Number(response.data.id)))
    toastCreator('success')
    onClose()
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(locationSLice.actions.addedLocation(e.response?.data?.message))
  }
}