import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

//local dependencies
import { Btn, DefButton, ErrorText, EyeInput } from "../ui";
import { UnlockIcon } from "../../assets/svg/UnlockIcon";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setIsFirstTime,
  setIsForgotPsw,
  setIsOpenLogin,
  setIsOpenSupport,
} from "../../redux/loginFormInterface/action";
import { PUBLIC_API } from "../../api";
import { toLocalStorage } from "../../config/helpers";
import { toastCreator } from "../../config/toast";

const LoginUser: FC = () => {
  const dispatch = useAppDispatch();
  const [eye, setEye] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [errorLogin, setErrorLogin] = useState<string>("");

  const { user } = useAppSelector((s) => s.ProfileReducer);
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    setErrorLogin("");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoad(true);
    PUBLIC_API.post("api/v1/users/auth/", {
      email: user.email,
      password,
    })
      .then(({ data }) => {
        toLocalStorage("access", data.access);
        toLocalStorage("refresh", data.refresh);
        if (!!data.is_first_time) {
          dispatch(setIsFirstTime(true));
        } else {
          dispatch(setIsOpenLogin(false));
          toastCreator("success", "Welcome to profile");
          navigate(`/profile`);
        }
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        setErrorLogin("Incorrect password. Please try again.");
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box mb="30px">
          <InputGroup border="none">
            <InputLeftElement
              w="3.2rem"
              h="100%"
              display="flex"
              alignItems="center"
            >
              <Box pr="10px">
                <UnlockIcon />
              </Box>
            </InputLeftElement>
            <Input
              type={eye ? "text" : "password"}
              onChange={handleChange}
              required
              bg="#353535"
              borderColor={errorLogin.length > 0 ? "#B3261E" : "blue.500"}
              borderRadius="30px"
              placeholder="Password"
              fontFamily="Poppins"
              _placeholder={{
                color: "#A8A8A8",
                fontSize: "15px",
              }}
              color="#FFFFFF"
              py="25px"
            />
            <InputRightElement
              w="3rem"
              h="100%"
              display="flex"
              alignItems="center"
            >
              <EyeInput eyeIsShow={eye} setEyeIsShow={setEye} />
            </InputRightElement>
          </InputGroup>
          <ErrorText children={errorLogin} />
          <Box
            display={!!user.is_first_time ? "none" : "flex"}
            justifyContent="flex-end"
          >
            <Text
              mt="10px"
              mr="5px"
              color="#0080DD"
              fontFamily="Poppins"
              fontSize="14px"
              cursor="pointer"
              onClick={() => dispatch(setIsForgotPsw(true))}
            >
              Forgot password
            </Text>
          </Box>
        </Box>
        <DefButton type="submit" isLoading={load} children="Login" />
      </form>
      <Box my="20px">
        <Btn text="Support" onClick={() => dispatch(setIsOpenSupport(true))} />
      </Box>
    </>
  );
};

export default LoginUser;
