import React from 'react';

const YoutubeSvg = () => {
  return (
    <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.1547 13.2882C26.1547 15.1791 24.5592 16.7311 22.5265 16.7311H4.72541C2.70638 16.7311 1.12305 15.1823 1.12305 13.2882V3.97423C1.12305 2.09368 2.70763 0.53125 4.72541 0.53125H22.5265C24.5579 0.53125 26.1547 2.09689 26.1547 3.97423V13.2882Z"
            stroke="#FFFFFF"/>
      <path d="M18.7588 8.90847L11.5859 13.8955V3.93652L18.7588 8.90847Z" fill="#FFFFFF"/>
    </svg>
  );
};

export default YoutubeSvg;