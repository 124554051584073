import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export type InputFontsType = 'full_name' | 'welcome_text' | 'name'

interface IPayload {
  name: InputFontsType;
  font: string;
}

interface FontsState {
  full_name_font: string;
  welcome_text_font: string;
  company_name_font: string;
}

const initialState: FontsState = {
  full_name_font: "Poppins",
  welcome_text_font: "Poppins",
  company_name_font: "Poppins",
}

const fontSlice = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    getChoseFont(state, action: PayloadAction<IPayload>) {
      switch (action.payload.name) {
        case "full_name":
          state.full_name_font = action.payload.font || "Poppins"
          break;
        case "welcome_text":
          state.welcome_text_font = action.payload.font || "Poppins"
          break;
        case "name":
          state.company_name_font = action.payload.font || "Poppins"
          break;
        default :
          return state
      }
    },
  }
})

export const FontReducer = fontSlice.reducer
export const {getChoseFont} = fontSlice.actions