import { Box } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";
import TitleComponents from "../../../components/ui/text/TitleComponents";

class TitleAnalytics extends Component {
  render(): ReactNode {
    return (
      <Box pt={6} pb={4}>
        <TitleComponents>Analytics</TitleComponents>
      </Box>
    );
  }
}

export default TitleAnalytics;
