import React, { FC, useEffect, useState } from "react";

//local
import ResetPassword from "../../../components/forms/ResetPassword";
import { Placement } from "../../../components";

const ResetLink: FC = () => {
  const [rpt, setRpt] = useState<string | null>(null);
  const [uci, setUci] = useState<string | null>(null);

  useEffect(() => {
    // Получаем текущий URL
    const url = new URL(window.location.href);

    // Получаем параметры строки запроса
    const params = new URLSearchParams(url.search);

    // Извлекаем значения параметров
    const _rpt = params.get("rpt");
    setRpt(_rpt);
    const _uci = params.get("uci");
    setUci(_uci);
  }, [rpt, uci]);

  if (rpt === null || uci === null) {
    return null;
  }

  return (
    <>
      <Placement
        isOpen={true}
        onClose={() => {}}
        children={<ResetPassword rpt={rpt} uci={uci} />}
        title="new password"
        viewvBtn={true}
      />
    </>
  );
};

export default ResetLink;
