import React, {FC} from 'react';
import {Box} from "@chakra-ui/react";

const Admin: FC<{children: any}> = ({children}) => {
  return (
    <Box bg='#151515' w='100%' minH='100vh' px={{md: '50px', base: 4}} py='25px'>
      {children}
    </Box>
  );
};

export default Admin;