import React from "react";
import {Box, Text} from "@chakra-ui/react";

//local
import LocationImage from "../../../../assets/img/LocationInterface.webp";
import {useCompanyDetail} from "../../editing/Company/companyReducer/hooks";
import {Vector} from "../../../../assets/svg/Vector";
import {navigateToUrl} from "../../../../config/helpers";

type IText = {
  text: string;
};

const Location = () => {
  const {
    company: {locations},
    isLoading,
  } = useCompanyDetail();

  const TextSpace = ({text}: IText) => {
    return (
      <Text
        maxW="90%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        color="#C8C8C8"
      >
        {text}
      </Text>
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box mt="48px">
      {locations.map((el) => (
        <Box
          key={el.id}
          mt="15px"
          p="1rem"
          position="relative"
          w="100%"
          fontFamily="Poppins"
          fontWeight="300"
          fontSize="12px"
          lineHeight="20px"
          color="#C8C8C8"
          minH="8rem"
          bg={`url(${LocationImage})`}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgPosition="center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius={8}
          overflow="hidden"
          cursor="pointer"
          _before={{
            content: '""',
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            height: "3rem",
            zIndex: "1",
            background:
              "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }}
          onClick={() => {
            if (el.map_link) {
              navigateToUrl(el.map_link);
            }
          }}
        >
          <Box zIndex="2" w="70%" pl="8px">
            <TextSpace text={el.tower_name}/>
            <TextSpace text={el.city_country}/>
            <TextSpace text={el.street}/>
          </Box>
          <Box
            w="30%"
            fontWeight="700"
            fontSize="15px"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            color="#C5C5C5"
            textTransform="uppercase"
            letterSpacing="10%"
            lineHeight="27.5px"
            zIndex="2"
          >
            <Vector/>
            <TextSpace text={el.main_branch}/>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Location;
