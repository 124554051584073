import {IContactButton, IMenuButtonServer} from "../../../../../../models/profile";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPayloadBtn} from "../../../../../../components/custom-buttons/CustomReducer/menuButton.slice";
import {allButtonData} from "../../../../../../components/custom-buttons/CustomReducer/data";

interface CompanyButtonsState {
  isError: string | null;
  isLoading: boolean;
  buttons: IContactButton[];
}

const initialState: CompanyButtonsState = {
  buttons: [],
  isError: null,
  isLoading: false
}

export const buttonsSlice = createSlice({
  name: 'buttons/company',
  initialState,
  reducers: {
    addButton(state){
      state.isLoading = true;
    },
    addedButton(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
    removeButtonLocal(state, {payload}: PayloadAction<number>){
      state.buttons = state.buttons.filter(el => el.id !== payload)
    },
    resetButtons(state){
      state.buttons = []
    },
    getSuccessButton(state, {payload}: PayloadAction<IPayloadBtn>){
      state.isLoading = false;
      state.buttons.push({
        ...payload.button,
        id: payload.data.id,
        value: JSON.parse(JSON.stringify([payload.data.value]))
      })
    },
    getButtonsCompany(state, {payload}: PayloadAction<IMenuButtonServer[]>) {
      state.buttons = []
      for (let i = 0; i < allButtonData.length; i++) {
        payload?.forEach((el,idx) => {
          if (el.type === allButtonData[i].type_id) {
            state.buttons.push({
              ...allButtonData[i],
              id: el.id,
              value: JSON.parse(JSON.stringify([el.value]))
            })
          }
        })
      }
    },


  }
})

export const ButtonsReducer = buttonsSlice.reducer