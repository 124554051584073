import { StatisticType } from "../../../redux/statistics/interfaces";

export const tabList: StatisticType[] = ["day", "month", "year"];

export const englishMonths = Array.from({ length: 12 }, (_, i) => {
  const date = new Date();
  date.setMonth(i);
  return date.toLocaleString("en-US", { month: "long" });
});

export function isToday(someDate: Date): boolean {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}
