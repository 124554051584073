import { Box, Text } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";

interface IProps {
  children: ReactNode;
  text?: string | number;
  title: string | number;
}

class RectangleQuantity extends Component<IProps> {
  render(): ReactNode {
    const { children, text, title } = this.props;
    return (
      <Box
        bg="#5776B51A"
        display="flex"
        justifyContent="center"
        borderRadius="20px"
        my="1.5rem"
        py="30px"
        position="relative"
      >
        {children}
        <Box position="absolute" top="15px" left="5%" color="#A2D6FC">
          <Text fontWeight="600" fontSize="20px" mb="10px">
            {title}
          </Text>
          <Text fontWeight="400" fontSize="14px">
            {text}
          </Text>
        </Box>
      </Box>
    );
  }
}

export default RectangleQuantity;
