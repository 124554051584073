import React, { useEffect, useState } from "react";
import {
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import QRCode from "qrcode";

//local
import QrCodeSvg from "../../../../assets/svg/QrCodeSvg";
import { useAppSelector } from "../../../../redux/store";
import MenuBtn from "../MenuBtn";
import QrCodeImage from "./QrCodeImage";

const MyQrCode = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [qrCode, setQrCode] = useState<string>("");
  const [qrText, setQrText] = useState<string>("");

  const { user } = useAppSelector((state) => state.ProfileReducer);

  const generateQrCode = async (text: string) => {
    try {
      const url = await QRCode.toDataURL(text, {
        width: 700,
      });
      setQrCode(url);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    qrText.length > 0 && generateQrCode(qrText);
  }, [qrText]);

  useEffect(() => {
    setQrText(`${window.location.origin}/user/${user.card_id}`);
  }, [user.card_id]);

  return (
    <div>
      <MenuBtn icon={<QrCodeSvg />} name={"Qr"} handleClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay bg={"#000000CC"} />
        <ModalContent
          bg="transparent"
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="243px"
          h="243px"
        >
          <QrCodeImage value={qrText} />
          <br />
          <Link
            color="white"
            fontFamily="Poppins"
            fontWeight="300"
            fontSize="15px"
            href={qrCode}
            download={`${qrText}.png`}
          >
            Download to Gallery
          </Link>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MyQrCode;
