import { Box, Button, Image, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import InterfacePhoto from "../../../../assets/img/interfacePhoto.webp";
import Line1 from "../../../../assets/img/Lines1.webp";
import { SectionComponent } from "../../../../components/ui";
import { INTERFACE_LINK } from "../../../../config/admin";

const Solution: FC = () => {
  return (
    <SectionComponent>
      <Image
        src={Line1}
        alt={Line1}
        position="absolute"
        right="0"
        top="0"
        bottom="0"
        height={{ lg: "80%", md: "70%", sm: "60%", base: "50%" }}
      />
      <Box
        my={{ base: "2rem", md: "4rem", xl: "6rem" }}
        display="flex"
        justifyContent="space-between"
        borderRadius={{ md: "20px", base: "10px" }}
        flexWrap="wrap"
        position="relative"
        overflow="hidden"
      >
        <Box
          display={{ lg: "block", base: "none" }}
          className="background__gradient"
        ></Box>
        <Box
          maxW="620px"
          borderRadius={{ base: "20px", md: "10px" }}
          color="rgba(255, 255, 255, 1)"
          overflow="hidden"
          zIndex="2"
          display="flex"
          alignItems="center"
          mx="auto"
          position="relative"
        >
          <Box
            display={{ lg: "none", base: "block" }}
            className="background__gradient"
          ></Box>
          <Box
            fontFamily="Poppins"
            display="flex"
            flexDir="column"
            alignItems={{ md: "flex-start" }}
            px="1.8rem"
            py="2rem"
            zIndex="2"
          >
            <Text
              fontWeight="500"
              fontSize={{ base: "32px", md: "47px", xl: "56px" }}
              lineHeight={{ base: "42px", md: "47px", xl: "69px" }}
              w="100%"
            >
              Unique software <br /> solution
            </Text>
            <Text
              fontWeight="300"
              fontSize="15px"
              letterSpacing="10%"
              mt="50px"
              w="100%"
            >
              This unique software solution is our intellectual property,
              designed to offer unparalleled functionality and seamless
              integration with NFC technology. Our software opens up a world of
              possibilities, enabling seamless connectivity, personalized
              interactions, and unmatched convenience.
            </Text>
            <Text
              fontWeight="300"
              fontSize="15px"
              letterSpacing="10%"
              opacity="60%"
              mt="20px"
              mb="50px"
              w="100%"
            >
              Our software opens up a world of possibilities, enabling seamless
              connectivity, personalized interactions, and unmatched
              convenience.
            </Text>
            <Button
              borderRadius="57px"
              fontSize="20px"
              fontWeight="600"
              color="rgba(36, 93, 206, 1)"
              p={{ base: "1.5rem", md: "2.5rem 2rem" }}
              maxW={{ base: "100%", md: "300px" }}
              mx="auton"
              onClick={() => window.open(INTERFACE_LINK)}
              transition=".4s"
              _hover={{
                boxShadow: "0 0 10px #0b98ff",
                background: "rgba(36, 93, 206, 1)",
                color: "rgba(255, 255, 255, 1)",
              }}
            >
              Go to Interface
            </Button>
          </Box>
        </Box>
        <Box
          bg="rgba(36, 93, 206, 1)"
          borderRadius={{ base: "10px", md: "38px" }}
          overflow="hidden"
          maxW="320px"
          mx="auto"
          zIndex={2}
          my="2rem"
          border="2px solid rgba(60, 60, 60, 1)"
        >
          <Image w="100%" src={InterfacePhoto} alt={InterfacePhoto} />
        </Box>
      </Box>
    </SectionComponent>
  );
};

export default Solution;
