import React from 'react';

const LockAdminSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 11.9167V10.8333C9.75 9.035 10.2917 7.58333 13 7.58333C15.7083 7.58333 16.25 9.035 16.25 10.8333V11.9167" stroke="#4F8BFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 15.8167C13.359 15.8167 13.65 15.5257 13.65 15.1667C13.65 14.8077 13.359 14.5167 13 14.5167C12.641 14.5167 12.35 14.8077 12.35 15.1667C12.35 15.5257 12.641 15.8167 13 15.8167Z" stroke="#4F8BFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.7083 18.4167H10.2917C8.12499 18.4167 7.58333 17.875 7.58333 15.7083V14.625C7.58333 12.4583 8.12499 11.9167 10.2917 11.9167H15.7083C17.875 11.9167 18.4167 12.4583 18.4167 14.625V15.7083C18.4167 17.875 17.875 18.4167 15.7083 18.4167Z" stroke="#4F8BFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 23.8333C18.9831 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01691 18.9831 2.16667 13 2.16667C7.01692 2.16667 2.16667 7.01691 2.16667 13C2.16667 18.9831 7.01692 23.8333 13 23.8333Z" stroke="#4F8BFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default LockAdminSvg;