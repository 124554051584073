import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

//local
import { ProfileReducer } from "../pages/interface/editing/Profile/ProfileReducer/profileSlice";
import { PostProfileReducer } from "../pages/interface/editing/Profile/ProfileReducer/postProfile.slice";
import { FontReducer } from "../pages/interface/editing/FontReducer/fonts-slice";
import { UploadFileReducer } from "../components/Cropper/UploadFile-Reducer/upload.slice";
import { AddCustomReducer } from "../components/custom-buttons/CustomReducer/customSlice";
import { MenuButtonsReducer } from "../components/custom-buttons/CustomReducer/menuButton.slice";
import { CompanyReducers } from "../pages/interface/editing/Company/companyReducer";
import { CompanyDetailReducer } from "../pages/interface/editing/Company/companyReducer/companyDetailSlice";
import { MemberReducer } from "../pages/interface/Member/MemberReducer/member.slice";
import { MemberDetailReducer } from "../pages/interface/Member/MemberReducer/memberDetail.slice";
import { LeadsReducer } from "./leads/leadsSlice";
import { StatisticsReducer } from "./statistics/statisticsSlice";
import loginFormInterfaceModal from "./loginFormInterface/reducer";
import { ShopReducers } from "../pages/store/reducers";
import { MenuBtnAnimateReducer } from "./menuBtnAnimation/menuBtnAnimationSlice";
import { shopApi } from "./shopApi";
import {CorpReducer} from "../pages/admin/CompanyDashboard/reducer/slice";

// корневой редюсер
const rootReducer = combineReducers({
  ...CompanyReducers,
  ...ShopReducers,
  loginFormInterfaceModal,
  MenuBtnAnimateReducer,
  ProfileReducer,
  PostProfileReducer,
  FontReducer,
  UploadFileReducer,
  AddCustomReducer,
  MenuButtonsReducer,
  CompanyDetailReducer,
  MemberReducer,
  MemberDetailReducer,
  LeadsReducer,
  StatisticsReducer,
  CorpReducer,
});

// функция, которая создает и возвращает Redux store
export const store = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(shopApi.middleware),
  });
};

type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore["dispatch"];

// хук, который позволяет использовать функцию диспетчера в компонентах
export const useAppDispatch = () => useDispatch<AppDispatch>();

// хук, который позволяет выбирать и использовать состояние из Redux store
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
