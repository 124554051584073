import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

//local
import CallbackForm from "./CallbackForm";
import CallBackWhatsapp from "./CallBackWhatsapp";

const CallbackInComponent: FC = () => {
  return (
    <Box
      bg="#303030"
      borderRadius={{ md: "20px", base: "10px" }}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      flexDirection={{ md: "row", base: "column" }}
      fontFamily="Poppins"
      p="3rem"
      px={{ xl: "3rem", md: "2rem", base: "13px" }}
    >
      <CallBackWhatsapp maxWComponent="425px" maxWBtn="432px" />
      <Box
        maxW={{ md: "401px", base: "100%" }}
        mt={{ md: "auto", base: "55px" }}
        pl={{ md: "20px", base: "0" }}
      >
        <CallbackForm />
      </Box>
    </Box>
  );
};

export default CallbackInComponent;
