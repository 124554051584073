export const NotLeadsSvg = () => {
  return (
    <svg
      width="115"
      height="114"
      viewBox="0 0 115 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.5 9.5C45.055 9.5 34.9375 19.6175 34.9375 32.0625C34.9375 44.27 44.485 54.15 56.93 54.5775C57.31 54.53 57.69 54.53 57.975 54.5775C58.07 54.5775 58.1175 54.5775 58.2125 54.5775C58.26 54.5775 58.26 54.5775 58.3075 54.5775C70.4675 54.15 80.015 44.27 80.0625 32.0625C80.0625 19.6175 69.945 9.5 57.5 9.5Z"
        fill="#292D32"
      />
      <path
        d="M81.6305 67.2127C68.378 58.3777 46.7655 58.3777 33.418 67.2127C27.3855 71.2502 24.0605 76.7127 24.0605 82.5552C24.0605 88.3977 27.3855 93.8127 33.3705 97.8027C40.0205 102.268 48.7605 104.5 57.5005 104.5C66.2405 104.5 74.9805 102.268 81.6305 97.8027C87.6155 93.7652 90.9406 88.3502 90.9406 82.4602C90.8931 76.6177 87.6155 71.2027 81.6305 67.2127ZM66.7155 86.7352C68.093 88.1127 68.093 90.3927 66.7155 91.7702C66.003 92.4827 65.1005 92.8152 64.198 92.8152C63.2955 92.8152 62.393 92.4827 61.6805 91.7702L57.5005 87.5902L53.3205 91.7702C52.608 92.4827 51.7055 92.8152 50.803 92.8152C49.9005 92.8152 48.998 92.4827 48.2855 91.7702C46.908 90.3927 46.908 88.1127 48.2855 86.7352L52.4655 82.5552L48.2855 78.3752C46.908 76.9977 46.908 74.7177 48.2855 73.3402C49.663 71.9627 51.943 71.9627 53.3205 73.3402L57.5005 77.5202L61.6805 73.3402C63.058 71.9627 65.338 71.9627 66.7155 73.3402C68.093 74.7177 68.093 76.9977 66.7155 78.3752L62.5355 82.5552L66.7155 86.7352Z"
        fill="#292D32"
      />
    </svg>
  );
};
