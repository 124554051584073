import React from 'react';

const MessengersSvg = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.9699 17.115C32.4599 8.41505 24.5549 1.71006 15.4499 3.21006C9.17994 4.24506 4.15495 9.33003 3.17995 15.6C2.60995 19.23 3.35998 22.665 4.99498 25.5L3.65996 30.465C3.35996 31.59 4.39493 32.61 5.50493 32.295L10.3949 30.945C12.6149 32.25 15.2099 33 17.9849 33C26.4449 33 33.4649 25.545 32.9699 17.115ZM25.32 23.58C25.185 23.85 25.0199 24.1051 24.8099 24.3451C24.4349 24.7501 24.0299 25.0501 23.5799 25.2301C23.1299 25.4251 22.6349 25.5151 22.1099 25.5151C21.3449 25.5151 20.5199 25.3351 19.6649 24.9601C18.7949 24.5851 17.94 24.09 17.085 23.475C16.215 22.845 15.405 22.14 14.625 21.375C13.845 20.595 13.1549 19.77 12.5249 18.915C11.9099 18.06 11.415 17.205 11.055 16.35C10.695 15.495 10.515 14.6701 10.515 13.8901C10.515 13.3801 10.605 12.8851 10.785 12.4351C10.965 11.9701 11.25 11.5501 11.655 11.1751C12.135 10.6951 12.66 10.4701 13.215 10.4701C13.425 10.4701 13.6349 10.5151 13.8299 10.6051C14.0249 10.6951 14.205 10.8301 14.34 11.0251L16.0799 13.485C16.2149 13.68 16.3199 13.845 16.3799 14.01C16.4549 14.175 16.4849 14.325 16.4849 14.475C16.4849 14.655 16.425 14.8351 16.32 15.0151C16.215 15.1951 16.0799 15.375 15.8999 15.555L15.3299 16.155C15.2399 16.245 15.21 16.335 15.21 16.455C15.21 16.515 15.2249 16.575 15.2399 16.635C15.2699 16.695 15.285 16.74 15.3 16.785C15.435 17.04 15.6749 17.355 16.0049 17.745C16.3499 18.135 16.71 18.54 17.1 18.93C17.505 19.335 17.895 19.695 18.3 20.04C18.69 20.37 19.0199 20.5951 19.2749 20.7301C19.3199 20.7451 19.365 20.775 19.41 20.79C19.47 20.82 19.53 20.8201 19.605 20.8201C19.74 20.8201 19.83 20.7751 19.92 20.6851L20.4899 20.115C20.6849 19.92 20.865 19.7851 21.03 19.6951C21.21 19.5901 21.375 19.53 21.57 19.53C21.72 19.53 21.87 19.56 22.035 19.635C22.2 19.71 22.3799 19.8001 22.5599 19.9351L25.05 21.705C25.245 21.84 25.3799 22.0051 25.4699 22.1851C25.5449 22.3801 25.59 22.56 25.59 22.77C25.5 23.025 25.44 23.31 25.32 23.58Z"
            fill="#0080DD"/>
    </svg>
  );
};

export default MessengersSvg;