import React, {useState} from 'react';
import InputStore from "../components/ui/InputStore";
import {Box, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import SubmitButton from "../components/ui/SubmitButton";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {IOrderData, sendOrders} from "./OrderReducer/actions";

export type PaymentMethodType = "ONLINE" | "CASH"

const methods: PaymentMethodType[] = ["CASH", 'ONLINE']

const CheckoutForm = () => {

  const dispatch = useAppDispatch()
  const {cart} = useAppSelector(state => state.CartShopReducer)
  const {isLoading} = useAppSelector(state => state.OrderShopReducer)

  const [value, setValue] = useState<IOrderData>({
    full_name: "",
    phone_number: "",
    email: "",
    payment_method: "CASH",
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'payment_method') {
      setValue({...value, [e.target.name]: methods[+e.target.value - 1]})
    } else {
      setValue({...value, [e.target.name]: e.target.value})
    }
  }

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(sendOrders(value, cart))
  }

  return (
    <form onSubmit={onSubmit}>
      <InputStore
        placeholder="Name"
        value={value.full_name}
        handleChange={handleChange}
        name={"full_name"}
      />
      <InputStore
        placeholder="Phone number"
        value={value.phone_number}
        handleChange={handleChange}
        name={"phone_number"}
        type={"tel"}
      />
      <InputStore
        placeholder="Email"
        value={value.email}
        handleChange={handleChange}
        name={"email"}
        isRequired={false}
        type={"email"}
      />
      <Text pl="10px" fontSize="14px" color="rgba(255, 255, 255, 1)">
        Payment Method
      </Text>
      <RadioGroup defaultValue="1" my="10px" mb='25px'>
        <Stack spacing='13px' direction="row">
          {methods.map((type, idx) => (
            <Box
              key={idx}
              bg="linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))"
              borderRadius="15px"
              w='100%'
            >
              <Radio
                onChange={handleChange}
                p="1rem" w='100%'
                colorScheme="blue"
                name={'payment_method'}
                value={`${idx + 1}`}
                isDisabled={type === "ONLINE"}
              >{type[0] + type.slice(1).toLowerCase()}</Radio>
            </Box>
          ))}
        </Stack>
      </RadioGroup>
      <SubmitButton
        isDisabled={!cart.length}
        isLoading={isLoading}
        type='submit'>Send</SubmitButton>
    </form>
  );
};

export default CheckoutForm;