export const Vector = () => {
  return (
    <svg
      width="15"
      height="23"
      viewBox="0 0 15 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3357 22.1772C7.1536 21.9344 6.9158 21.6117 6.63884 21.2229C5.98215 20.3012 5.10711 19.0105 4.23283 17.5342C3.35782 16.0568 2.48842 14.4016 1.83893 12.75C1.18731 11.093 0.769531 9.47051 0.769531 8.04997C0.769531 3.81239 3.7645 0.499971 7.3357 0.499971C10.9069 0.499971 13.9019 3.81239 13.9019 8.04997C13.9019 9.47051 13.4841 11.093 12.8325 12.75C12.183 14.4016 11.3136 16.0568 10.4386 17.5342C9.56429 19.0105 8.68925 20.3012 8.03256 21.2229C7.7556 21.6117 7.5178 21.9344 7.3357 22.1772ZM4.31207 8.04997C4.31207 9.84989 5.60727 11.425 7.3357 11.425C9.06413 11.425 10.3593 9.84989 10.3593 8.04997C10.3593 6.25005 9.06413 4.67497 7.3357 4.67497C5.60727 4.67497 4.31207 6.25005 4.31207 8.04997Z"
        stroke="url(#paint0_linear_565_3085)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_565_3085"
          x1="7.3357"
          y1="0"
          x2="7.3357"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.583333" stopColor="#C8C8C8" />
          <stop offset="1" stopColor="#C8C8C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
