import {useState} from "react";


export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const result = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  return `${result} ${sizes[i]}`
}


export function useFileDecoder() {
  const [files, setFiles] = useState<File[]>([])
  const [file, setFile] = useState<any>('')
  const [binaryData, setBinary] = useState<any>('')
  const fileReader: FileReader = new FileReader()


  const handleChange = (fileArr: File[]) => {
    if (!fileArr.length) {
      return;
    }
    setFiles(Array.from(fileArr))
    const fileObj = fileArr[0];
    if (!fileObj) {
      return;
    }

    const dateModified = new Date(fileObj.lastModified).toISOString();
    const {name, size, type} = fileObj;

    const data = {
      dateModified,
      name,
      size,
      type,
    }


    fileReader.onload = ev => {
      const src = ev.target?.result

      setBinary(fileObj)
      setFile(src)


    }

    fileReader.readAsDataURL(fileObj)
  }

  const removeImage = (index: number) => {
    setFiles(files.filter((_, idx) => idx !== index))
  }

  return [handleChange, file, binaryData, files, removeImage]
}


export function urlToFile(url: any) {
  if (!url) {
    return;
  }
  var arr = url.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    data = arr[1],

    dataStr = atob(data),
    n = dataStr.length,
    dataArr = new Uint8Array(n)

  while (n--) {
    dataArr[n] = dataStr.charCodeAt(n)
  }
  return new File([dataArr], `${Math.floor(Math.random() * 100000)}.png`, {type: mime})

}