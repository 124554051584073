import { Box, Skeleton, SkeletonText, Stack } from "@chakra-ui/react";
import React, { FC } from "react";

const SkeletonLead: FC = () => {
  return (
    <Stack p="1rem" bg="#FFFFFF1A" borderRadius="11px" mx="0.8rem" mb="10px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Skeleton w="60%" minH="25px" borderRadius={4} />
        <Box w="40%" display="flex" justifyContent="flex-end">
          <Skeleton w="35px" h="35px" borderRadius={2} mr="10px" />
          <Skeleton w="35px" h="35px" borderRadius={2} />
        </Box>
      </Box>
      <SkeletonText mt="1" noOfLines={2} spacing="1" skeletonHeight="2" />
      <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="2" />
      <Box display="flex" justifyContent="flex-end">
        <SkeletonText
          w="20%"
          mt="2"
          noOfLines={1}
          spacing="4"
          skeletonHeight="2"
        />
      </Box>
    </Stack>
  );
};

export default SkeletonLead;
