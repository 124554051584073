import { Box, Flex } from "@chakra-ui/react";
import React, { FC, useState } from "react";

//local
import { useAppDispatch } from "../../../../redux/store";
import { CategoryShopCardSkeleton } from "../skeletons";
import { ICategoryShop } from "./CategoriesShopReducer/interfaces";
import { filterProducts } from "./ProductsReducer/slice";

interface IProps {
  categories: ICategoryShop[];
  isLoading: boolean;
}

const ProductTabList: FC<IProps> = ({ categories, isLoading }) => {
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<number | null>(null);

  const handleClick = (category: ICategoryShop) => {
    dispatch(filterProducts(category));
    setActive(category.id);
  };

  if (!!isLoading) {
    return <CategoryShopCardSkeleton />;
  }

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
      {categories.map((el) => (
        <Flex
          onClick={() => handleClick(el)}
          key={el.id}
          justifyContent="center"
          alignItems="center"
          bg={active === el.id ? "#3F3F3F" : "rgba(255, 255, 255, 0.05)"}
          textTransform="uppercase"
          color={active === el.id ? "#ffffff" : "rgba(255, 255, 255, 1)"}
          border={{ md: "1px solid rgba(255, 255, 255, 1)" }}
          borderRadius={{ md: "25px", base: "6px" }}
          w={{ lg: "24.5%", md: "33%", sm: "49.5%", base: "49%" }}
          px={{ md: "20px", base: "5px" }}
          py={{ md: "20px", base: "43px" }}
          mb={{ sm: "5px", base: "6px" }}
          fontSize={{ md: "14px", base: "13px" }}
          fontWeight={{ md: "500", base: "700" }}
          letterSpacing={{ md: "1.5px", base: "1px" }}
          h={{ md: "60px", base: "40px" }}
          cursor="pointer"
          textAlign="center"
          flexWrap="nowrap"
          _hover={{
            color: "#2B90EF",
          }}
        >
          {el.title}
        </Flex>
      ))}
    </Box>
  );
};

export default ProductTabList;
