export const TikTotIconSvg = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2244 6.3224C19.7266 6.32236 18.2901 5.72733 17.2311 4.6682C16.172 3.60907 15.577 2.17261 15.577 0.674805H11.5422V16.4745C11.5414 17.1948 11.314 17.8967 10.8921 18.4805C10.4701 19.0644 9.87517 19.5006 9.19147 19.7275C8.50777 19.9543 7.77002 19.9602 7.08279 19.7443C6.39555 19.5284 5.7937 19.1017 5.36252 18.5246C4.93135 17.9476 4.69273 17.2495 4.68048 16.5292C4.66824 15.809 4.88299 15.1032 5.29429 14.5118C5.7056 13.9204 6.29261 13.4735 6.9721 13.2344C7.6516 12.9953 8.38913 12.9761 9.08014 13.1795V9.2573C7.61161 9.05928 6.11761 9.31398 4.79765 9.98741C3.47768 10.6608 2.39457 11.7209 1.69296 13.0261C0.991337 14.3313 0.704601 15.8195 0.871036 17.2919C1.03747 18.7644 1.64915 20.151 2.62441 21.2667C3.59966 22.3823 4.89207 23.1739 6.32905 23.5357C7.76603 23.8975 9.27918 23.8123 10.6665 23.2915C12.0537 22.7707 13.2491 21.839 14.093 20.6209C14.9368 19.4029 15.389 17.9563 15.3891 16.4745L15.5068 8.48826C17.1646 9.70525 19.1682 10.3601 21.2248 10.357L21.2244 6.3224Z"
        fill="white"
      />
    </svg>
  );
};
