import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//local dependencies
import "./style/index.scss";
import { store } from "./redux/store";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
      <Router>
        <Provider store={store()}>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </Provider>
      </Router>
  </React.StrictMode>
);
