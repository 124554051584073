import toast from "react-hot-toast";
import {removeLocalStorage, toLocalStorage} from "../../../config/helpers";
import {toastCreator} from "../../../config/toast";
import {PUBLIC_API} from "../../../api";


export interface IValueLogin {
  email: string;
  password: string;
}

interface ILoginData {
  access: string
  refresh: string
  slug: string
}

export const onLogin =
  async (value: IValueLogin, setLoading: (b: boolean) => void, navigate: any) => {
    try {
      setLoading(true)
      const {data, status} = await PUBLIC_API.post<ILoginData>('api/v1/corp/auth/login/', value)
      sessionStorage.setItem("accessToken", data.access);
      sessionStorage.setItem("refreshToken", data.refresh);
      toLocalStorage("slug", data.slug);
      setLoading(false)
      if (status) {
        navigate('/admin')
        toast.success("You are successfully logged in")
      }
    } catch (e: any) {
      setLoading(false)
      toast.error(e.response?.data?.message || "An error has occurred")
    }
  }

export const onLogoutAdmin = () => {
  removeLocalStorage('slug')
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('refreshToken')
  window.location.reload()
}

export const onSendEmail =
  async (email: string, setLoading: (b: boolean) => void, navigate: any) => {
    try {
      setLoading(true)
      const res = await PUBLIC_API.post('api/v1/corp/auth/reset-password/', {email})
      setLoading(false)
      if (res.status) {
        toast(res.data.detail, {duration: 5000, style: {textAlign: 'center'}, icon: '📩'})
        navigate('/admin/create-password')
      }
    } catch (e: any) {
      setLoading(false)
      toast.error(e.response?.data?.message || "An error has occurred")
    }
  }

export interface ICreatePassword {
  key: string;
  new_password1: string;
  new_password2: string;
}

export const onCreatePassword =
  async ({new_password1, new_password2, key}: ICreatePassword, setLoading: (b: boolean) => void, onClose: any) => {
    try {
      setLoading(true)
      const res = await PUBLIC_API.post(`api/v1/corp/auth/reset-password-confirm/${key}/`, {
        new_password1,
        new_password2,
      })
      setLoading(false)
      if (res.status) {
        onClose()
        toastCreator("success", res.data || "You have successfully changed your password")
      }
    } catch (e: any) {
      setLoading(false)
      toast.error(e.response?.data?.message || "An error has occurred")
    }
  }