import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/react";

//local
import { IVideos } from "../../../../models/company";
import { getYouTubeVideoId } from "../../../../config/helpers";

type IProps = {
  video: IVideos;
};

const YoutubeVideo: FC<IProps> = ({ video }) => {
  return (
    <Box pb="0.8rem">
      <iframe
        className="responsive-iframe"
        src={`https://www.youtube.com/embed/${getYouTubeVideoId(video.url)}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <Text
        mt="15px"
        fontWeight={400}
        fontSize={16}
        lineHeight="24px"
        textAlign="center"
      >
        {video.title}
      </Text>
      <Text
        mt="5px"
        fontWeight={275}
        fontSize={15}
        lineHeight="22.5px"
        textAlign="center"
        color="#C8C8C8"
      >
        {video.description}
      </Text>
    </Box>
  );
};

export default YoutubeVideo;
