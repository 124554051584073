import React, {useEffect, useState} from 'react';
import {IInputProps} from "./EditInput";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text
} from "@chakra-ui/react";
import {ErrorText, FileInput, PlacementInput} from "../index";
import {AiOutlinePlus} from "react-icons/ai";
import {HiMinusSm} from "react-icons/hi";
import {useFileDecoder} from "../../../config/hooks/file-decoder";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {uploadFiles} from "../../Cropper/UploadFile-Reducer/actions";
import Loader from "../../Loader";
import {useLocation} from 'react-router-dom';

interface Props extends IInputProps {
  handler: (index: number, value: string) => void
  leftIcon?: JSX.Element;
  isLast: boolean;
  index: number;
  addInput: () => void
  removeInput: (index: number) => void;
}

const CustomInput = (
  {
    name, placeholder, isLast, value, handler, index,
    removeInput, addInput, leftIcon, error, type = "text"
  }: Props) => {

  const [handleFileChange, src, file] = useFileDecoder()
  const {isLoading} = useAppSelector(state => state.UploadFileReducer)
  const dispatch = useAppDispatch()
  const [text, setText] = useState<string>(value || "")

  const {pathname} = useLocation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value.trim())
  }

  const handleDocument = (e: React.ChangeEvent<any>) => {
    const fileList = e.target.files
    if (fileList) {
      handleFileChange(fileList)
      dispatch(uploadFiles(fileList[0], setText, () => {}))
    }
  }

  useEffect(() => {
    handler(index, text)
  }, [text])

  console.log(isLoading)
  return (
    <Box mb='18px' w='100%'>
      {isLoading && <Loader/>}
      <InputGroup>
        <InputLeftElement width='4rem' display='flex' justifyContent='end' alignItems='center' h='full'>
          <Box
            color='#C8C8C8' fontSize='1.6rem' pr={4} cursor='pointer' borderRight='1px solid #A8A8A8'
          >{leftIcon}</Box>
        </InputLeftElement>
        {type !== 'file' &&
          <Input
            onChange={handleChange}
            value={text}
            name={name + (index + 1)}
            type={type}
            placeholder={placeholder}
            h='56px' w='100%'
            rounded='39px' pl={'4.5rem'} pr={'45px'}
            border='none' bg={'#75757540'}
            fontSize={'16px'}
            letterSpacing='0.5px'
            fontWeight='400' fontFamily={"Poppins"}
            lineHeight='24px' color={'#FFFFFF'}
            _placeholder={{
              color: '#787878',
              fontSize: '15px',
            }}
            isRequired={true}
          />}
        {type === 'file' &&
          <Stack as='label' position='relative' display='inline-block' w='100%' h='56px'>
            <FileInput handleChange={handleDocument} accept={['.pdf', '.doc', '.docx', '.xlxs']} multi={false}/>
            <Text
              as='span'
              position='relative'
              display='inline-block'
              cursor='pointer'
              outline='none'
              h='56px' w='100%' py={4}
              rounded='39px' pr={16} pl={20}
              border='none' bg={'#75757540'}
              fontSize={'16px'}
              letterSpacing='0.5px'
              fontWeight='400' fontFamily={"Poppins"}
              lineHeight='24px' color={'#FFFFFF'}
            >{file ? <Text noOfLines={1}>{file?.name}</Text> : <Text opacity='.5'>{placeholder}</Text>}</Text>
          </Stack>}
        <InputRightElement
          h='full' display={pathname === "/profile" ? 'none' : 'flex'}
          justifyContent='start' alignItems='center' w='45px'>
          {isLast && <Box
            onClick={addInput}
            color='#0080DD' fontSize='2rem'
            _active={{opacity: '0.4'}}
            opacity={text.trim() ? '1' : '0.4'}
            cursor='pointer'
          ><AiOutlinePlus/>
          </Box>}
          {!isLast && <Flex
            onClick={() => removeInput(index)}
            justifyContent='center' alignItems='center'
            color='#FFFFFF' bg='#DE1C1C' w='27px' h='27px'
            rounded='50%' fontSize='1.7rem'
            _active={{opacity: 0.7}} cursor='pointer'
          ><HiMinusSm/>
          </Flex>}
        </InputRightElement>
      </InputGroup>
      <Box px={2}><ErrorText>{error}</ErrorText></Box>
    </Box>
  );
};

export default CustomInput;