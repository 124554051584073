import React, {useEffect, useState} from 'react';
import {Avatar, Box, Divider, Flex, Link, Text} from "@chakra-ui/react";
import {MdFileCopy} from "react-icons/md";
import copy from "copy-to-clipboard";
import {IEmployees} from "../reducer/types";
import toast from "react-hot-toast";
import EditModal from "./EditModal";
import {useAppSelector} from "../../../../redux/store";
import {allocateText, useEncryption} from "../../../../config/helpers";
import LeadsModal from "./LeadsModal";

interface Props {
  employee: IEmployees
}

const UserCard = ({employee}: Props) => {
  const {corp} = useAppSelector(state => state.CorpReducer)
  const {search_text} = useAppSelector(state => state.CorpReducer)
  const handleOnCopy = () => {
    copy(`https://nfc-bc.com/user/${employee.card_id}`)
    toast.success('Card ID copied')
  };
  const [name, font] = useEncryption(employee.full_name)

  const [textArray, setArray] = useState<string[]>([name || "Username"])
  useEffect(() => {
    setArray(allocateText(name, search_text))
  }, [search_text])
  return (
    <Flex justifyContent='space-between' h='81px' w='100%' mb='10px'>
      <Flex
        justifyContent='space-between' alignItems='center'
        maxW='70%' w='100%' h='100%'
        py='13.2px' pl='17.2' pr='130px'
        bg='#FFFFFF1A' rounded='14px' mr='14px'
      >
        <Flex>
          {!employee.avatar && <Avatar w='54.6px' h='54.6px' src={"https://bit.ly/broken-link"}/>}
          {!!employee.avatar && <Avatar w='54.6px' h='54.6px' objectFit='cover' src={employee.avatar}/>}
          <Flex py='5px' flexDirection='column' justifyContent='center' fontFamily='Rubik' fontWeight='400'
                color='#FFFFFF7F' ml='16px'>
            <Flex fontSize='20px'>
              {!name && "Username"}
              {textArray.map(text => (
                <Text key={text} w='auto' bg={text === search_text ? '#b49a03' : 'transparent'} rounded='4px'
                >{text}</Text>
              ))}
            </Flex>
            <Text fontSize='12px' mt={1}>{employee.designation || "Designation"}</Text>
          </Flex>
        </Flex>
        <Link
          href={`mailto:${corp.email}`}
          color='#0080DD'
          fontFamily='Rubik'
          fontWeight='400'
          fontSize='16px'
        >{corp.email}</Link>
        <Flex alignItems='start'>
          <EditModal employee={employee}/>
          <Divider h='41.5px' w='1px' bg='#454545' orientation='vertical' ml='15.5px' mr='11.5px'/>
          <Box
            onClick={handleOnCopy} cursor='pointer' mt={1}
            _active={{opacity: '.8'}} color={'#FFFFFF4C'}
            fontSize='12px' fontFamily='Rubik'>
            <Box fontSize='1.3rem' color='#FEFEFE' mb='4px'><MdFileCopy/></Box>
            Copy
          </Box>
        </Flex>
      </Flex>

      <Flex
        maxW='30%' w='100%' h='100%'
        justifyContent='space-between' alignItems='center'
        py='13.2px' pl='35px' pr='82px'
        bg='#FFFFFF1A' rounded='14px'
      >
        <Text
          color='#6A6A6A'
          fontFamily='Rubik'
          fontWeight='400'
          fontSize='16px'
        >Taps:</Text>
        <Flex alignItems='center' color='#FFFFFF' fontSize='20px' fontWeight='400' fontFamily='Rubik'>
          {employee.total}
          <Divider h='41px' w='1px' bg='#454545' orientation='vertical' ml='2' mr='11.5px'/>
        </Flex>
        <LeadsModal leads={employee.leads}/>
      </Flex>
    </Flex>
  );
};

export default UserCard;