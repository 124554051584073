import React from 'react';
import {Outlet} from "react-router-dom";
import {useActiveAuth} from "../pages/admin/AuthActions/useActiveAuth";
import AdminLogin from "../pages/admin/AdminLogin";
import Admin from "../pages/admin";

const ProtectAdminPage = () => {
  const isAuth = useActiveAuth()
  return isAuth ? <Outlet/> : <Admin><AdminLogin/></Admin>
};

export default ProtectAdminPage;