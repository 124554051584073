import React, {useEffect, useState} from 'react';
import {Box, Center, Flex} from "@chakra-ui/react";
import TitleComponents from "../../../components/ui/text/TitleComponents";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import MemberCard from "./MemberCard";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {getMemberCategories, getMembers} from "./MemberReducer/actions";
import SkeletonMember from "./SkeletonMember";
import {filterMembers} from "./MemberReducer/member.slice";
import {ErrorText} from "../../../components/ui";
import MemberSearch from "./MemberSearch";

const Member = () => {
  const dispatch = useAppDispatch()
  const {members, isLoading, isError, categories} = useAppSelector(state => state.MemberReducer)
  const [isCategory, setCategory] = useState('')

  const getCategory = (category: string) => {
    setCategory(category)
    dispatch(filterMembers(category))
  }

  useEffect(() => {
    dispatch(getMemberCategories())
    dispatch(getMembers())
  }, [])
  return (
    <Box pt='212px' h='100%'>
      <Box position='fixed' zIndex='2' top='0' left='0' right='0'>
        <Box bg='black' maxW={INTERFACE_WIDTH} pb={1} mx='auto'>
          <Center pt={6} pb={4}>
            <TitleComponents>Member</TitleComponents>
          </Center>
          <MemberSearch/>
          <Box overflow="hidden">
            <Box
              display="flex"
              pl='2.5' fontWeight='500'
              overflowX='auto'
              className='unscroll'
              my={5}>
              <Flex
                onClick={() => getCategory('')}
                justifyContent='center'
                alignItems='center'
                mr='2.5' whiteSpace="nowrap"
                px={4} cursor='pointer' h='44px'
                color={!isCategory ? '#FFFFFF' : '#FFFFFF7F'}
                bg={!isCategory ? '#FFFFFF33' : '#FFFFFF1A'}
                border={`1px solid ${!isCategory ? '#737373' : 'transparent'}`}
                rounded='8px' fontFamily='Poppins'
                fontSize='16px' fontWeight='500' letterSpacing='5%'
                lineHeight='24px'
                _active={{bg: '#FFFFFF33', border: '1px solid #737373', color: '#FFFFFF'}}
              >All</Flex>
              {categories.map(el => (
                <Flex
                  key={el.id}
                  onClick={() => getCategory(el.title)}
                  justifyContent='center'
                  alignItems='center'
                  mr='2.5' whiteSpace="nowrap"
                  px={4} cursor='pointer' h='44px'
                  color={isCategory === el.title ? '#FFFFFF' : '#FFFFFF7F'}
                  bg={isCategory === el.title ? '#FFFFFF33' : '#FFFFFF1A'}
                  border={`1px solid ${isCategory === el.title ? '#737373' : 'transparent'}`}
                  rounded='8px' fontFamily='Poppins'
                  fontSize='16px' fontWeight='500' letterSpacing='5%'
                  lineHeight='24px'
                  _active={{bg: '#FFFFFF33', border: '1px solid #737373', color: '#FFFFFF'}}
                >{el.title}</Flex>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box borderTopRadius='15px' bg='#151515' minH='100vh' className='unscroll'>
        <Box borderTopRadius='15px' pt='30px' px='2.5' pb={4}>
          {!isLoading && !members.length &&
            <Center fontFamily='Poppins' fontSize='16px' color='#FFFFFF7F' fontWeight='500'
            >no results
            </Center>}
          {!!isError && <Box w='100%' textAlign='center'><ErrorText>{isError}</ErrorText></Box>}
          {!!isLoading && Array(3).fill(0).map((_, idx) => <SkeletonMember key={idx}/>)}
          {!isLoading && members.map((el, idx) => <MemberCard el={el} idx={idx} key={idx}/>)}
        </Box>
      </Box>
    </Box>
  );
};

export default Member;