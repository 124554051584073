import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "../../../../../models/company";
import {IContactButton} from "../../../../../models/profile";
import {allButtonData} from "../../../../../components/custom-buttons/CustomReducer/data";

interface IStateCompany {
  company: ICompany;
  buttons: IContactButton[]
  isLoading: boolean;
  error: string;
}

const initialState: IStateCompany = {
  company: {
    id: 0,
    name: "",
    slogan: "",
    is_gradient: false,
    description: "",
    preview: null,
    menu_buttons: [],
    images: [],
    locations: [],
    slug:'',
    videos: [],
  },
  buttons: [],
  isLoading: false,
  error: "",
};

export const companyDetailSlice = createSlice({
  name: "get/companyDetail",
  initialState,
  reducers: {
    getCompanyLoading(state) {
      state.isLoading = true;
    },
    getCompanySuccess(state, action: PayloadAction<ICompany>) {
      state.isLoading = false;
      state.company = action.payload;
      state.buttons = [];
      for (let i = 0; i < allButtonData.length; i++) {
        action.payload.menu_buttons?.forEach(el => {
          if (el.type === allButtonData[i].type_id) {
            state.buttons.push({
              ...allButtonData[i],
              name: el.name || allButtonData[i].name,
              id: el.id,
              value: JSON.parse(JSON.stringify([el.value]))
            })
          }
        })    
      }
    },
    getCompanyError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const CompanyDetailReducer = companyDetailSlice.reducer;
