import { CallbackShopReducer } from "./components/Callback/CallbackReducer/slice";
import { ProductsShopReducer } from "./components/Product/ProductsReducer/slice";
import { CartShopReducer } from "./BasketPage/CartReducer/slice";
import { OrderShopReducer } from "./CheckoutPage/OrderReducer/slice";
import { CategoryShopReducer } from "./components/Product/CategoriesShopReducer/categoryShopSlice";
import { shopApi } from "../../redux/shopApi";

export const ShopReducers = {
  CallbackShopReducer,
  ProductsShopReducer,
  CartShopReducer,
  [shopApi.reducerPath]: shopApi.reducer,
  OrderShopReducer,
  CategoryShopReducer,
};
