import { Box, Image, Text } from "@chakra-ui/react";
import React, { Component, RefObject } from "react";
import Slider from "react-slick";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

//local
import SamatPhoto from "../../../../assets/img/SamatAwa.webp";
import Alexssandr from "../../../../assets/img/AlexsandrTian.webp";
import { SectionComponent } from "../../../../components/ui";

class CoFounders extends Component {
  private slider: RefObject<Slider> = React.createRef();
  constructor(props: {}) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    if (this.slider.current) {
      this.slider.current.slickNext();
    }
  }
  previous() {
    if (this.slider.current) {
      this.slider.current.slickPrev();
    }
  }
  render(): React.ReactNode {
    const coFoundList = [
      {
        name: "Samat Shermatov",
        aboutText:
          "Samat Shermatov is the creative and technological mastermind behind our innovative products. As our Creative Head, he is responsible for shaping and executing our most imaginative ideas. Samat's philosophy centers on the belief that life's complexities can be simplified through a deep passion for what we do. He inspires us to see that everything in this life is simple and achievable when approached with love for our craft.",
        photo: SamatPhoto,
      },
      {
        name: "Alexandr Tian",
        aboutText:
          "At the heart of our team's relentless drive and enthusiasm stands Alexandr Tian, our Energy Extraordinaire. Without him, our company wouldn't be the same. Alexandr  boundless energy fuels our company's entire ecosystem, propelling us forward toward new horizons. His inspiring leadership motivates the entire team, and we continuously learn from his wisdom. Alexandr firmly believes in the philosophy that nothing is impossible.",
        photo: Alexssandr,
      },
    ];
    const settings = {
      dots: false,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <SectionComponent>
        <Box
          pt={{ md: "115px", base: "90px" }}
          className="co-founder"
          maxW={{ lg: "1024px", md: "90%", base: "100%" }}
          mx="auto"
        >
          <Text
            textAlign="center"
            fontFamily="Poppins"
            fontSize={{ base: "32px", md: "47px", xl: "56px" }}
            borderRadius={{ base: "10px", md: "20px" }}
            py="10px"
            color="#000000"
            background="radial-gradient(circle, #ffffff, #2848F2)"
          >
            co-founders
          </Text>
          <Slider ref={this.slider} {...settings}>
            {coFoundList.map((el, idx) => (
              <Box key={idx} fontFamily="Poppins">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDir={{ base: "column-reverse", xl: "row" }}
                  my="10px"
                >
                  <Box
                    bg="#31312F"
                    color="rgba(255, 255, 255, 1)"
                    borderRadius={{ base: "10px", md: "20px" }}
                    p="1.5rem"
                    w={{ base: "100%", xl: "49%" }}
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                  >
                    <Text
                      fontWeight="500"
                      fontSize="26px"
                      lineHeight="32px"
                      my="2rem"
                    >
                      {el.name}
                    </Text>
                    <Text
                      fontWeight="300"
                      fontSize="14px"
                      textAlign={{ base: "justify", md: "left" }}
                    >
                      {el.aboutText}
                    </Text>
                    <Box
                      borderColor="rgba(255, 255, 255, 1)"
                      border="1px solid"
                      maxW="100px"
                      borderRadius="6px"
                      display="flex"
                      justifyContent="space-around"
                      my="1rem"
                    >
                      <button
                        className="button"
                        onClick={this.previous}
                        style={{ padding: "1rem" }}
                      >
                        <MdOutlineArrowBackIos />
                      </button>
                      <Box w="1px" bg="rgba(255, 255, 255, 1)" />
                      <button
                        className="button"
                        onClick={this.next}
                        style={{ padding: "1rem" }}
                      >
                        <MdOutlineArrowForwardIos />
                      </button>
                    </Box>
                  </Box>
                  <Image
                    src={el.photo}
                    alt={el.photo}
                    borderRadius={{ md: "20px", base: "10px" }}
                    my={{ base: "10px", xl: "0" }}
                    w={{ base: "100%", md: "49.5%" }}
                    mx="auto"
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </SectionComponent>
    );
  }
}

export default CoFounders;
