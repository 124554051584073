import React from "react";
import { Box, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  size?: string;
  closeIcon?: boolean;
}
const ModalMini = ({ isOpen, onClose, children, size, closeIcon }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      size={size ? size : "sm"}
    >
      <ModalOverlay bg="#000000B2" />
      <ModalContent
        bg="#2E2E2E"
        rounded="20px"
        px="10px"
        py="17px"
        position="relative"
      >
        <Box
          display={!!closeIcon ? "block" : "none"}
          className="modal__close"
          onClick={onClose}
        ></Box>
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ModalMini;
