export const YoutubeIconSvg = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26249 15.0568C1.87023 14.9408 1.51232 14.7343 1.21896 14.4549C0.925599 14.1754 0.705403 13.8312 0.576947 13.4512C-0.157079 11.4874 -0.374569 3.28693 1.03911 1.66807C1.50961 1.14145 2.17389 0.817218 2.88777 0.765759C6.68024 0.36768 18.3975 0.420757 19.7432 0.898453C20.1217 1.01832 20.4675 1.22026 20.7547 1.48912C21.0419 1.75799 21.263 2.08681 21.4015 2.45096C22.2035 4.48117 22.2307 11.8589 21.2928 13.8095C21.044 14.3173 20.6275 14.7285 20.1102 14.9772C18.6965 15.6672 4.13833 15.6539 2.26249 15.0568ZM8.28422 11.2087L15.0808 7.75869L8.28422 4.28213V11.2087Z"
        fill="white"
      />
    </svg>
  );
};
