import React, {useEffect, useState} from 'react';
import {Box, InputGroup, InputRightElement, Textarea} from "@chakra-ui/react";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {IInputProps} from "./EditInput";
import {ErrorText} from "../index";

interface Props extends IInputProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}
const EditTextarea = ({placeholder, cleaner, value, name, validation, handleChange, required = true, font = 'Poppins'}: Props) => {

  const [errorText, setError] = useState('')
  const [isFocus, setFocus] = useState(false)

  function setErrorText() {
    if (!value?.trim().length && required) {
      setError('obligatory field')
    } 
    // else if (!!value && value.trim().length >= 125) {
    //   setError('The number of characters must not exceed 125')
    // } 
    else {
      setError('')
    }
  }

  useEffect(() => {
    if (isFocus || validation) setErrorText()
  }, [value, validation])

  return (
    <Box mb='18px'>
      <InputGroup position='relative'>
        <Textarea
          onChange={handleChange}
          value={value}
          isRequired={required}
          variant='none'
          name={name}
          placeholder={placeholder}
          className='scroll'
          h='99px' w='100%'
          opacity='0.38'
          borderTopRadius='4px'
          borderBottomRadius='0'
          border='none' bg={'#2E2E2E'}
          fontSize='16px' letterSpacing='0.5px'
          fontWeight='400' fontFamily={font}
          lineHeight='24px' color='#FFFFFF'
          onFocus={() => setFocus(true)}
          _placeholder={{color: 'white', opacity: '0.38'}}
        />
        {!!value && <InputRightElement display='flex' alignItems='start' h='full' pt={2}>
          <Box
            onClick={() => cleaner(name)}
            color='#49454F' fontSize='1.8rem' cursor='pointer'
          ><IoMdCloseCircleOutline/></Box>
        </InputRightElement>}
      </InputGroup>
      <ErrorText>{errorText}</ErrorText>
    </Box>
  );
};
// <Textarea placeholder='Here is a sample placeholder' />
export default EditTextarea;