import { Box, Flex, Skeleton } from "@chakra-ui/react";
import React, { FC } from "react";

const CategoryShopCardSkeleton: FC = () => {
  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
      {Array.from({ length: 4 }, (_, idx) => (
        <Flex
          key={idx}
          w={{ lg: "24.5%", md: "33%", sm: "49.5%", base: "49%" }}
          mb={{ lg: "0", sm: "5px", base: "6px" }}
        >
          <Skeleton
            w="100%"
            h={{ md: "60px", base: "85px" }}
            borderRadius={{ md: "25px", base: "6px" }}
          />
        </Flex>
      ))}
    </Box>
  );
};

export default CategoryShopCardSkeleton;
