import {AppDispatch} from "../../../../../../redux/store";
import PRIVATE_API from "../../../../../../api";
import {getCompanyButtonsId, removeCompanyButtonId} from "../../companyReducer/addCompany.slice";
import {IContactButton, IMenuButtonServer, RootMenuButtons} from "../../../../../../models/profile";
import {buttonsSlice} from "./companyButtons.slice";
import {toastCreator} from "../../../../../../config/toast";
import toast from "react-hot-toast";
import PRIVATE_API_ADMIN from "../../../../../../api/admin/API";

export const addCompanyButtons = (button: IContactButton, value: string[], onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    const result = value.map(el => button.href + (button.type_id === 6 ? el.slice(1) : el) )
    dispatch(buttonsSlice.actions.addButton())
    const {data} = await PRIVATE_API.post<IMenuButtonServer>('api/v1/users/profile/buttons/',{
      is_company: true,
      type_: button.type_id,
      value: result[0],
      name: button.name
    })
    data.value = JSON.stringify([data.value]);
    dispatch(buttonsSlice.actions.getSuccessButton({button, data}))
    dispatch(getCompanyButtonsId(data.id))
    toastCreator('success')
    onClose();
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(buttonsSlice.actions.addedButton(e.response?.data?.message))
  }
}

export const addCompanyButtonsAdmin = (button: IContactButton, value: string[], onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    const result = value.map(el => button.href + (button.type_id === 6 ? el.slice(1) : el) )
    dispatch(buttonsSlice.actions.addButton())
    const {data} = await PRIVATE_API_ADMIN.post<IMenuButtonServer>('api/v1/users/profile/buttons/',{
      is_company: true,
      type_: button.type_id,
      value: result[0],
      name: button.name
    })
    data.value = JSON.stringify([data.value]);
    dispatch(buttonsSlice.actions.getSuccessButton({button, data}))
    dispatch(getCompanyButtonsId(data.id))
    toastCreator('success')
    onClose();
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(buttonsSlice.actions.addedButton(e.response?.data?.message))
  }
}

export const getCompanyButtons = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(buttonsSlice.actions.addButton())
    const response = await PRIVATE_API.get<RootMenuButtons>(
      'api/v1/users/profile/buttons/'
    )
    dispatch(buttonsSlice.actions.getButtonsCompany(response.data))
    dispatch(buttonsSlice.actions.addedButton(null))
  }catch (e: any) {
    dispatch(buttonsSlice.actions.addedButton(e.response?.data?.message))
    console.log('Error: ',e.response?.data?.message)
  }
}

export const removeButton = (id: number, inAdmin: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(buttonsSlice.actions.addButton())
    inAdmin ? await PRIVATE_API_ADMIN.delete(`api/v1/users/profile/buttons/${id}/`) :
    await PRIVATE_API.delete(`api/v1/users/profile/buttons/${id}/`)
    dispatch(buttonsSlice.actions.removeButtonLocal(id))
    dispatch(removeCompanyButtonId(id))
    toast.success('button removed')
    dispatch(buttonsSlice.actions.addedButton(null))
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    console.log('Error: ',e.response?.data?.message)
  }
}

