import React from 'react';

const MenuStatisticSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 22H21.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.34998 8.37988H4.75C4.2 8.37988 3.75 8.82988 3.75 9.37988V17.9999C3.75 18.5499 4.2 18.9999 4.75 18.9999H6.34998C6.89998 18.9999 7.34998 18.5499 7.34998 17.9999V9.37988C7.34998 8.82988 6.89998 8.37988 6.34998 8.37988Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5492 5.19043H11.9492C11.3992 5.19043 10.9492 5.64043 10.9492 6.19043V18.0004C10.9492 18.5504 11.3992 19.0004 11.9492 19.0004H13.5492C14.0992 19.0004 14.5492 18.5504 14.5492 18.0004V6.19043C14.5492 5.64043 14.0992 5.19043 13.5492 5.19043Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.7504 2H19.1504C18.6004 2 18.1504 2.45 18.1504 3V18C18.1504 18.55 18.6004 19 19.1504 19H20.7504C21.3004 19 21.7504 18.55 21.7504 18V3C21.7504 2.45 21.3004 2 20.7504 2Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default MenuStatisticSvg;