import React, {useState} from 'react';
import {Box, Modal, ModalContent, Text, useDisclosure} from "@chakra-ui/react";
import {DefButton} from "../../../components/ui";
import ModalInput from "../modalInput";
import EmailAdminSvg from "../modalInput/EmailAdminSvg";
import LockAdminSvg from "../modalInput/LockAdminSvg";
import {useNavigate} from "react-router-dom";
import {IValueLogin, onLogin} from "../AuthActions";
import CreatePasswordEmail from "../CreatePassword/CreatePasswordEmail";

const AdminLogin = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {onOpen, isOpen, onClose} = useDisclosure()
  const [value, setValue] = useState<IValueLogin>({
    email: "",
    password: ""
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onLogin(value, setLoading, navigate)
  }
  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={true} onClose={() => {
      }} isCentered={true} size='xl'>
        <ModalContent bg='#0E0E0E' px={{md: '53px', base: '4'}} py='66px' rounded='31px'>
          <form onSubmit={onSubmit}>
            <ModalInput
              handleChange={handleChange}
              value={value.email}
              type={'email'}
              name={'email'}
              icon={<EmailAdminSvg/>}
              placeholder={"Email"}
            />
            <ModalInput
              handleChange={handleChange}
              value={value.password}
              type={'password'}
              name={'password'}
              icon={<LockAdminSvg/>}
              placeholder={"Password"}
            />
            <Text
              onClick={onOpen}
              mt={3} cursor='pointer'
              textAlign='end'
              fontSize='14px'
              fontFamily='Poppins'
              fontWeight='700'
              color='#0080DD'
              _active={{color: '#0080DDCC'}}
            >Forgot password</Text>
            <Box mt='7px'>
              <DefButton isLoading={isLoading} isRound={true} isAdmin={true} type='submit'>Login</DefButton>
            </Box>
          </form>
        </ModalContent>
      </Modal>
      <CreatePasswordEmail onClose={onClose} isOpen={isOpen}/>
    </>
  );
};

export default AdminLogin;