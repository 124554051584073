import { createReducer } from "@reduxjs/toolkit";
import {
  exchangeSaveAction,
  firstTimeAction,
  forgotPswAction,
  loginFormAction,
  messageAfterAction,
  modalFAQAction,
  privacyPolicyAction,
  supportAction,
  techSupportAction,
} from "./action";
import { ILoginFormInterface } from "./interface";

const initialState: ILoginFormInterface = {
  isOpenLoginForm: false,
  isOpenSupport: false,
  isTechSupport: false,
  isOpenFAQ: false,
  isFirstTime: false,
  isForgotPsw: false,
  isMessageEmail: false,
  isExchange: false,
  isPrivacyPolicy: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(
    loginFormAction,
    (state, { payload }): ILoginFormInterface => {
      return { ...state, isOpenLoginForm: payload ? payload : false };
    }
  );
  builder.addCase(supportAction, (state, { payload }): ILoginFormInterface => {
    return {
      ...state,
      isOpenSupport: payload ? payload : false,
      isOpenLoginForm: payload ? false : true,
    };
  });
  builder.addCase(techSupportAction, (state, { payload }) => {
    return {
      ...state,
      isTechSupport: payload ? payload : false,
      isOpenSupport: payload ? false : true,
    };
  });
  builder.addCase(modalFAQAction, (state, { payload }): ILoginFormInterface => {
    return {
      ...state,
      isOpenFAQ: payload ? payload : false,
      isOpenSupport: payload ? false : true,
    };
  });
  builder.addCase(
    firstTimeAction,
    (state, { payload }): ILoginFormInterface => {
      return {
        ...state,
        isFirstTime: payload ? payload : false,
        isOpenLoginForm: payload ? false : true,
      };
    }
  );
  builder.addCase(
    forgotPswAction,
    (state, { payload }): ILoginFormInterface => {
      return {
        ...state,
        isForgotPsw: payload ? payload : false,
        isOpenLoginForm: payload ? false : true,
      };
    }
  );
  builder.addCase(
    messageAfterAction,
    (state, { payload }): ILoginFormInterface => {
      return { ...state, isMessageEmail: payload ? payload : false };
    }
  );
  builder.addCase(
    exchangeSaveAction,
    (state, { payload }): ILoginFormInterface => {
      return { ...state, isExchange: payload ? payload : false };
    }
  );
  builder.addCase(
    privacyPolicyAction,
    (state, { payload }): ILoginFormInterface => {
      return {
        ...state,
        isPrivacyPolicy: payload ? payload : false,
        isOpenSupport: payload ? false : true,
      };
    }
  );
});
