import React, {FC, useEffect} from "react";
import {Box} from "@chakra-ui/react";
import {useLocation, useParams} from "react-router-dom";

//local
import CustomButtons from "../../../components/custom-buttons";
import PersonGif from "../../../components/ui/PersonGif";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {INNER_WIDTH, INTERFACE_WIDTH} from "../../../config/_variables";
import {getUserById} from "../editing/Profile/ProfileReducer/actions";
import Companies from "./Companies";
import EditPart from "./EditPart";
import ProfileBtns from "./ProfileBtns";

const HomePage: FC = () => {
  const {userId} = useParams();

  const {is_gradient, avatar} = useAppSelector((state) => state.ProfileReducer.user);
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();


  useEffect(() => {
    if (userId) {
      dispatch(getUserById(userId));
    }
  }, [userId, dispatch]);

  const isUser = pathname.includes("user");

  const paddingTop = isUser
    ? {sm: "543px", base: (INNER_WIDTH / 6) * 5 + 100 + "px"}
    : "0";
  return (
    <Box pt={paddingTop}>
      <Box
        position={isUser ? "fixed" : "static"}
        bg="#000"
        zIndex="2"
        top="0"
        left="0"
        right="0"
        maxW={INTERFACE_WIDTH}
        mx="auto"
      >
        <Box
          h={{sm: "443px", base: (INNER_WIDTH / 6) * 5 + "px"}}
          w="full"
          position="relative"
        >
          <PersonGif avatar={avatar} is_gradient={is_gradient}/>
        </Box>
        <EditPart/>
      </Box>
      <Box
        bg="#000"
        position="relative"
        borderTopRadius="15px"
        pt="5px"
        zIndex="3"
        w="full"
        boxShadow="0 -20px 10px 0 rgba(0,0,0,0.3)"
      >
        <Box w="full" px="10px">
          <ProfileBtns/>
          <Companies/>
          <CustomButtons/>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
