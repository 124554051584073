import {Box, Flex, Text, Tooltip} from "@chakra-ui/react";
import React, {FC} from "react";
import {IProductCart} from "../../../models/products";

interface IProps {
  product: IProductCart
}

const CheckoutProductCard: FC<IProps> = ({product}) => {
  const sum_price = product.price * product.quantity

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p="1rem"
      flexDirection={{xl: 'row', md: 'column', sm: 'row', base: 'column'}}
      borderRadius="14px"
      my="10px"
      bg="linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))"
    >
      <Box>
        <Text fontSize="18px">{product.title}</Text>
        <Text
          mt='13px'
          fontWeight="300"
          fontSize="16px"
          color="rgba(255, 255, 255, 1)"
          opacity="50%"
        >
          {product.description}
        </Text>
      </Box>
      <Flex
        justifyContent='space-between'
        w={{xl: '50%', lg: '80%', md: '100%', sm: '50%', base: '100%'}}
        mt={{xl: '0', md: '10px', sm: '0', base: '10px'}}
      >
        <Tooltip label='Price' placement='top'>
          <Text
            fontWeight="300"
            fontSize="14px"
            color="rgba(255, 255, 255, 1)"
            opacity="50%"
          >{`${product.price} AED`}</Text>
        </Tooltip>
        <Tooltip label='Quantity' placement='top'>
          <Text fontWeight="300" fontSize="14px">
            {product.quantity}x
          </Text>
        </Tooltip>
        <Tooltip label='Total Price' placement='top'>
          <Text fontWeight="300" fontSize="14px">{`${sum_price} AED`}</Text>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default CheckoutProductCard;
