import React from 'react';

const WhatsAppSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.8747 7.68541C17.8372 3.24709 14.0843 0.231869 9.56 0.165039C8.96216 0.188626 8.31804 0.239732 7.73563 0.357668C1.39469 1.6471 -1.86835 8.78615 1.19798 14.5375C1.30598 14.6947 1.30598 14.8441 1.28669 15.0132C0.839277 16.4991 0.430432 18.0323 0.0370161 19.534C-0.0285532 19.8014 -0.0285527 19.8014 0.218297 19.7267C1.76496 19.3021 3.30006 18.8972 4.84287 18.5119C4.97786 18.4687 5.07429 18.4687 5.18614 18.5355C7.16865 19.5655 9.26301 19.8407 11.4577 19.4318C16.6916 18.4018 20.1282 12.965 18.8747 7.68541ZM10.9061 17.8672C10.4471 17.9576 10.019 18.0009 9.80299 17.9655C8.11748 17.9655 6.7598 17.592 5.4947 16.8451C5.31728 16.735 5.17843 16.6957 5.00872 16.7625C4.14474 16.9788 3.30391 17.2107 2.44766 17.4348C2.27023 17.4937 2.2548 17.4662 2.29337 17.2775C2.52094 16.4716 2.73307 15.65 2.97992 14.8166C3.03392 14.5925 3.01849 14.4274 2.87964 14.2426C1.14784 11.3768 1.22498 7.8348 3.1612 5.18124C5.17843 2.43727 7.94776 1.29329 11.2378 2.0127C14.4854 2.70459 16.5644 4.86282 17.301 8.15322C18.3579 12.6505 15.3725 17.1281 10.9061 17.8672Z"
            fill="#FFFFFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0627 14.5398C11.731 14.5712 11.3916 14.5044 11.0715 14.3983C8.88839 13.7496 7.23374 12.3855 5.90306 10.5339C5.40936 9.84596 4.93881 9.11082 4.7961 8.24203C4.59553 7.22778 4.90409 6.33147 5.65621 5.63958C5.90692 5.39977 6.63204 5.2897 6.92903 5.43909C7.02545 5.52951 7.07945 5.61206 7.14117 5.70641C7.41116 6.39437 7.70043 7.03515 7.97428 7.69952C8.01285 7.83319 8.009 7.96684 7.93571 8.10837C7.78914 8.40714 7.56544 8.65874 7.34945 8.9064C7.16817 9.07151 7.16817 9.25627 7.29159 9.44497C8.07457 10.7816 9.13139 11.7369 10.5508 12.2951C10.7552 12.3855 10.9133 12.3541 11.0406 12.185C11.2875 11.902 11.5266 11.5875 11.7542 11.2808C11.893 11.0882 12.0782 11.0568 12.2864 11.1393C12.7531 11.3634 13.216 11.5875 13.6634 11.7958C13.7868 11.8548 13.8755 11.9059 13.9835 11.9452C14.4541 12.185 14.4541 12.2204 14.4001 12.7354C14.3384 13.7496 13.5708 14.2646 12.6991 14.5162C12.46 14.5712 12.271 14.5712 12.0627 14.5398Z"
            fill="#FFFFFF"/>
    </svg>

  );
};

export default WhatsAppSvg;