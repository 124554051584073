import React from 'react';
import {Box, HStack, Text} from "@chakra-ui/react";
import Placement from "../../ui/placement";
import {contact_buttons} from "../CustomReducer/data";
import ButtonCard from "./ButtonCard";
import {IContactButton} from "../../../models/profile";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (button: IContactButton, values: string[], closeModals: () => void) => void;
}

const CustomModal = ({isOpen, onClose, onSubmit}: Props) => {
  return (
    <Placement isOpen={isOpen} onClose={onClose} title='Add button'>
      <Box>
        {contact_buttons.map(part => (
          <Box key={part._id} mb={5} w='100%'>
            <Text
              color='#FFFFFF' fontFamily='Poppins'
              fontWeight='500' lineHeight='18px'
              fontSize='12px' textTransform='uppercase'
              px={4} py='5px'
            >{part.name}</Text>
            <HStack
              display='flex' alignItems='start' w='100%'
              flexWrap='wrap' spacing={{sm: '22px 18px', base: '22px 15px'}}
              rounded='14px' bg='#1A1A1ACC' px={4} py={5}
            >
              {part.value.map((btn, idx) =>
                <ButtonCard
                  key={idx}
                  onSubmit={onSubmit}
                  btn={btn}
                  title={part.name}
                  parentClose={onClose}
                />
              )}
            </HStack>
          </Box>
        ))}
      </Box>
    </Placement>
  );
};

export default CustomModal;