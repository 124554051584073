import React, {HTMLInputTypeAttribute} from 'react';
import {Input} from "@chakra-ui/react";
import {InputType} from "zlib";


interface Props {
  placeholder: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  as?: 'input' | 'textarea';
  name: string;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute
}

const InputStore = ({placeholder, value, handleChange, name, isRequired = true, as = 'input', type = 'text'} : Props) => {
  return (
    <Input
      as={as}
      name={name}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      fontWeight="400"
      fontSize="16px"
      type={type}
      pt={as === 'textarea' ? '12px' : 'auto'}
      h={as === 'input' ? '72px': '92px'}
      borderRadius="15px"
      bg="#FFFFFF33"
      border='none'
      px='15px' color="white"
      mb="15px" w='100%'
      letterSpacing='1px'
      _placeholder={{
        opacity: "50%",
        color: 'white',
        fontSize: '14px',
      }}
      isRequired={isRequired}
    />
  );
};

export default InputStore;