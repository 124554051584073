import React from 'react';
import {Box, Image, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import {BiEdit} from "react-icons/bi";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import CompanyEdit from "../../../interface/editing/Company";
import {copyCompanyToChange} from "../../../interface/editing/Company/companyReducer/actions";
import {API_ADDRESS} from "../../../../api";

const EditCorp = () => {
  const dispatch = useAppDispatch()
  const {onOpen, isOpen, onClose} = useDisclosure()
  const {corp} = useAppSelector(state => state.CorpReducer)

  const handleClick = () => {
    onOpen()
    dispatch(copyCompanyToChange(corp))
  }
  return (
    <>
      <Box
        w='44.52px' h='44.52px'
        bg='#110000' position='relative'
        rounded='50%' mr='13.67px'>
        {!!corp.preview && <Image w='100%' h="100%" rounded='50%' objectFit='cover' src={API_ADDRESS + corp.preview} alt=""/>}
        <Box
          onClick={handleClick}
          color='#FFFFFF' fontSize='1.5rem' _active={{color: '#6A6A6A'}}
          position='absolute' right='-4px' cursor='pointer'
          top='0'><BiEdit/>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered={true}>
        <ModalOverlay/>
        <ModalContent bg='#0E0E0E' px={{md: '50px', base: '4'}} pt='42px' pb='66px' rounded='31px'>
          <ModalCloseButton color='#A8A8A8'/>
          <CompanyEdit inAdmin={true} onCloseAdmin={onClose}/>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditCorp;