import toast from "react-hot-toast";

interface IToastMsgs {
  loading: string,
  success: string,
  error: string,
}

export const promiseMsgs: IToastMsgs = {
  loading: 'Loading',
  success: 'Got the data',
  error: 'Error when fetching',
}

export const SUCCESS_TOAST = "Data added successfully"
export const ERROR_TOAST = "An error has occurred! "

type StatusTypes = 'success' | 'error'

export function toastCreator(status: StatusTypes, message?: string){
  if (status === 'success') {
    toast.success(message ? message : SUCCESS_TOAST)
  }else {
    toast.error(`${ERROR_TOAST} ${!!message? message : ''}`)
  }
}