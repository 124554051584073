import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompanyImages} from "../../../../../../models/company";

interface AddGalleyState {
  isError: string | null;
  isLoading: boolean;
  images: ICompanyImages[];
}

const initialState: AddGalleyState = {
  isError: null,
  isLoading: false,
  images: [],
}

export const gallerySLice = createSlice({
  name: 'gallery/post',
  initialState,
  reducers: {
    addGallery(state) {
      state.isLoading = true;
    },
    addedGallery(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.isError = action.payload
    },
    getImages(state, action: PayloadAction<ICompanyImages>){
      const found = state.images.find(el => el.id === action.payload.id)
      if (found){
        const index = state.images.indexOf(found)
        state.images[index] = action.payload
      }else {
        state.images.push(action.payload)
      }
    },
    getImagesToChange(state, action: PayloadAction<ICompanyImages[]>){
      state.images = []
      state.images = action.payload;
    },
    removeImage(state, action: PayloadAction<number>){
      state.images = state.images.filter(el => el.id !== action.payload)
    },
    resetImages(state){
      state.images = []
    }
  }
})

export const GalleryReducer = gallerySLice.reducer
