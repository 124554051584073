import React, {useEffect, useState} from 'react';
import {Flex, Heading, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {LuSearch} from "react-icons/lu";
import {onLogoutAdmin} from "../../AuthActions";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {useDebounce} from "../../../../config/hooks/debounce";
import {searchEmployee} from "../reducer/actions";
import EditCorp from "./EditCorp";
import {useEncryption} from "../../../../config/helpers";

const AdminHeader = () => {
  const dispatch = useAppDispatch()
  const {corp} = useAppSelector(state => state.CorpReducer)

  const [value, setValue] = useState('')

  const debounced = useDebounce(value, 400)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const [name, font] = useEncryption(corp.name)

  useEffect(() => {
    dispatch(searchEmployee(debounced))
  },[debounced])

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <InputGroup maxW='460px'>
        <Input
          onChange={handleChange}
          bg='#FFFFFF1A'
          variant='none'
          h='40px' rounded='14px'
          border='1px solid #494949'
          color='#FFFFFF7F'
          placeholder='User Name'
          fontFamily='Rubik'
          fontWeight='400'
          fontSize='17px' pr='60px' pl='5'
          _placeholder={{color: '#FFFFFF7F'}}
        />
        <InputRightElement w='70px' color={'#FFFFFF7F'} fontSize='1.2rem' alignItems='center' h='100%'>
          <LuSearch/>
        </InputRightElement>
      </InputGroup>
      <Flex alignItems='center'>
        <Flex alignItems='center' mr='79px'>
         <EditCorp/>
          <Heading
            color='#FFFFFF'
            fontFamily='Rubik'
            fontWeight='500'
            fontSize='20px'
            textTransform='uppercase'
          >{name}</Heading>
        </Flex>
        <Heading
          onClick={onLogoutAdmin}
          _active={{color: '#FFFFFFCC'}}
          cursor='pointer'
          color='#FFFFFF'
          fontFamily='Rubik'
          fontWeight='400'
          fontSize='20px'
        >Log out</Heading>
      </Flex>
    </Flex>
  );
};

export default AdminHeader;