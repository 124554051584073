export const EmailReqSvg = () => {
  return (
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 29.5H13C10 29.5 8 28 8 24.5V17.5C8 14 10 12.5 13 12.5H23C26 12.5 28 14 28 17.5V24.5C28 28 26 29.5 23 29.5Z"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 18L19.87 20.5C18.84 21.32 17.15 21.32 16.12 20.5L13 18"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
