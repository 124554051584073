import { Box, Text } from "@chakra-ui/react";
import React, { FC, useState } from "react";

//local
import ModalMini from "../ui/modal";
import CallbackForm from "../../pages/store/components/Callback/CallbackForm";
import CallBackWhatsapp from "../../pages/store/components/Callback/CallBackWhatsapp";

const CallMeBack: FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isHover, setHover] = useState<boolean>(false);

  return (
    <>
      <Text
        fontSize="14px"
        lineHeight="18px"
        opacity="80%"
        display={{ md: "block", base: "none" }}
        cursor="pointer"
        textUnderlineOffset="revert"
        position="relative"
        mt="5px"
        py="5px"
        _after={{
          content: "''",
          position: "absolute",
          bottom: "1px",
          left: "0",
          right: "0",
          zIndex: "1",
          borderBottom: `1px dashed ${
            isHover ? "rgba(36, 93, 206, 1)" : "#ffffff"
          }`,
        }}
        color={isHover ? "rgba(0, 132, 255, 1)" : ""}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setOpen(true)}
      >
        Call me back
      </Text>
      <ModalMini
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        children={
          <Box p="1.8rem">
            <Box pb="20px">
              <CallBackWhatsapp maxWComponent="100%" maxWBtn="100%" />
            </Box>
            <CallbackForm isVisible={true} />
          </Box>
        }
        size="xl"
        closeIcon={true}
      />
    </>
  );
};

export default CallMeBack;
