import React, { Component, ReactNode } from "react";
import { Containers } from ".";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
  idName?: string;
}

class SectionComponent extends Component<IProps> {
  render(): ReactNode {
    const { children, idName } = this.props;
    return (
      <section id={idName}>
        <Box position="relative" overflow="hidden">
          <Containers>{children}</Containers>
        </Box>
      </section>
    );
  }
}

export default SectionComponent;
