import React, {useEffect, useRef} from 'react';
import {Box, Flex, Text, useDisclosure, useOutsideClick} from "@chakra-ui/react";
import MenuBtn from "./MenuBtn";
import {CgLogOut} from "react-icons/cg";
import {RiUser3Line} from "react-icons/ri";
import ChatGPTSvg from "../../../assets/svg/ChatGPTSvg";
import ShareSvg from "../../../assets/svg/ShareSvg";
import MyQrCode from "./MyQrCode";
import {logout, navigateToUrl, URL_CHAT_GPT} from "../../../config/helpers";
import LoginUser from '../../forms/LoginUser';
import Placement from '../../ui/placement';
import {useLoginInterface} from '../../../redux/loginFormInterface/hook';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import {
  setIsFirstTime,
  setIsForgotPsw,
  setIsMessageEmail,
  setIsOpenFAQ,
  setIsOpenLogin,
  setIsOpenSupport,
  setIsPrivacyPolicy,
  setIsTechSupport
} from '../../../redux/loginFormInterface/action';
import {FAQuations, Support, TechnicalSupport} from '../../support';
import {SlMenu} from "react-icons/sl";
import SendEmail from '../../forms/SendEmail';
import ModalMini from '../../ui/modal';
import MessageAfterEmail from '../../messageAfterEmail/MessageAfterEmail';
import { useAuthUser } from '../../../config/hooks/userAccess';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicyText from '../../PrivacyPolicyText';
import {ImArrowRight} from "react-icons/im";

interface Props {
  openMenu: () => void;
  isMenu: boolean;
}

const btn = {
  id: 5,
  icon: <SlMenu/>,
  name: 'Menu',
  className: 'level5',
}

const left = {
  id: 5,
  icon: <ImArrowRight/>,
  name: 'Log Out',
  className: 'level5',
}

const CustomMenu = ({openMenu, isMenu}: Props) => {

  const isAuth = useAuthUser()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    isOpenLoginForm,
    isOpenSupport,
    isTechSupport,
    isOpenFAQ,
    isFirstTime,
    isForgotPsw,
    isMessageEmail,
    isPrivacyPolicy
  } = useLoginInterface(); //значение состояние логин формы, для открытые модалки

  const { user } = useAppSelector((s) => s.ProfileReducer);

  const {isOpen, onOpen, onClose} = useDisclosure()
  const ref = useRef<any>()

  const handleLogout = () => {
    logout()
    navigate(`/user/${user.card_id}`)
  }

  const handleClick = () => {
    if (isMenu){
      return isAuth ? handleLogout() : onOpen()
    }else {
      openMenu()
    }
  }

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  const share = () => {
    window.navigator.share({
      text: 'Checkout this cool website!',
      url: window.location.href,
      title: 'NFC - Network. Future. Concept'
    })
  }
  useEffect(() => onClose(), [isMenu])
  return (
    <>
    <Flex
      ref={ref}
      flexDirection='column' alignItems='center' position='relative'>
      <Box hidden={!isOpen} zIndex='15' position='absolute' left='0' bottom='106px' width='100%'>
        <Flex flexDirection='column' alignItems='center'>
          {
            isAuth ?
            <MenuBtn 
              icon={<CgLogOut/>} name='Log Out'
              handleClick={handleLogout}
            />:
            <MenuBtn
              icon={<RiUser3Line/>} name={'Log In'}
              handleClick={() => {
                dispatch(setIsOpenLogin(true));
                onClose();
              }}
            />
          }
          <MenuBtn
            icon={<ChatGPTSvg/>} name={'Chat GPT'}
            handleClick={() => navigateToUrl(URL_CHAT_GPT)}
          />
          <MyQrCode/>
          <MenuBtn icon={<ShareSvg/>} name={'Share'} handleClick={share}/>
        </Flex>
      </Box>
      {!isAuth && <Flex
        onClick={handleClick} className={`custom-btn ${btn.className}`}
        rounded='50%' p='1px' mx={{sm: 2, base: '0'}}
        w='64px' h='61.43px' cursor='pointer'
        bg='linear-gradient(#C8C8C8, #49494990, #29292975, #FFFFFF47)'
      >
        <Flex
          justifyContent='center'
          alignItems='center'
          rounded='50%' bg='#000' _active={{bg: '#171717'}}
          w='100%' h='100%' color={'#FFFFFF'} fontSize='1.4rem'
        >
          {isMenu && btn.icon}
        </Flex>
      </Flex>}

      {isAuth && <Flex
        onClick={handleClick} className={`custom-btn ${btn.className}`}
        rounded='50%' p='1px' mx={{sm: 2, base: '0'}}
        w='64px' h='61.43px' cursor='pointer'
        bg='linear-gradient(#C8C8C8, #49494990, #29292975, #FFFFFF47)'
      >
        <Flex
          justifyContent='center'
          alignItems='center'
          rounded='50%' bg='#000' _active={{bg: '#171717'}}
          w='100%' h='100%' color={'#FF0000FF'} fontSize='1.4rem'
        >
          {isMenu &&  left.icon}
        </Flex>
      </Flex>}

      {isMenu &&
        <Text transition='0.2s' fontFamily='Poppins' textAlign='center' w='full' fontWeight='400' mt={2} fontSize='10px'
              color={isAuth ? '#FF0000FF' : '#E1E1E1'}>{isAuth ? left.name : btn.name}</Text>}
    </Flex>
      <Placement
        isOpen={isOpenLoginForm}
        onClose={() => dispatch(setIsOpenLogin(false))}
        children={<LoginUser />}
        title="login"
      />
      <Placement
        isOpen={isOpenSupport}
        onClose={() => dispatch(setIsOpenSupport(false))}
        children={<Support />}
        title="support"
      />
      <Placement
        isOpen={isTechSupport}
        onClose={() => dispatch(setIsTechSupport(false))}
        children={<TechnicalSupport />}
        title="Technical Support"
      />
      <Placement
        isOpen={isOpenFAQ}
        onClose={() => dispatch(setIsOpenFAQ(false))}
        children={<FAQuations />}
        title="FAQ"
      />
      <Placement
        isOpen={isFirstTime}
        onClose={() => dispatch(setIsFirstTime(true))}
        children={<SendEmail />}
        title="login"
        viewvBtn={true}
      />
      <Placement
        isOpen={isForgotPsw}
        onClose={() => dispatch(setIsForgotPsw(false))}
        children={<SendEmail />}
        title="login"
      />
      <ModalMini
        isOpen={isMessageEmail}
        onClose={() => dispatch(setIsMessageEmail(false))}
        children={
          <MessageAfterEmail text="In order to set a new password, you must confirm your email address." />
        }
      />
      <Placement
        isOpen={isPrivacyPolicy}
        onClose={() => dispatch(setIsPrivacyPolicy(false))}
        children={<PrivacyPolicyText />}
        title="Privacy Policy"
      />
  </>
  );
};

export default CustomMenu;