import React from "react";
import {Box, Text} from "@chakra-ui/layout";

//local
import {useCompanyDetail} from "../../editing/Company/companyReducer/hooks";
import ImageSlide from "./ImageSlide";
import {BtnGray} from "../../../../components/ui";

const Media = () => {
  const {
    company: {images},
    isLoading,
  } = useCompanyDetail();

  if (isLoading) {
    return null;
  }

  return (
    <>
      {images.length > 0 && (
        <Box fontFamily="Poppins" color="#FFFFFF" mt="50px">
          {images.map((el) => (
            <Box key={el.id} bg="#000" borderRadius={10} mt='30px'>
              {typeof el.image === "string" && <ImageSlide imgStr={el.image}/>}
              <Text
                fontWeight={275}
                fontSize={15}
                lineHeight="22.5px"
                textAlign="center"
                color="#C8C8C8"
              >
                {el.description}
              </Text>
              {!!el.button_link &&
                <Box display="flex" justifyContent="center" pt="10px">
                  <BtnGray
                    text={!!el.button_name ? el.button_name : "Go to website"}
                    onClick={() => {
                      window.open(el.button_link);
                    }}
                  />
                </Box>}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Media;
