import {AppDispatch} from "../../../../redux/store";
import {corpSlice, ISearchPayload} from "./slice";
import {ICorp, IEmployees} from "./types";
import {getLocalStorage, logout, toLocalStorage} from "../../../../config/helpers";
import PRIVATE_API_ADMIN from "../../../../api/admin/API";
import {addCompanySLice} from "../../../interface/editing/Company/companyReducer/addCompany.slice";
import toast from "react-hot-toast";
import {promiseMsgs} from "../../../../config/toast";
import {onLogoutAdmin} from "../../AuthActions";
import {profileSlice} from "../../../interface/editing/Profile/ProfileReducer/profileSlice";
import PRIVATE_API from "../../../../api";
import {RootProfile} from "../../../../models/profile";
import {getCompanies} from "../../../interface/editing/Company/companyReducer/actions";
import {getProfileSimilar} from "../../../interface/editing/Profile/ProfileReducer/actions";


export const getCorporationEmployees = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(corpSlice.actions.getCorp())
    const slug = getLocalStorage("slug")
    PRIVATE_API_ADMIN.get<IEmployees[]>(`api/v1/corp/${slug}/employees/`)
      .then(({data}) => {
        dispatch(corpSlice.actions.getEmployees(data))
      })
      .catch((e) => {
        dispatch(corpSlice.actions.getCorpError(e.response?.data?.message))
      })
  } catch (e: any) {
    dispatch(corpSlice.actions.getCorpError(e.response?.data?.message))
  }
}

export const editEmployee = async (
  {card_id, full_name, designation, is_gradient, avatar, welcome_text}: IEmployees,
  setLoading: (b: boolean) => void, onClose: () => void) => {
  try {
    setLoading(true)
    const slug = getLocalStorage("slug")
    const {status} = await PRIVATE_API_ADMIN.patch(`api/v1/corp/${slug}/employees/${card_id}/`, {
      full_name,
      designation,
      is_gradient,
      avatar,
      welcome_text
    })
    if (status) {
      onClose()
    }
    setLoading(false)
  } catch (e) {
    setLoading(false)
  }
}


export const getCorpSlug = () => async (dispatch: AppDispatch) => {
  try {
    const slug = getLocalStorage("slug")
    dispatch(corpSlice.actions.getCorp())
    const {data} = await PRIVATE_API_ADMIN.get<ICorp>(`api/v1/corp/${slug}/`)
    dispatch(corpSlice.actions.getCorpSuccess(data))
  } catch (e: any) {
    // onLogoutAdmin()
    dispatch(corpSlice.actions.getCorpError(e.response?.data?.message))
  }
}


export const editCorp = (value: any, redirect: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(addCompanySLice.actions.addCompany())
    const slug = getLocalStorage("slug")
    const promiseAl = PRIVATE_API_ADMIN.patch(`api/v1/corp/${slug}/`, value)
    toast.promise(promiseAl, promiseMsgs)
      .then(({data}) => {
        toLocalStorage("slug", data.slug);
        dispatch(corpSlice.actions.getCorpSuccess(data))
        redirect()
        dispatch(addCompanySLice.actions.addedCompany(null))
      })
      .catch(error => {
        dispatch(addCompanySLice.actions.addedCompany(error))
      })
  } catch (e: any) {
    dispatch(addCompanySLice.actions.addedCompany(e.response?.data?.message))
  }
}


export const searchEmployee = (search: string) => async (dispatch: AppDispatch) => {
  try {
    // dispatch(corpSlice.actions.getCorp())
    const slug = getLocalStorage("slug")
    const {data} = await PRIVATE_API_ADMIN.get<IEmployees[]>(`api/v1/corp/${slug}/employees/?search=${search}`)
    const result: ISearchPayload = {
      data, search
    }
    dispatch(corpSlice.actions.getSearchEmployees(result))
  } catch (e: any) {
    dispatch(corpSlice.actions.getCorpError(e.response?.data?.message))
  }
}


export const getEmployeeDetail = (card_id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.getProfile());
    const { data } = await PRIVATE_API_ADMIN.get<RootProfile>(
      `/api/v1/users/${card_id}/profile/`
    );
    // dispatch(getCompanies(data.card_id));
    dispatch(getProfileSimilar(data));
  } catch (e: any) {
    if (e.response?.data?.message?.includes("token"))
    dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
  }
};