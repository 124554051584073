import React, {FC} from "react";

//local
import {SkeletonCompanyList} from "../../../../components/skeletons";
import {useAppSelector} from "../../../../redux/store";
import CompanyTab from "./CompanyTab";
import Location from "./Location";
import Buttons from "./Buttons/Buttons";
import Videos from "./Videos";
import Media from "./Media";
import {Flex, Image, Link, Text} from "@chakra-ui/react";
import PoweredByLogo from "../../../../assets/img/powered-by-logo.webp"

const Companies: FC = () => {
  const {companies, isLoading} = useAppSelector(
    (state) => state.CompanyReducer
  );

  if (!!isLoading) {
    return <SkeletonCompanyList/>;
  }

  return (
    <>
      {companies.length > 0 && (
        <>
          <CompanyTab companies={companies}/>
          <Buttons/>
          <Videos/>
          <Media/>
          <Location/>
          <Flex
            mt='30px'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Text
              fontWeight={'300'}
              fontSize={'15px'}
              lineHeight="22.5px"
              opacity='.8'
              textAlign="center"
              color="#A7ACAF"
            >powered by</Text>
            <Link href="https://nfc-bc.com/" target='_blank'>
              <Image width={180} mt='-8px' src={PoweredByLogo} alt='Logo'/>
            </Link>
          </Flex>
        </>
      )}
    </>
  );
};

export default Companies;
