import { RouteProps } from "react-router-dom";
import {
  BasketPage,
  CheckoutPage,
  Editing,
  HomePage,
  HomeStorePage,
  Leads,
  Member,
  MemberDetail,
  ResetLink,
  Analytics,
} from "../pages";
import ProfileEdit from "../pages/interface/editing/Profile";
import CompanyEdit from "../pages/interface/editing/Company";
import CoFounders from "../pages/store/components/About/CoFounders";
import CompanyDashboard from "../pages/admin/CompanyDashboard";

export const privateInterfaceRoutes: RouteProps[] = [
  {
    path: "/profile",
    element: <HomePage />,
  },
  {
    path: "/leads",
    element: <Leads />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/member",
    element: <Member />,
  },
  {
    path: "/member-detail/:memberId",
    element: <MemberDetail />,
  },
  {
    path: "/edit/profile",
    element: (
      <Editing title="profile">
        <ProfileEdit />
      </Editing>
    ),
  },
  {
    path: "/edit/company",
    element: (
      <Editing title="company">
        <CompanyEdit />
      </Editing>
    ),
  },
];

export const routesInterface: RouteProps[] = [
  {
    path: "/user/:userId",
    element: <HomePage />,
  },
  {
    path: "/reset",
    element: <ResetLink />,
  },
];

export const routesStore: RouteProps[] = [
  {
    path: "/",
    element: <HomeStorePage />,
  },
  {
    path: "/basket",
    element: <BasketPage />,
  },
  {
    path: "/checkout",
    element: <CheckoutPage />,
  },
  {
    path: "/about",
    element: <CoFounders />,
  },
];

export const routesAdmin: RouteProps[] = [
  {
    path: '/admin',
    element: <CompanyDashboard/>
  }
]
