import {AppDispatch} from "../../../redux/store";
import PRIVATE_API, {API_ADDRESS} from "../../../api";
import {uploadSlice} from "./upload.slice";
import {toastCreator} from "../../../config/toast";
import PRIVATE_API_ADMIN from "../../../api/admin/API";

export const uploadFiles = (file: any, uploader: any, onClose: () => any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(uploadSlice.actions.uploadFile())
    let formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    const response = await PRIVATE_API.post('api/v1/users/upload/', formData, config)
    dispatch(uploadSlice.actions.uploadFileSuccess())
    uploader(response.data.path)
    toastCreator("success", "File updated")
    onClose()
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(uploadSlice.actions.uploadFileError(e.response?.data?.message))
  }
}


export const loadImage = (url: string) => (dispatch: AppDispatch) => {
  if (!!url) {
    const image: HTMLImageElement = new Image()
    dispatch(uploadSlice.actions.uploadFile())
    image.src =  API_ADDRESS + url
    image.onload = function () {
      dispatch(uploadSlice.actions.uploadFileSuccess())
    }
  }
}




export const uploadAvatarFromAdmin = (file: any, uploader: any, onClose: () => any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(uploadSlice.actions.uploadFile())
    let formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    const response = await PRIVATE_API_ADMIN.post('api/v1/users/upload/', formData, config)
    dispatch(uploadSlice.actions.uploadFileSuccess())
    uploader(response.data.path)
    toastCreator("success", "File updated")
    onClose()
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(uploadSlice.actions.uploadFileError(e.response?.data?.message))
  }
}
