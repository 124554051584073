import React from 'react';

const MenuMemberSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.25 6L5.25 18H19.25L12.25 6ZM12.25 9.96927L8.73207 16H15.7679L12.25 9.96927Z" fill="#CAC4D0"/>
    </svg>

  );
};

export default MenuMemberSvg;