import React from "react";

const Galochka = () => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.18457 3.08827L1.27701 2.13239L4.0081 4.04416L8.5144 0.766846H10.2896L4.0081 6.22903L0.18457 3.08827Z"
        fill="white"
      />
    </svg>
  );
};

export default Galochka;
