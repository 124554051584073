import { Box, Text } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { MdPhone } from "react-icons/md";
import { navigateToUrl } from "../../config/helpers";
import { TELL_NUMBER } from "../../config/admin";
import CallMeBack from "./CallMeBack";

const CallPhone: FC = () => {
  const [isHover, setHover] = useState<boolean>(false);
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-end"
      color="rgba(255, 255, 255, 1)"
      fontSize="18px"
      py="5px"
    >
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => navigateToUrl("tel:" + TELL_NUMBER, true)}
        transition=".4s"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          w="35px"
          h="35px"
          borderRadius="50%"
          bg="rgba(0, 132, 255, 1)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 25px #0b98ff"
        >
          <MdPhone />
        </Box>
        <Text
          fontWeight="600"
          ml="15px"
          display={{ md: "block", base: "none" }}
          transition=".4s"
          color={isHover ? "rgba(0, 132, 255, 1)" : ""}
        >
          {TELL_NUMBER}
        </Text>
      </Box>
      <CallMeBack />
    </Box>
  );
};

export default CallPhone;
