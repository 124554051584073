import React from 'react';
import {INNER_WIDTH} from "../../../config/_variables";
import {Box, Flex, Image} from "@chakra-ui/react";
import {API_ADDRESS} from "../../../api";
import DefaultCompanySvg from "../../../assets/svg/DefaultCompanySvg";

interface Props {
  preview: string;
  is_gradient: boolean
}
const CompanyPreview = ({preview, is_gradient}: Props) => {
  return (
    <Box w='full' rounded='15px' overflow='hidden' position='relative'
         h={{sm: '326px', base: (INNER_WIDTH - 32) / 5 * 3 + 'px'}}>
      {preview && <Image objectFit='cover' height='100%' w='100%' src={API_ADDRESS + preview} alt='Company'/>}
      {!preview && <Flex justifyContent='center' alignItems='center' height='100%' w='100%' bg='#A9A9A9'>
        <DefaultCompanySvg/>
      </Flex>}
      <Flex hidden={!is_gradient} w='100%' h='152px' position='absolute' left='0' right='0' bottom='0'
            bg='linear-gradient(#15151500, #151515)'/>
    </Box>
  );
};

export default CompanyPreview;
