import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import { Btn } from "../ui";
import NFC_Icon from "../../assets/img/NFC-ICON.webp";
import { useAppDispatch } from "../../redux/store";
import {
  setIsOpenFAQ,
  setIsPrivacyPolicy,
  setIsTechSupport,
} from "../../redux/loginFormInterface/action";
import { EMAIL_ADDRESS } from "../../config/admin";
import { navigateToUrl } from "../../config/helpers";

const Support: FC = () => {
  const dispatch = useAppDispatch();
  const buttons = [
    {
      text: "Technical Support",
      onClick: () => dispatch(setIsTechSupport(true)),
    },
    {
      text: "FAQ",
      onClick: () => dispatch(setIsOpenFAQ(true)),
    },
    {
      text: "Privacy Policy",
      onClick: () => dispatch(setIsPrivacyPolicy(true)),
    },
  ];
  return (
    <>
      {buttons.map((el, index) => (
        <Box key={index} mb="20px">
          <Btn text={el.text} onClick={el.onClick} />
        </Box>
      ))}
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="10px"
        lineHeight="15px"
        color="#717171"
        pt="10px"
        pb="20px"
      >
        <Image src={NFC_Icon} alt="nfc_logo" />
        <Text mt="8px">This Softwhare mae by NFC-Smart Cards LLC</Text>
        <Text mt="8px">Dubai - United Arab Emirates</Text>
        <Text
          mt="8px"
          color="#0080DD"
          cursor="pointer"
          onClick={() => navigateToUrl("mailto:" + EMAIL_ADDRESS, true)}
        >
          {EMAIL_ADDRESS}
        </Text>
      </Box>
    </>
  );
};

export default Support;
