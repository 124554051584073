import React, {useEffect, useState} from 'react';
import {Box, Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {CiSearch} from "react-icons/ci";
import {useDebounce} from "../../../../config/hooks/debounce";
import {useAppDispatch} from "../../../../redux/store";
import {searchMembers} from "../MemberReducer/actions";

const MemberSearch = () => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')

  const debounced = useDebounce(value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    dispatch(searchMembers(debounced))
  },[debounced])

  return (
    <Box px='2.5' h='46px'>
      <InputGroup h='100%'>
        <InputLeftElement color='gray.300' fontSize='1.2rem' h='100%'>
          <CiSearch/>
        </InputLeftElement>
        <Input
          onChange={handleChange}
          value={value}
          type='search' h='100%'
          bg='#FFFFFF33' rounded='25px'
          border='none' variant='none'
          placeholder='Search'
          fontFamily='Poppins'
          fontSize='16px'
          fontWeight='400'
          lineHeight='24px'
          letterSpacing='1px'
          color='#FFFFFF80'
        />
      </InputGroup>
    </Box>
  );
};

export default MemberSearch;