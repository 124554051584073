import React, {useEffect, useState} from 'react';
import {Box, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import ModalInput from "../modalInput";
import LockAdminSvg from "../modalInput/LockAdminSvg";
import {DefButton, ErrorText} from "../../../components/ui";
import {BiSolidPaste} from "react-icons/bi";
import {ICreatePassword, onCreatePassword} from "../AuthActions";
import {useNavigate} from "react-router-dom";


interface Props {
  isOpen: boolean;
  onClose: () => void
}

const CreatePassword = ({isOpen, onClose} : Props) => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [validation, setValid] = useState(false)
  const [value, setValue] = useState<ICreatePassword>({
    key: "",
    new_password1: "",
    new_password2: "",
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onCreatePassword(value, setLoading, onClose)
  }

  useEffect(() => {
    if (!!value.new_password2){
      setValid(value.new_password2 !== value.new_password1)
    }
  }, [value.new_password2, value.new_password1])
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={() => {}} isCentered={true} size='xl'>
      <ModalContent bg='#0E0E0E' px={{md: '53px', base: '4'}} pt='42px' pb='66px' rounded='31px'>
        <ModalCloseButton color='#A8A8A8' onClick={onClose}/>
        <ModalHeader textAlign='center' mb='1'>
          <Text
            fontSize='20px'
            fontFamily='Rubik'
            fontWeight='400'
            color='#FFFFFF'
            letterSpacing='-0.41 px'
          >Create new password</Text>
        </ModalHeader>
        <form onSubmit={onSubmit}>
          <ModalInput
            handleChange={handleChange}
            value={value.key}
            type={'text'}
            name={'key'}
            icon={<BiSolidPaste/>}
            placeholder={"Key from email (token)"}
          />
          <ModalInput
            handleChange={handleChange}
            value={value.new_password1}
            type={'password'}
            name={'new_password1'}
            icon={<LockAdminSvg/>}
            placeholder={"New Password"}
          />
          <ModalInput
            handleChange={handleChange}
            value={value.new_password2}
            type={'password'}
            name={'new_password2'}
            icon={<LockAdminSvg/>}
            placeholder={"Confirm password"}
          />
          {validation && <ErrorText>passwords must match</ErrorText>}
          <Box mt='29px'>
            <DefButton isDisabled={validation} isLoading={isLoading} isRound={true} isAdmin={true} type='submit'
            >Login</DefButton>
          </Box>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreatePassword;