export const INTERFACE_WIDTH = '576px'
export const NAVBAR_HEIGHT = '62px'
export const CUSTOM_HEIGHT = '156px'

export const INNER_WIDTH = window.innerWidth

export const fonts_inputs: string[] =
  [
    'Aldrich',
    'Sue Ellen Francisco',
    'Poppins',
    'Comfortaa',
    'Inter',
    'Josefin Sans',
    'Niconne',
    'Cormorant Garamond',
    'Codystar',
    'Coiny',
    'Anton',
    'Emblema One',
    'Monoton',
    'Train One',
    'Allerta Stencil'
  ]







