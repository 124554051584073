import { Box, Image } from "@chakra-ui/react";
import React, { FC, useRef } from "react";
import Slider from "react-slick";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

//local
import { IOurClient } from "../../../../models/ourClientShop";

interface IProps {
  ourClient: IOurClient[];
}

const ClientSlider: FC<IProps> = ({ ourClient }) => {
  const slider = useRef<Slider | null>(null);

  const next = () => {
    if (slider.current) {
      slider.current.slickNext();
    }
  };

  const previous = () => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  };

  const PreviosBtn = () => {
    return (
      <button
        className="button"
        onClick={previous}
        style={{ padding: "0.5rem" }}
      >
        <MdOutlineArrowBackIos />
      </button>
    );
  };

  const NextBtn = () => {
    return (
      <button className="button" onClick={next} style={{ padding: "0.5rem" }}>
        <MdOutlineArrowForwardIos />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 1084,
        settings: {
          slidesToShow: 3,
          rows: 3,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          rows: 4,
        },
      },
    ],
  };
  return (
    <>
      {ourClient.length > 0 && (
        <Box
          py="30px"
          className="our_clients"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxW={{ lg: "1024px", md: "90%", base: "100%" }}
          mx="auto"
        >
          <Box display={{ base: "none", lg: "block" }} zIndex="2">
            <PreviosBtn />
          </Box>
          <Box w={{ base: "100%", md: "90%" }} mx="auto" zIndex="2">
            <Slider ref={slider} {...settings}>
              {ourClient.map((el) => (
                <Box
                  key={el.id}
                  h={{ base: "60px", sm: "80px", md: "101px" }}
                  my="10px"
                >
                  <Image
                    src={el.image}
                    borderRadius="8px"
                    mx="auto"
                    h="100%"
                    key={el.text}
                    alt="Our Client"
                  />
                </Box>
              ))}
              {ourClient.map((el) => (
                <Box
                  key={el.id}
                  h={{ base: "60px", sm: "80px", md: "101px" }}
                  my="10px"
                >
                  <Image
                    src={el.image}
                    borderRadius="8px"
                    mx="auto"
                    h="100%"
                    key={el.text}
                    alt="Our Client"
                  />
                </Box>
              ))}
            </Slider>
            <Box
              display={{ base: "flex", lg: "none" }}
              justifyContent="center"
              alignItems="center"
              zIndex="2"
            >
              <PreviosBtn />
              <NextBtn />
            </Box>
          </Box>
          <Box display={{ base: "none", lg: "block" }} zIndex="2">
            <NextBtn />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ClientSlider;
