import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMenuButtonServer} from "../../../../../models/profile";

interface AddCompanyState {
  isError: string | null;
  isLoading: boolean;
  videos: number[];
  locations: number[];
  images: number[];
  buttons: number[];
  preview: string;
}

const initialState: AddCompanyState  = {
  isError: null,
  isLoading: false,
  images: [],
  buttons: [],
  locations: [],
  videos: [],
  preview: "",
}

export const addCompanySLice = createSlice({
  name: 'company/post',
  initialState,
  reducers: {
    addCompany(state){
      state.isLoading = true;
    },
    addedCompany(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },

    // youtube - videos
    getCompanyVideos(state, action: PayloadAction<number[]>){
      state.videos = action.payload
    },

    // gallery - images
    getCompanyImagesId(state, action: PayloadAction<number>){
      state.images = [action.payload]
    },

    // buttons
    getCompanyButtonsId(state, action: PayloadAction<number>){
      state.buttons.push(action.payload)
    },
    getCompanyButtonsArray(state, action: PayloadAction<IMenuButtonServer[]>){
      state.buttons = action.payload.map(el => el.id)
    },
    removeCompanyButtonId(state, action: PayloadAction<number>){
      state.buttons = state.buttons.filter(el => el !== action.payload)
    },


    // locations
    getCompanyLocationId(state, action: PayloadAction<number>){
      state.locations = [action.payload]
    },

    // main-image
    uploadMainImage(state, action: PayloadAction<string>){
      state.preview = action.payload
    },
    removeImage(state){
      state.preview = ""
    },

    resetLocals(state){
      state.videos = [];
      state.images = [];
      state.buttons = [];
      state.locations = [];
      state.preview = "";
    }
  }
})

export const AddCompanyReducer = addCompanySLice.reducer
export const {
  removeImage,
  uploadMainImage,
  getCompanyVideos,
  getCompanyButtonsArray,
  getCompanyButtonsId,
  removeCompanyButtonId,
  getCompanyImagesId,
  getCompanyLocationId,
  resetLocals,
} = addCompanySLice.actions