import { Box, Text } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

//local
import { useAppSelector } from "../../redux/store";
import { getLocalStorage, useEncryption } from "../../config/helpers";

const MainAnimation: FC = () => {
  const [isOpen, setOpen] = useState<Boolean>(false);
  const { welcome_text } = useAppSelector((state) => state.ProfileReducer.user);
  const [text, font] = useEncryption(getLocalStorage("wlcm") || welcome_text);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2900);
  }, []);
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      w="100%"
      h="100vh"
      zIndex="100"
      display={isOpen ? "none" : "flex"}
      justifyContent="center"
      alignItems="center"
      bg="black"
      color="#C8C8C8"
      className="main-animation"
    >
      <Text
        fontWeight="400"
        letterSpacing="4px"
        fontFamily={font}
        fontSize="calc(18px + 2 * ((100vw - 220px) / (1280 - 320)))"
        className="main-animation__text"
      >
        {text || "Welcome"}
      </Text>
    </Box>
  );
};

export default MainAnimation;
