import React, { useEffect, useRef } from "react";
import { Box, Container, Flex, useOutsideClick } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

//local
import { useMenuBtnAnimation } from "../../redux/menuBtnAnimation/hook";
import {
  CUSTOM_HEIGHT,
  INTERFACE_WIDTH,
  NAVBAR_HEIGHT,
} from "../../config/_variables";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setMenuBtnAnimate } from "../../redux/menuBtnAnimation/action";
import CustomCard from "./CustomCard";
import CustomMenu from "./CustomMenu";
import SaveToPhone from "./SaveToPhone";

const CustomButtons = () => {
  const ref = useRef<any>(null);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { menu_buttons } = useAppSelector((s) => s.MenuButtonsReducer);

  // menu animation parameters
  const { isMenuBtnAnimate: isMenu } = useMenuBtnAnimation();

  const openMenu = () => {
    dispatch(setMenuBtnAnimate(true));
  };

  const closeMenu = () => {
    dispatch(setMenuBtnAnimate(false));
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      window.addEventListener("scroll", openMenu);
      window.addEventListener("click", openMenu);
      return () => {
        window.removeEventListener("scroll", openMenu);
        window.removeEventListener("click", openMenu);
      };
    },
  });

  useEffect(() => {
    if (isMenu) {
      const timoutId = setTimeout(() => {
        closeMenu();
      }, 8000);
      return () => {
        clearTimeout(timoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenu]);

  return (
    <>
      <Flex
        position="fixed"
        zIndex="20"
        bottom={pathname.includes("user") ? "0" : NAVBAR_HEIGHT}
        left="0"
        right="0"
        h={CUSTOM_HEIGHT}
      >
        <Flex
          bg="linear-gradient(#15151500, #000)"
          h="100%"
          mx="auto"
          w={INTERFACE_WIDTH}
          pt="50px"
          pb="20px"
        >
          <Container
            display="flex"
            ref={ref}
            justifyContent={{ sm: "center", base: "space-between" }}
            className={`${!isMenu && "parent"}`}
            alignItems="start"
            maxW="container.xl"
            h="86px"
          >
            {menu_buttons.map((btn, index) => (
              <CustomCard
                key={index}
                btn={btn}
                openMenuButtons={openMenu}
                isMenu={isMenu}
                className={`level${index + 1}`}
                index={index}
              />
            ))}

            <SaveToPhone openMenu={openMenu} isMenu={isMenu} />
            <CustomMenu openMenu={openMenu} isMenu={isMenu} />
          </Container>
        </Flex>
      </Flex>
      <Box h={CUSTOM_HEIGHT} w="full" />
    </>
  );
};

export default CustomButtons;
