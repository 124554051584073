import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProfileState {
  isError: string;
  isLoading: boolean
}

const initialState: ProfileState = {
  isError: '',
  isLoading: false,
}

export const postProfileSlice = createSlice({
  name: 'profile/edit',
  initialState,
  reducers: {
    addProfile(state){
      state.isLoading = true;
    },
    addProfileSuccess(state){
      state.isLoading = false;
    },
    addProfileError(state, action: PayloadAction<string>){
      state.isError = action.payload
      state.isLoading = false
    },
  }
})

export const PostProfileReducer = postProfileSlice.reducer