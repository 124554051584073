import {AppDispatch} from "../../../../../../redux/store";
import {videoSLice} from "./youtube.slice";
import {IVideos} from "../../../../../../models/company";
import PRIVATE_API from "../../../../../../api";
import {toastCreator} from "../../../../../../config/toast";
import toast from "react-hot-toast";
import PRIVATE_API_ADMIN from "../../../../../../api/admin/API";

export const addVideo = ({url, description, id, title}: IVideos, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(videoSLice.actions.addVideos())
    const response = id ?
      await PRIVATE_API.put<IVideos>(`api/v1/users/videos/${id}/`, {title, url, description,}) :
      await PRIVATE_API.post<IVideos>('api/v1/users/videos/', {title, url, description})
    dispatch(videoSLice.actions.addedVideos(null))
    onClose()
    toastCreator('success')
    dispatch(videoSLice.actions.getVideosSuccess(response.data))
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(videoSLice.actions.addedVideos(e.response?.data?.message))
  }
}
export const addVideoAdmin = ({url, description, id, title}: IVideos, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(videoSLice.actions.addVideos())
    const response = id ?
      await PRIVATE_API_ADMIN.put<IVideos>(`api/v1/users/videos/${id}/`, {title, url, description,}) :
      await PRIVATE_API_ADMIN.post<IVideos>('api/v1/users/videos/', {title, url, description})
    dispatch(videoSLice.actions.addedVideos(null))
    onClose()
    toastCreator('success')
    dispatch(videoSLice.actions.getVideosSuccess(response.data))
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(videoSLice.actions.addedVideos(e.response?.data?.message))
  }
}

export const removeVideo = (id: number, inAdmin: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(videoSLice.actions.addVideos())
    inAdmin ? await PRIVATE_API_ADMIN.delete(`api/v1/users/videos/${id}/`) :
    await PRIVATE_API.delete(`api/v1/users/videos/${id}/`)
    dispatch(videoSLice.actions.removeVideo(id))
    dispatch(videoSLice.actions.addedVideos(null))
    toast.success('video removed')
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(videoSLice.actions.addedVideos(e.response?.data?.message))
  }
}

