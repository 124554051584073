import React from "react";
import {Button} from "@chakra-ui/react";

interface Props {
  children: any;
  handleClick?: () => void;
  border?: boolean;
  isRound?: boolean;
  type?: "button" | "submit";
  isLoading?: boolean;
  isDisabled?: boolean;
  isRed?: boolean;
  mb?: number;
  isAdmin?: boolean;
}

const DefButton = ({
                     children,
                     handleClick,
                     border = false,
                     type = "button",
                     isRound = false,
                     isLoading,
                     isDisabled = false,
                     isRed = false,
                     isAdmin = false,
                     mb = 2
                   }: Props) => {
  return (
    <Button
      isLoading={isLoading}
      onClick={handleClick}
      isDisabled={isDisabled}
      type={type}
      w="full"
      h="50px"
      mb={mb}
      mt={isRound ? 4 : 0}
      border={border ? "1px solid #007AFF" : ""}
      bg={border ? "transparent" : isRed ? '#c21515' : "#007AFF"}
      color={border ? "white" : "#FFFFFF"}
      _hover={{bg: "auto"}}
      _active={{bg: "#EDEDF2", color: "#0080DD", border: "none"}}
      rounded={isRound ? isAdmin ? '39px' : "69px" : "8px"}
      fontFamily="Poppins"
      fontWeight="500"
      fontSize="17px"
    >
      {children}
    </Button>
  );
};

export default DefButton;
