import React from 'react';

const QrCodeSvg = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="7" height="7" fill="#4F8BFF"/>
      <rect y="12" width="7" height="7" fill="#4F8BFF"/>
      <rect x="8" y="8" width="5" height="5" fill="#4F8BFF"/>
      <rect x="13" y="13" width="5" height="5" fill="#4F8BFF"/>
      <rect x="12" width="7" height="7" fill="#4F8BFF"/>
    </svg>

  );
};

export default QrCodeSvg;