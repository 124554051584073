import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompany} from "../../../../../models/company";

interface CompanyState {
  companies: any[];
  isError: null | string;
  isLoading: boolean;
  company_to_change: ICompany,
}

const defaultCompany: ICompany = {
  id: 0,
  name: '',
  slogan: '',
  description: '',
  slug: '',
  preview: '',
  is_gradient: false,
  videos: [{url: '', description: '', title: ''}],
  locations: [{map_link: '', city_country: '', street: '', tower_name: '', main_branch: ''}],
  images: [],
  menu_buttons: [],
}

const initialState: CompanyState = {
  companies: [],
  isError: null,
  isLoading: false,
  company_to_change: {...defaultCompany},
}

export const companySlice = createSlice({
  name: "edit/company",
  initialState,
  reducers: {
    getCompanies(state){
      state.isLoading = true;
    },
    getCompaniesSuccess(state, action: PayloadAction<any[]>){
      state.isLoading = false;
      state.companies = action.payload.reverse();
      state.isError = null;
    },
    getCompaniesError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.isError = action.payload;
    },

    getCompanyToChange(state, {payload}: PayloadAction<ICompany>){
      state.company_to_change = {
        ...payload,
        videos: [...payload.videos, ...defaultCompany.videos],
        locations: [...payload.locations, ...defaultCompany.locations]
      };
    },
    resetCompanyToChange(state){
      state.company_to_change = {...defaultCompany};
    },
  }
})

export const CompanyReducer = companySlice.reducer
export const {getCompanyToChange, resetCompanyToChange} = companySlice.actions
