import { Tab, TabIndicator, TabList } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";

//local
import { StatisticType } from "../../../redux/statistics/interfaces";
import { tabList } from "./helpersStatistics";

interface IProps {
  setTypeDate: React.Dispatch<React.SetStateAction<StatisticType>>;
}

class StaticTabMenu extends Component<IProps> {
  render(): ReactNode {
    const { setTypeDate } = this.props;
    return (
      <>
        <TabList display="flex" justifyContent="space-evenly" color="#353535">
          {tabList.map((el, idx) => (
            <Tab
              key={idx}
              px="0"
              _selected={{ color: "#ffffff" }}
              textTransform="capitalize"
              onClick={() => setTypeDate(el)}
            >
              {el}
            </Tab>
          ))}
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="#ffffff"
          borderRadius="1.5px"
        />
      </>
    );
  }
}

export default StaticTabMenu;
