export const PhoneRequiredSvg = () => {
  return (
    <svg
      width="28"
      height="31"
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3077 26.2753C26.3077 26.5753 26.241 26.8837 26.0993 27.1837C25.9577 27.4837 25.7744 27.767 25.5327 28.0337C25.1244 28.4837 24.6743 28.8087 24.166 29.017C23.666 29.2253 23.1244 29.3337 22.541 29.3337C21.691 29.3337 20.7827 29.1337 19.8243 28.7253C18.866 28.317 17.9077 27.767 16.9577 27.0753C15.9993 26.3753 15.091 25.6003 14.2243 24.742C13.366 23.8753 12.591 22.967 11.8993 22.017C11.216 21.067 10.666 20.117 10.266 19.1753C9.86602 18.2253 9.66602 17.317 9.66602 16.4503C9.66602 15.8837 9.76602 15.342 9.96602 14.842C10.166 14.3337 10.4827 13.867 10.9243 13.4503C11.4577 12.9253 12.041 12.667 12.6577 12.667C12.891 12.667 13.1243 12.717 13.3327 12.817C13.5493 12.917 13.741 13.067 13.891 13.2837L15.8243 16.0087C15.9743 16.217 16.0827 16.4087 16.1577 16.592C16.2327 16.767 16.2743 16.942 16.2743 17.1003C16.2743 17.3003 16.216 17.5003 16.0993 17.692C15.991 17.8837 15.8327 18.0837 15.6327 18.2837L14.9993 18.942C14.9077 19.0337 14.866 19.142 14.866 19.2753C14.866 19.342 14.8743 19.4003 14.891 19.467C14.916 19.5337 14.941 19.5837 14.9577 19.6337C15.1077 19.9087 15.366 20.267 15.7327 20.7003C16.1077 21.1337 16.5077 21.5753 16.941 22.017C17.391 22.4587 17.8243 22.867 18.266 23.242C18.6993 23.6087 19.0577 23.8587 19.341 24.0087C19.3827 24.0253 19.4327 24.0503 19.491 24.0753C19.5577 24.1003 19.6243 24.1087 19.6993 24.1087C19.841 24.1087 19.9493 24.0587 20.041 23.967L20.6743 23.342C20.8827 23.1337 21.0827 22.9753 21.2743 22.8753C21.466 22.7587 21.6577 22.7003 21.866 22.7003C22.0243 22.7003 22.191 22.7337 22.3743 22.8087C22.5577 22.8837 22.7494 22.992 22.9577 23.1337L25.716 25.092C25.9327 25.242 26.0827 25.417 26.1743 25.6253C26.2577 25.8337 26.3077 26.042 26.3077 26.2753Z"
        stroke="#4F8BFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5.805 6.95L6.345 7.925L4.26 8.795L6.36 9.65L5.79 10.655L3.975 9.335L4.215 11.6H3.09L3.315 9.335L1.5 10.67L0.915 9.65L3 8.78L0.915 7.94L1.47 6.935L3.33 8.27L3.09 5.99H4.23L3.975 8.27L5.805 6.95Z"
        fill="#D72727"
      />
    </svg>
  );
};
