import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import ProductCard from "./ProductCard";
import { ICategoryShop } from "./CategoriesShopReducer/interfaces";
import { ProductShopCardSkeleton } from "../skeletons";

interface IProps {
  categoryProducts: ICategoryShop;
  isLoading: boolean;
}

const ProductMenu: FC<IProps> = ({ categoryProducts, isLoading }) => {
  const { title, products } = categoryProducts;
  if (!!isLoading) {
    return <ProductShopCardSkeleton />;
  }
  return (
    <>
      {products.length === 0 ? (
        <Text
          textAlign="center"
          color="rgba(255, 255, 255, 1)"
          fontWeight="500"
          my="3rem"
        >
          There are no products in this category
        </Text>
      ) : (
        products.length > 0 && (
          <>
            {title.toLowerCase() === "premium" && (
              <Text
                textAlign="center"
                color="rgba(255, 255, 255, 1)"
                fontWeight="500"
                fontSize={{ base: "24px", md: "32px" }}
                mt="1rem"
              >
                Souvenir products
              </Text>
            )}
            <SimpleGrid
              spacing={{ xl: "46px", md: "30px", base: "24px" }}
              columns={{ lg: 3, md: 2, base: 1 }}
              my="1rem"
              position="relative"
              borderRadius={{ lg: "20px", md: "14px", sm: "8px", base: "6px" }}
              overflow="hidden"
              p={{ lg: "2rem", md: "1.5rem", sm: "1rem", base: "0.8rem" }}
            >
              <Box className="background__gradient"></Box>
              {products.map((el) => (
                <ProductCard product={el} key={el.id} />
              ))}
            </SimpleGrid>
          </>
        )
      )}
    </>
  );
};

export default ProductMenu;
