import React, {useState} from "react";
import {
  Box,
  Drawer, DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure
} from "@chakra-ui/react";
// import { ImArrowDown } from "react-icons/im";
import SaveContactLesson from "../../../assets/gif/SaveContact-Process.gif";
import SaveContactGif from "../../../assets/gif/SaveContact.gif";

//local
import {DefButton, WhiteButton} from "../../ui";
import {API_ADDRESS, PUBLIC_API} from "../../../api";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import ModalMini from "../../ui/modal";
import {downloadFile} from "../../../config/helpers";
import Placement from "../../ui/placement";
import LetMeSaveContact from "../../forms/LetMeSaveContact";
import {useLoginInterface} from "../../../redux/loginFormInterface/hook";
import {setIsExchangeContact} from "../../../redux/loginFormInterface/action";
import {toastCreator} from "../../../config/toast";
import {useAuthUser} from "../../../config/hooks/userAccess";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import TitleComponents from "../../ui/text/TitleComponents";
import {IoCloseOutline} from "react-icons/io5";
import {loadImage} from "../../Cropper/UploadFile-Reducer/actions";

interface Props {
  openMenu: () => void;
  isMenu: boolean;
}

const btn = {
  id: 4,
  icon: <Image src={SaveContactGif} alt="save"/>,
  name: "Save to Phone ",
  className: "level4",
};

const SaveToPhone = ({openMenu, isMenu}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [load, setLoad] = useState<boolean>(false);
  const isAuth = useAuthUser();
  const dispatch = useAppDispatch();

  const {user} = useAppSelector((state) => state.ProfileReducer);
  const {isLoading} = useAppSelector((state) => state.UploadFileReducer);
  const {isExchange} = useLoginInterface();

  const handleClick = () => {
    if (!isAuth) {
      return !isMenu ? openMenu() : onOpen();
    } else openMenu();
  };

  const saveContact = async () => {
    setLoad(true);
    try {
      const {data} = await PUBLIC_API.post(
        `api/v1/users/${user.card_id}/vcard/`
      );
      const url = API_ADDRESS + encodeURIComponent(data.file_url);
      downloadFile(url, data.file_url);
      setLoad(false);
      onClose();
      setTimeout(() => {
        dispatch(setIsExchangeContact(true));
      }, 4000);
    } catch (e: any) {
      setLoad(false);
      toastCreator("error", e.message);
      onClose();
    }
  };
  loadImage(SaveContactLesson as string)
  // useEffect(() => {
    // if (!sessionStorage.getItem('saveContacts') && !!user.card_id && !isLoading) {
      // setTimeout(() => {
       // onOpen()
        // sessionStorage.setItem('saveContacts', 'true')
      // }, 2000)
    // }
  // }, [user.card_id, isLoading])

  return (
    <Flex flexDirection="column" alignItems="center" position="relative">
      <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay bg="#000000B2"/>
        <DrawerContent
          borderTopRadius="35px"
          w={INTERFACE_WIDTH}
          maxH="90vh"
          mx="auto"
          bg='#F8F8F8'
          overflowY='auto'
          className='unscroll'
        >
          <DrawerCloseButton fontSize='1.2rem' top='4' right='4'/>
          <Box px={{sm: '60px', base: '40px'}} py='40px'>
            <Image
              src={SaveContactLesson}
              alt={'Lesson'}
              maxH='434px'
              objectFit='cover'
              w='242px'
              mx='auto'
              rounded='22px'
              boxShadow='0px 4px 4px 0px #00000040'
            />
            <Box mt='49px'>
              <DefButton type='button' handleClick={saveContact} isLoading={load}>GOT IT, ADD TO CONTACS</DefButton>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>


      <Placement
        isOpen={isExchange}
        onClose={() => dispatch(setIsExchangeContact(false))}
        title="Hello"
        children={<LetMeSaveContact/>}
        viewvBtn={true}
        message="Let me save your contact details"
        isMessage={true}
        bg="#161616"
      />

      <Flex
        onClick={handleClick}
        className={`custom-btn ${btn.className}`}
        rounded="50%"
        p="1px"
        mx={{sm: 2, base: "0"}}
        w="64px"
        h="61.43px"
        cursor="pointer"
        bg="linear-gradient(#C8C8C8, #49494990, #29292975, #FFFFFF47)"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          rounded="50%"
          bg="#000"
          _active={{bg: "#171717"}}
          w="100%"
          h="100%"
          color="#FFFFFF"
          fontSize="1.4rem"
          overflow="hidden"
        >
          {!isAuth && isMenu ? (
            <Image
              w="100%"
              h="100%"
              rounded="50%"
              objectFit="cover"
              transform="scale(1.1) rotate(90deg)"
              m="auto"
              src={SaveContactGif}
              alt={"save"}
            />
          ) : (
            ""
          )}
        </Flex>
      </Flex>
      {!isAuth && isMenu && (
        <Text
          transition="0.2s"
          fontFamily="Poppins"
          textAlign="center"
          w="full"
          fontWeight="400"
          mt={2}
          fontSize="10px"
          color="#E1E1E1"
        >
          {btn.name}
        </Text>
      )}
    </Flex>
  );
};

export default SaveToPhone;
