import React, { useState} from 'react';
import {
  Box, Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {EditInput} from "../index";
import {IInputProps} from "./EditInput";
import {SlArrowUp} from "react-icons/sl";
import {fonts_inputs} from "../../../config/_variables";
import {getChoseFont, InputFontsType} from "../../../pages/interface/editing/FontReducer/fonts-slice";
import {useAppDispatch} from "../../../redux/store";

interface Props extends IInputProps {
  name: InputFontsType;
  default_font: string;
}


const EditInputFont = ({name, placeholder, value, handleChange, required, validation, cleaner, default_font}: Props) => {
  const dispatch = useAppDispatch()
  const [font, setFont] = useState(default_font)

  const handleClick = (font: string) => {
    setFont(font)
    dispatch(getChoseFont({name, font}))
  }

  return (
    <Flex justifyContent='space-between'>
      <Box w='80%'>
        <EditInput
          name={name}
          validation={validation}
          placeholder={placeholder}
          value={value}
          handleChange={handleChange}
          cleaner={cleaner}
          font={font}
          required={required}
        /></Box>
      <Box w={4}/>
      <Menu>
        <MenuButton
          as={Button} rightIcon={<SlArrowUp/>}
          justifyContent='space-between'
          alignItems='center'
          h='56px' w='95px' cursor='pointer'
          px={3.5} variant='none'
          borderTopRadius='4px' py='1px'
          bg={'#2E2E2E62'} _hover={{bg: '#2E2E2EB2'}}
          fontSize='16px' letterSpacing='0.5px'
          fontWeight='400' fontFamily={font}
          color={'#3497DF'}
        >
          Font
        </MenuButton>
        <MenuList bg='#2E2E2E' h='314px' w='255px' overflowY='auto' className='scroll' border='none' zIndex='40'>
          {fonts_inputs.map((f, idx) => (
            <MenuItem
              onClick={() => handleClick(f)}
              key={idx} px={4} py='2.5'
              bg={font === f ? 'whiteAlpha.600' : '#2E2E2E'}
              noOfLines={1} textAlign='start' display='flex' justifyContent='start'
              fontSize='16px' letterSpacing='0.5px'
              fontWeight='400' fontFamily={f}
              cursor='pointer' _active={{bg: '#2E2E2E'}}
              lineHeight='24px' color={font === f ? '#3497DF' : '#FFFFFF'}
            >{f}</MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default EditInputFont;