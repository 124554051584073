import React, { FC } from "react";
import { Flex, Text } from "@chakra-ui/react";

//local
import { useAppSelector } from "../../../redux/store";

const EditPart: FC = () => {
  const {
    user: { full_name, designation, vip },
  } = useAppSelector((state) => state.ProfileReducer);
  const { full_name_font } = useAppSelector((state) => state.FontReducer);

  return (
    <Flex w="100%" flexDirection="column" alignItems="center" py={1}>
      <Flex flexDirection="column" alignItems="center" fontFamily="Poppins">
        <Text
          color="#C8C8C8"
          fontSize="16px"
          fontWeight="700"
          fontFamily={full_name_font}
          letterSpacing="5%"
        >
          {full_name}
        </Text>
        <Text
          mt="10px"
          color="#C8C8C8"
          fontSize="12px"
          fontFamily="Poppins"
          fontWeight="300"
          lineHeight="22.5px"
          letterSpacing="1px"
        >
          {designation}
        </Text>
        {!!vip && (
          <Text
            mt="10px"
            color="#000"
            fontStyle="italic"
            fontSize="12px"
            fontWeight="600"
            letterSpacing="5%"
            bg="#fff"
            padding="5px 10px"
            rounded="2xl"
          >
            {vip}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default EditPart;
