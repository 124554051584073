import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

interface Props {
  icon: JSX.Element;
  name: string;
  handleClick?: () => void;
}
const MenuBtn = ({ icon, name, handleClick }: Props) => {
  return (
    <Flex
      onClick={handleClick}
      cursor="pointer"
      flexDirection="column"
      justifyContent="center"
      color="#4F8BFF"
      alignItems="center"
      w="63px"
      h="63px"
      rounded="5px"
      bg="#191919"
      _active={{ bg: "#112645" }}
      mb="6px"
    >
      <Box fontSize="1.7rem">{icon}</Box>
      <Text
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="11px"
        textAlign="center"
        lineHeight="16.5px"
      >
        {name}
      </Text>
    </Flex>
  );
};

export default MenuBtn;
