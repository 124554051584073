import React, { useState, FC, useEffect } from "react";
import { Box } from "@chakra-ui/layout";
import { useLocation } from "react-router-dom";

//local dependencies
import CompanyInfo from "./CompanyInfo";
import { ICompany } from "../../../../models/company";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {getCompanyById, getCorpCompany} from "../../editing/Company/companyReducer/actions";
import CompanyModal from "./CompanyModal";

type IProps = {
  companies: ICompany[];
};

const CompanyTab: FC<IProps> = ({ companies }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>(companies[0]?.id);
  const [slug, setSlug] = useState<string | undefined>(companies[0]?.slug);

  const { user } = useAppSelector((state) => state.ProfileReducer);

  const handleNext = () => {
    if (selectedCompany < companies.length - 1) {
      const index = selectedCompany + 1;
      setIdx(index);
    }
  };

  const handlePrev = () => {
    if (selectedCompany > 0) {
      const index = selectedCompany - 1;
      setIdx(index);
    }
  };

  const setIdx = (index: number) => {
    setSelectedCompany(index);
    setCompanyId(companies[index]?.id);
    setSlug(companies[index]?.slug)
  };

  useEffect(() => {
    // setSlug(companies[0]?.slug)
    if (!!slug){
      dispatch(getCorpCompany(slug))
    } else {
      companyId > 0 &&
      user.card_id &&
      dispatch(getCompanyById(user.card_id, companyId));
    }
  }, [dispatch, user.card_id, companyId, slug]);
  
  useEffect(()=> {
    if(companies){
      setSlug(companies[0]?.slug)
      setCompanyId(companies[0]?.id)
    }

  },[companies])

  return (
    <Box bg="#000" mt="1.5rem" borderRadius="12px" overflow="hidden">
      {!pathname.includes("user") && <CompanyModal />}
      <CompanyInfo
        next={selectedCompany === companies.length - 1}
        prev={selectedCompany === 0}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    </Box>
  );
};

export default CompanyTab;
