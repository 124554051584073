import React from 'react';
import {IInputProps} from "./EditInput";
import {Box, Input, InputGroup, InputLeftElement, Textarea} from "@chakra-ui/react";
import {ErrorText} from "../index";

interface Props extends IInputProps {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  leftIcon?: JSX.Element;
  as?: "input" | "textarea";
}

const PlacementInput = ({
                          name,
                          placeholder,
                          value,
                          handleChange,
                          cleaner,
                          error,
                          leftIcon,
                          as = "input",
                          type = "text",
                          required = true,
                        }: Props) => {
  return (
    <Box mb='18px' w='100%'>
      {as === "input" && <InputGroup>
        {type === "url" &&
          <InputLeftElement width='4rem' display='flex' justifyContent='end' alignItems='center' h='full'>
            <Box
              color='#C8C8C8' fontSize='1.6rem' pr={4} cursor='pointer' borderRight='1px solid #A8A8A8'
            >{leftIcon}</Box>
          </InputLeftElement>}
        <Input
          onChange={handleChange}
          value={value}
          name={name}
          type={type}
          placeholder={placeholder}
          h='56px' w='100%'
          rounded='39px' pl={type === "url" ? '4.5rem' : 6} pr={6}
          border='none' bg={'#75757540'}
          fontSize={type === "url" ? "18px" : '16px'}
          letterSpacing='0.5px'
          fontWeight='400' fontFamily={"Poppins"}
          lineHeight='24px' color={type === 'url' ? '#0080DD' : '#FFFFFF'}
          _placeholder={{
            color: type === "url" ? '#0080DD' : '#787878',
            fontSize: type === "url" ? '18px' : '15px',
            textAlign: type === "url" ? 'center' : 'left'
          }}
          isRequired={required}
        />
      </InputGroup>}
      {as === "textarea" && <Textarea
        onChange={handleChange}
        value={value}
        name={name}
        placeholder={placeholder}
        className='unscroll'
        h='100px' w='100%'
        rounded='14px' pl={type === "url" ? '4.5rem' : 6} pr={6}
        border='none' bg={'#75757540'}
        fontSize={type === "url" ? "18px" : '16px'}
        letterSpacing='0.5px'
        fontWeight='400' fontFamily={"Poppins"}
        lineHeight='24px' color={type === 'url' ? '#0080DD' : '#FFFFFF'}
        _placeholder={{
          color: type === "url" ? '#0080DD' : '#787878',
          fontSize: type === "url" ? '18px' : '15px',
          textAlign: type === "url" ? 'center' : 'left'
        }}
        isRequired={required}
      />}
      <Box px={2}><ErrorText>{error}</ErrorText></Box>
    </Box>
  );
};

export default PlacementInput;