import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import SkeletonCompany from "./SkeletonCompany";
import SkeletonBtns from "./SkeletonBtns";

const SkeletonCompanyList: FC = () => {
  return (
    <Box pt="10px">
      <SkeletonCompany />
      <SkeletonBtns />
    </Box>
  );
};

export default SkeletonCompanyList;
