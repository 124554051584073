import React from 'react';
import {Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import {FiMoreHorizontal} from "react-icons/fi";
import {useCompanyDetail} from "../../editing/Company/companyReducer/hooks";
import ModalMini from "../../../../components/ui/modal";
import {DefButton} from "../../../../components/ui";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {useNavigate} from "react-router-dom";
import {copyCompanyToChange, deleteCompany} from "../../editing/Company/companyReducer/actions";


const CompanyModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {isLoading, company} = useCompanyDetail();
  const {onClose, isOpen, onOpen} = useDisclosure()
  const {user} = useAppSelector((state) => state.ProfileReducer);
  const removeCompany = () => {
    dispatch(deleteCompany(user.card_id, company.id))
    onClose()
  }
  const toEdit = () => {
    console.log(122)
    dispatch(copyCompanyToChange(company))
    navigate('/edit/company')
  }

  return (
    <Flex w='100%' justifyContent='end' alignItems='center' px="1.4rem" mb='1.5'>
      {!company.slug && <Menu>
        {!isLoading && <Tooltip label='More'>
          <MenuButton
            color='white' alignItems='center' fontSize='2.6rem' h='30px' cursor='pointer'
            _active={{color: '#FFFFFFB2'}}
          >
            <FiMoreHorizontal/>
          </MenuButton>
        </Tooltip>}


        <MenuList bg='#2E2E2E' w='255px' border='none' zIndex='20'>
          <MenuItem
            onClick={toEdit}
            px={4} py='2.5'
            bg='#2E2E2E'
            noOfLines={1} textAlign='start' display='flex' justifyContent='start'
            fontSize='16px' letterSpacing='0.5px'
            fontWeight='400' fontFamily='Poppins'
            borderBottom='1px solid #494949'
            cursor='pointer' _active={{bg: 'whiteAlpha.600'}}
            lineHeight='24px' color={'#FFFFFF'}
          >Edit</MenuItem>
          <MenuItem
            onClick={onOpen}
            px={4} py='2.5' bg='#2E2E2E'
            noOfLines={1} textAlign='start' display='flex' justifyContent='start'
            fontSize='16px' letterSpacing='0.5px'
            fontWeight='400' fontFamily='Poppins'
            borderBottom='1px solid #494949'
            cursor='pointer' _active={{bg: 'whiteAlpha.600'}}
            lineHeight='24px' color={'#EF0303'}
          >delete company</MenuItem>
        </MenuList>
      </Menu>}


      <ModalMini isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection='column' alignItems='center'>
          <Text color='#FFFFFF' fontWeight='400' mt={3} fontSize='15px' lineHeight='22.5px' textAlign='center'
                w='70%'>do you really want to delete the company?</Text>
          <Flex mt='32px' w='full'>
            <DefButton handleClick={onClose} border={true}>Cancel</DefButton>
            <Box w='20px'/>
            <DefButton handleClick={removeCompany} isRed={true}>Delete</DefButton>
          </Flex>
        </Flex>
      </ModalMini>
    </Flex>
  );
};

export default CompanyModal;