export const ArrowRightSvg = () => {
  return (
    <svg
      width="17"
      height="37"
      viewBox="0 0 17 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H4L15.5 18.5L5 36.5H2L12.5 18.5L1 1Z"
        fill="black"
        stroke="white"
      />
    </svg>
  );
};
