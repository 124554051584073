import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProductCart, IProductShop} from "../../../../models/products";
import {toLocalStorage} from "../../../../config/helpers";

interface ICartState {
  isLoading: boolean;
  isError: string | null;
  cart: IProductCart[]
}

const initialState: ICartState = {
  isError: null,
  isLoading: false,
  cart: JSON.parse(localStorage.getItem('cart') as string) || []
}

export const cartSlice = createSlice({
  name: 'cart/shop',
  initialState,
  reducers: {
    addCart(state, action: PayloadAction<IProductShop>){
      state.cart.push({...action.payload, quantity: 1})
      toLocalStorage('cart', JSON.stringify(state.cart))
    },
    removeCart(state, {payload}: PayloadAction<number>){
      state.cart = state.cart.filter(el => el.id !== payload)
      toLocalStorage('cart', JSON.stringify(state.cart))
    },
    increaseQuantity(state, {payload}: PayloadAction<number>){
      state.cart = state.cart.map(el => el.id === payload ? {...el, quantity: el.quantity + 1} : el)
      toLocalStorage('cart', JSON.stringify(state.cart))
    },
    decreaseQuantity(state, {payload}: PayloadAction<number>){
      state.cart = state.cart.map(el => {
        if (el.id === payload && el.quantity > 1){
          return {...el, quantity: el.quantity - 1}
        }else return el
      })
      toLocalStorage('cart', JSON.stringify(state.cart))
    }
  }
})

export const CartShopReducer = cartSlice.reducer

export const {addCart, removeCart, increaseQuantity, decreaseQuantity} = cartSlice.actions