import { Box, Heading } from "@chakra-ui/react";
import React, { FC } from "react";

const Locations: FC = () => {
  return (
    <Box
      position="relative"
      maxW={{
        base: "100%",
        md: "95%",
        "2xl": "1360px",
      }}
      pt={{ base: "80px", md: "20px" }}
      mx="auto"
    >
      <Heading
        mb={"30px"}
        color={"rgba(255, 255, 255, 1)"}
        fontSize={"36px"}
        fontFamily={"Poppins"}
        fontWeight="400"
        textAlign={{ base: "center", md: "left" }}
      >
        Our locations
      </Heading>
      <Box
        h={"460"}
        borderRadius={{ base: "0", md: "10px", xl: "20px" }}
        overflow="hidden"
        mb={{ base: "30px", md: "100px" }}
      >
        <iframe
          src="https://www.google.com/maps/d/embed?mid=17tnytwJTWiOWQyGJZHPC8fJDBlggnLU&ehbc=2E312F"
          width={"100%"}
          height={"100%"}
          title="Our locations"
        />
      </Box>
    </Box>
  );
};

export default Locations;
