export const URL_CHAT_GPT = "https://chat.openai.com/auth/login?next=/chat";

export const toEncryption = (text: string, font: string) => {
  return `${text}#${font}`;
};

export function useEncryption(data: string) {
  const array = data.split("#");
  const text = array.length > 1 ? array.slice(0, -1).join("") : array.join('');
  const font = array.length > 1 ? array[array.length - 1] : "";
  return [text, font];
}

export function toLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key: string): string {
  return localStorage.getItem(key) || "";
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}


export function getFileName(filePath: string): string {
  // Удалите все символы перед последним символом '/'
  const lastIndex = filePath.lastIndexOf('/');
  if (lastIndex !== -1) {
    return filePath.substring(lastIndex + 1);
  } else {
    // Если символ '/' не найден, возвращаем оригинальную строку
    return filePath;
  }
}

export function getYouTubeVideoId(url: string) {
  let videoId = "";

  // Regular expression to match YouTube video URLs in various formats
  const patterns = [
    /youtu\.be\/([A-Za-z0-9_-]+)/, // Shortened URL
    /[?&]v=([A-Za-z0-9_-]+)/, // Regular URL with "v="
    /\/embed\/([A-Za-z0-9_-]+)/, // URL with "/embed/"
    /\/v\/([A-Za-z0-9_-]+)/, // URL with "/v/"
    /\/watch\?v=([A-Za-z0-9_-]+)/, // URL with "/watch?v="
    /\/watch\?.*?v=([A-Za-z0-9_-]+)/, // URL with "/watch?" and "v="
    /\/(?:embed|v)\/([A-Za-z0-9_-]+)(?:\?|&|$)/, // Embed or v parameter
  ];

  // Iterate through patterns to find a match
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      videoId = match[1];
      break;
    }
  }

  return videoId;
}

export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFormatDate = (date: Date) => {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = _date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1
  const year = _date.getFullYear();
  return `${day.toString().padStart(2, "0")}.${month
    .toString()
    .padStart(2, "0")}.${year}`;
};

export const createVcfFile = (name: string, contact: string, email: string) => {
  // Создаем текст VCF файла
  const vcfText = `BEGIN:VCARD
VERSION:3.0
EMAIL:${email}
FN:${name}
TEL:${contact}
END:VCARD`;

  // Создаем Blob из текста VCF
  const blob = new Blob([vcfText], {type: "text/vcard"});

  // Создаем ссылку для скачивания файла
  const url = window.URL.createObjectURL(blob);

  downloadFile(url, `${name + contact}.vcf`);
};

export const navigateToUrl = (url: string, isMobile?: boolean) => {
  if (isMobile) {
    window.open(url, "_self");
  } else {
    window.open(url, "_blank");
  }
};

export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
};

export const allocateText = (title: string, search: string): string[] => {
  let array: string[] = [];
  if (title.toLowerCase().includes(search)) {
    const index = title.toLowerCase().replace(search, "&").split("").indexOf("&");
    array[0] = title.slice(0, index);
    array[1] = search;
    array[2] = title.slice(index + search.length);
  } else {
    array[0] = title;
  }

  return array.filter((el) => !!el);
};

export function changeTitle(newTitle: string) {
  if (newTitle.length > 0) {
    const array = newTitle.split("#");
    const text = array.slice(0, -1).join("");
    document.title = text;
  }
}
