import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/react";

//local dependencies
import { Containers } from "../ui";
import { NFCLogoSvg } from "../../assets/svg/NFCLogoSvg";
import { WhatsappSvg } from "../../assets/svg/WhatsappSvg";
import { YoutubeIconSvg } from "../../assets/svg/YoutubeIconSvg";
import { TelegramIconSvg } from "../../assets/svg/TelegramIconSvg";
import { TikTotIconSvg } from "../../assets/svg/TikTotIconSvg";
import { InstagramIconSvg } from "../../assets/svg/InstagramIconSvg";
import { TELL_NUMBER } from "../../config/admin";

const Footer: FC = () => {
  const messengersList = [
    {
      icon: <InstagramIconSvg />,
      link: "https://www.instagram.com/nfc_business_card",
    },
    {
      icon: <TikTotIconSvg />,
      link: "https://www.tiktok.com/@nfc_business_cards",
    },
    {
      icon: <TelegramIconSvg />,
      link: "https://t.me/nfc_gcc",
    },
    {
      icon: <WhatsappSvg color="#ffffff" />,
      link: `https://wa.me/${TELL_NUMBER}`,
    },
    {
      icon: <YoutubeIconSvg />,
      link: "https://www.youtube.com/@NFC-NetworkFutureConcept",
    },
  ];
  return (
    <footer id="footer">
      <Containers>
        <Box py="20px" w="100%" fontFamily="Poppins" color="#B3B3B3">
          <Box display="flex" justifyContent="center">
            <NFCLogoSvg />
          </Box>
          <Text
            textAlign="center"
            my="25px"
            maxW="524px"
            mx="auto"
            fontWeight="400"
            fontSize="14px"
          >
            Head Office: The Opus - C-101 - Office W4 - Al Amal St - Business
            Bay - Dubai - UAE
          </Text>
          <Box
            maxW="260px"
            mx="auto"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            py="10px"
          >
            {messengersList.map((el, idx) => (
              <Box
                key={idx}
                w="40px"
                h="40px"
                bg="#31312F"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                borderRadius="50%"
                transition=".4s"
                _hover={{
                  boxShadow: "0 0 25px #0b98ff",
                  background: "rgba(36, 93, 206, 1)",
                }}
                onClick={() => window.open(el.link)}
              >
                {el.icon}
              </Box>
            ))}
          </Box>
          <Box my="20px" maxW="451px" mx="auto" h="2px" bg="#232323" />
          <Box fontWeight="400">
            <Text
              fontSize="8px"
              lineHeight="19px"
              letterSpacing="2px"
              textAlign="center"
            >
              © 2023 NFC - Network. Future. Concept. All rights reserved
            </Text>
          </Box>
        </Box>
      </Containers>
    </footer>
  );
};

export default Footer;
