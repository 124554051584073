import React from 'react';

const MenuLeadsSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.25 12C15.0114 12 17.25 9.76142 17.25 7C17.25 4.23858 15.0114 2 12.25 2C9.48858 2 7.25 4.23858 7.25 7C7.25 9.76142 9.48858 12 12.25 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.66016 22C3.66016 18.13 7.51018 15 12.2502 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.45 21.4C20.2173 21.4 21.65 19.9673 21.65 18.2C21.65 16.4327 20.2173 15 18.45 15C16.6827 15 15.25 16.4327 15.25 18.2C15.25 19.9673 16.6827 21.4 18.45 21.4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.25 22L21.25 21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default MenuLeadsSvg;