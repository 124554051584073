import React from 'react';

const UploadSvg = () => {
  return (
    <svg width="35" height="38" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.63498 12.5427L16.8803 0.243434C17.2298 -0.0811448 17.7706 -0.0811446 18.1201 0.243434L31.3654 12.5427C31.9722 13.1061 31.5735 14.1213 30.7455 14.1213H25.0821C24.5789 14.1213 24.1711 14.5292 24.1711 15.0323V27.6638C24.1711 28.1669 23.7632 28.5748 23.26 28.5748H11.7404C11.2373 28.5748 10.8294 28.1669 10.8294 27.6638V15.0323C10.8294 14.5292 10.4215 14.1213 9.91833 14.1213H4.25489C3.42688 14.1213 3.02822 13.1061 3.63498 12.5427ZM1.93491 34.9887C1.18018 34.9887 0.568359 35.6005 0.568359 36.3553C0.568359 37.11 1.18018 37.7218 1.93491 37.7218H33.0655C33.8202 37.7218 34.4321 37.11 34.4321 36.3553C34.4321 35.6005 33.8202 34.9887 33.0655 34.9887H1.93491Z" fill="#8A8A8A"/>
    </svg>

  );
};

export default UploadSvg;