import React from 'react';
import {Box, Flex, Skeleton, SkeletonCircle, SkeletonText} from "@chakra-ui/react";

const DetailSkeleton = () => {
  return (
    <Box borderTopRadius='25px' pt='30px' minH='100%' px='2.5'>
      <Flex px={6} py={4} justifyContent='space-between' alignItems='start' boxShadow='lg' bg='#FFFFFF1A' rounded='8px'>
        <SkeletonCircle size='20' mt={2} mr={4}/>
        <SkeletonText w='80%' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
      </Flex>
      <Skeleton h='230px' bg='#FFFFFF1A' mt={4} rounded='8px'/>
      <Box px={2} py={4} boxShadow='lg' mt={4}  rounded='8px'>
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
      </Box>
    </Box>
  );
};

export default DetailSkeleton;