import { Box, Flex } from "@chakra-ui/react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

//local
import { StoreBasketSvg } from "../../assets/svg/StoreBasketSvg";
import { useAppSelector } from "../../redux/store";

interface IProps {
  onClose?: () => void;
}

const BasketNav: FC<IProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { cart } = useAppSelector((state) => state.CartShopReducer);
  return (
    <Box
      position="relative"
      w="40px"
      h="40px"
      mx={{ md: "10px" }}
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => {
        navigate("/basket");
        onClose && onClose();
      }}
    >
      {!!cart.length && (
        <Flex
          position="absolute"
          top={"1px"}
          left={"3.5px"}
          justifyContent="center"
          alignItems="center"
          w="20px"
          h="20px"
          bg="red"
          rounded="50%"
          color="white"
          fontFamily="Poppins"
          fontSize="12px"
        >
          {cart.length}
        </Flex>
      )}
      <Box
        display="flex"
        bg="transparent"
        alignItems="center"
        justifyContent="center"
        className="nav_active"
      >
        <StoreBasketSvg />
      </Box>
    </Box>
  );
};

export default BasketNav;
