import {AppDispatch} from "../../../../../redux/store";
import {callbackSlice} from "./slice";
import {PUBLIC_API} from "../../../../../api";
import {toastCreator} from "../../../../../config/toast";

export interface ICallbackData  {
  full_name: string;
  phone_number: string;
  email: string;
  message: string;
}

export const sendCallback = (data: ICallbackData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(callbackSlice.actions.loadingCallback())
    await PUBLIC_API.post('api/v1/shop/callback/', data)
    dispatch(callbackSlice.actions.sendCallback(null))
    toastCreator('success', 'Your application has been submitted, well be in touch with you soon!')
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(callbackSlice.actions.sendCallback(e.response?.data?.message))
  }
}