import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";

//local
import { DefButton, PlacementInput } from "../ui";
import { PUBLIC_API } from "../../api";
import { logout } from "../../config/helpers";
import { toastCreator } from "../../config/toast";

type Props = {
  rpt: string;
  uci: string;
};

interface IPassword {
  password: string;
  confirm_password: string;
}

const ResetPassword: FC<Props> = ({ rpt, uci }) => {
  const [load, setLoad] = useState<boolean>(false);
  const [errText, setErrText] = useState<string>("");

  //инициализация состояние пароля и подтверждение пароля
  const [resetPsw, setResetPsw] = useState<IPassword>({
    password: "",
    confirm_password: "",
  });

  //обработчик изменений полей ввода пароля
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setResetPsw({ ...resetPsw, [event.target.name]: event.target.value });
  };

  //обработчик отправки формы
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (resetPsw.password === resetPsw.confirm_password) {
      setLoad(true);
      PUBLIC_API.post(`api/v1/users/profile/reset/?rpt=${rpt}&&uci=${uci}`, {
        password: resetPsw.password,
      })
        .then((res) => {
          setLoad(false);
          logout();
          window.location.replace(`/user/${uci}`);
        })
        .catch((e) => {
          setLoad(false);
          toastCreator("error", `${e.response.data?.detail + " " + e.message}`);
        });
    } else {
      setErrText("Password mismatch");
    }
  };

  //следящий за изменениями паролей и сравнивающий их для установки текста ошибки
  useEffect(() => {
    if (resetPsw.password === resetPsw.confirm_password) {
      setErrText("");
    } else {
      setErrText("Password mismatch");
    }
  }, [resetPsw.confirm_password, resetPsw.password]);

  return (
    <form onSubmit={onSubmit} style={{ paddingBottom: "1.6rem" }}>
      <PlacementInput
        placeholder="New Password"
        name="password"
        value={resetPsw.password}
        handleChange={handleChange}
        cleaner={() => {}}
      />
      <PlacementInput
        placeholder="Confirm Password"
        name="confirm_password"
        value={resetPsw.confirm_password}
        handleChange={handleChange}
        error={errText}
        cleaner={() => {}}
      />
      <DefButton isRound={true} type="submit" isLoading={load}>
        Save
      </DefButton>
    </form>
  );
};

export default ResetPassword;
