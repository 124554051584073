import React from 'react';

const TwitterSvg = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8805 7.12349L12.9326 8.03832L12.0649 7.9263C8.90649 7.49689 6.14724 6.04063 3.80447 3.59487L2.65912 2.38132L2.3641 3.27748C1.73936 5.27517 2.1385 7.38487 3.44004 8.80379C4.13419 9.58793 3.97801 9.69995 2.78059 9.2332C2.3641 9.08384 1.99967 8.97182 1.96496 9.02783C1.84349 9.15852 2.25998 10.8575 2.5897 11.5296C3.0409 12.4631 3.96065 13.3779 4.96718 13.9194L5.81751 14.3488L4.81099 14.3674C3.83918 14.3674 3.80447 14.3861 3.90859 14.7782C4.25567 15.9917 5.62662 17.2799 7.15376 17.84L8.22969 18.2321L7.29259 18.8296C5.90428 19.6884 4.27302 20.1738 2.64176 20.2111C1.86084 20.2298 1.21875 20.3045 1.21875 20.3605C1.21875 20.5472 3.33592 21.5927 4.56804 22.0034C8.2644 23.217 12.6549 22.6942 15.9521 20.6219C18.2949 19.1469 20.6377 16.2158 21.731 13.3779C22.321 11.8657 22.911 9.10251 22.911 7.77694C22.911 6.91812 22.9631 6.8061 23.9349 5.77925C24.5076 5.18182 25.0455 4.52837 25.1497 4.34167C25.3232 3.98694 25.3059 3.98694 24.4208 4.30433C22.9457 4.86443 22.7375 4.78975 23.4664 3.9496C24.0043 3.35216 24.6464 2.2693 24.6464 1.95191C24.6464 1.8959 24.3861 1.98925 24.0911 2.15728C23.7787 2.34398 23.0846 2.62403 22.564 2.79206L21.6268 3.10945L20.7765 2.49334C20.308 2.15728 19.6485 1.78388 19.3014 1.67186C18.4164 1.41048 17.0628 1.44782 16.2645 1.74654C14.0953 2.58669 12.7243 4.75241 12.8805 7.12349Z"
            stroke="#FFFFFF"/>
    </svg>
  );
};

export default TwitterSvg;