import axios, { AxiosInstance } from "axios";
import {onLogoutAdmin} from "../../pages/admin/AuthActions";
import {API_ADDRESS} from "../index";



//Axios-инстанс для приватных запросов
const PRIVATE_API_ADMIN: AxiosInstance = axios.create({
  baseURL: API_ADDRESS,
});

//интерцепторы для приватных запросов перед отправкой запроса
PRIVATE_API_ADMIN.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Token ${sessionStorage.getItem("accessToken")}`;
  return config;
});

//интерцепторы для приватных запросов после получения ответа
PRIVATE_API_ADMIN.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !err.config._isRetry) {
      // помечаем запрос как повторный, чтобы избежать бесконечных циклов
      originalRequest._isRetry = true;
      try {
        // отправляем запрос на обновление access токена с помощью refresh-токена
        const { data } = await axios.post(
          `${API_ADDRESS}api/v1/corp/auth/refresh/`,
          {
            refresh: sessionStorage.getItem("refreshToken"),
          }
        );
        // обновляем токен доступа в локальном хранилище
        sessionStorage.setItem("accessToken", data.access);
        // повторно отправляем исходный запрос
        return PRIVATE_API_ADMIN.request(originalRequest);
      } catch (e) {
        onLogoutAdmin()
        return Promise.reject(e);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export default PRIVATE_API_ADMIN;
