import React, { ChangeEvent, FormEvent, useState } from "react";

//local
import InputStore from "../ui/InputStore";
import SubmitButton from "../ui/SubmitButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ICallbackData, sendCallback } from "./CallbackReducer/actions";

interface IProps {
  isVisible?: boolean;
}

const CallbackForm = ({ isVisible = false }: IProps) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.CallbackShopReducer);
  const [value, setValue] = useState<ICallbackData>({
    full_name: "",
    phone_number: "",
    email: "",
    message: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(sendCallback(value));
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputStore
        value={value.full_name}
        name="full_name"
        placeholder={"Name"}
        handleChange={handleChange}
      />
      <InputStore
        value={value.phone_number}
        name="phone_number"
        type="tel"
        placeholder={"Phone number"}
        handleChange={handleChange}
      />
      {!!isVisible ? (
        ""
      ) : (
        <>
          <InputStore
            isRequired={false}
            value={value.email}
            type="email"
            name="email"
            placeholder={"Email"}
            handleChange={handleChange}
          />
          <InputStore
            isRequired={false}
            as="textarea"
            value={value.message}
            name="message"
            placeholder={"Message"}
            handleChange={handleChange}
          />
        </>
      )}
      <SubmitButton isLoading={isLoading} type={"submit"}>
        Send
      </SubmitButton>
    </form>
  );
};

export default CallbackForm;
