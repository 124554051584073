import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMember, IMemberCategory, RootMember} from "../../../../models/member";

interface MemberState {
  members: RootMember,
  data: RootMember,
  categories: IMemberCategory[];
  isLoading: boolean;
  isError: null | string;
  search_text: string;
}

export interface SearchPayload {
  data: RootMember,
  search: string,
}

const initialState: MemberState = {
  members: [],
  data: [],
  categories: [],
  isLoading: false,
  isError: null,
  search_text: ""
}

export const memberSlice = createSlice({
  name: 'members/companies',
  initialState,
  reducers: {
    getMembers(state) {
      state.isLoading = true
    },
    getMembersSuccess(state, action: PayloadAction<RootMember>) {
      state.isLoading = false
      state.data = action.payload
      state.members = action.payload
    },
    getCategories(state, action: PayloadAction<IMemberCategory[]>) {
      state.isLoading = false
      state.categories = action.payload
      state.isError = null
    },
    getSearchResult(state, {payload}: PayloadAction<SearchPayload>){
      state.members = payload.data;
      state.search_text = payload.search
    },
    getMembersError(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.isError = action.payload
    },
    filterMembers(state, action: PayloadAction<string>) {
      if (!!action.payload) {
        state.members = state.data.filter(el => el.category.title === action.payload)
      }else {
        state.members = state.data
      }
    },
  }
})

export const MemberReducer = memberSlice.reducer
export const {filterMembers} = memberSlice.actions