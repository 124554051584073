import React, {useState} from 'react';
import {Box, Flex, useDisclosure} from "@chakra-ui/react";
import {AiOutlinePlus} from "react-icons/ai";
import {DefButton, PlacementInput} from "../../../../../components/ui";
import Placement from "../../../../../components/ui/placement";
import {TfiLocationPin} from "react-icons/tfi";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store";
import {ILocation} from "../../../../../models/company";
import {addLocations, addLocationsAdmin} from "./reducer/actions";

interface Props {
  inAdmin: boolean
}

const Location = ({inAdmin}: Props) => {
  const dispatch = useAppDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {isLoading} = useAppSelector(state => state.LocationReducer)
  const {locations} = useAppSelector(
    state => state.CompanyReducer.company_to_change
  )
  const [value, setValue] = useState<ILocation>(locations[0])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const cleaner = () => {
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (inAdmin) {
      dispatch(addLocationsAdmin(value, onClose))
    } else {
      dispatch(addLocations(value, onClose))
    }
  }

  return (
    <>
      <DefButton handleClick={onOpen}>
        <Box fontSize='1.2rem' mr={2}>
          <AiOutlinePlus/>
        </Box>
        Location
      </DefButton>
      <Flex hidden={!isOpen} position='fixed' left='0' right='0' top='0' bottom='0' bg='#000000B2'/>
      <Placement isOpen={isOpen} onClose={onClose} title={"Location"}>
        <form onSubmit={onSubmit}>
          <PlacementInput
            handleChange={handleChange}
            value={value.main_branch}
            name={"main_branch"}
            placeholder={"Office-Shop-Main branch"}
            cleaner={cleaner}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.tower_name}
            name={"tower_name"}
            placeholder={"Tower name & Unit No "}
            cleaner={cleaner}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.street}
            name={"street"}
            placeholder={"Street / Area - Business Bay "}
            cleaner={cleaner}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.city_country}
            name={"city_country"}
            placeholder={"City/Country-Dubai -UAE "}
            cleaner={cleaner}
            required={false}
          />
          <PlacementInput
            handleChange={handleChange}
            value={value.map_link}
            name={"map_link"}
            type={"url"}
            leftIcon={<TfiLocationPin/>}
            placeholder={"map link"}
            cleaner={cleaner}
            required={false}
          />
          <Box pt={4}>
            <DefButton isLoading={isLoading} type='submit'
                       isRound={true}>{value.id ? 'Update' : 'SAVE'}</DefButton></Box>
        </form>
      </Placement>
    </>
  );
};

export default Location;