import React from 'react';
import {Flex, Text} from "@chakra-ui/react";
import {getFormatDate} from "../../../../../config/helpers";
import {ILead} from "../../../../../redux/leads/interface";

const LeadCardAdmin = ({lead, idx}: { lead: ILead, idx: number }) => {
  const {name, contact, comment, created_date, email} = lead
  return (
    <Flex
      alignItems="start"
      rounded="11px"
      mt="10px"
      p="15px"
      h='161px'
      bg="#181818"
      w="100%"
      fontFamily="Poppins"
    >
      <Text
        as="h2"
        color="#FFFFFF"
        fontWeight="700"
        fontSize="15px"
        lineHeight="27px"
        mr='21px'
      >{idx + 1}</Text>
      <Flex
        flexDirection="column"
        alignItems="start"
        w='100%'
      >
        <Flex w="full" justifyContent="space-between" alignItems="start">
          <Flex
            fontFamily="Poppins"
            w={{sm: "90%", base: "70%"}}
            flexDirection="column"
            alignItems="start"
          >
            <Text
              whiteSpace='nowrap'
              as="h2"
              color="#0080DD"
              fontWeight="700"
              fontSize="18px"
              lineHeight="27px"
            >
              {name}
            </Text>
            <Text
              mt='13px'
              color="#A3A3A3"
              fontWeight="300"
              fontSize="14px"
              lineHeight="21px"
            >
              {email}
            </Text>
            <Text
              mt='9px'
              color="#838383"
              fontWeight="300"
              fontSize="14px"
              lineHeight="21px"
              maxW="100%"
            >
              {contact}
            </Text>
          </Flex>

          <Text
            color="#838383"
            fontWeight="300"
            fontSize="12px"
            lineHeight="18px"
            textAlign="end"
            w="full"
          >
            {getFormatDate(created_date)}
          </Text>

        </Flex>
        <Text
          mt='13px'
          color="#838383"
          fontWeight="300"
          fontSize="12px"
          lineHeight="18px"
        >
          {comment}
        </Text>
      </Flex>
    </Flex>
  );
};

export default LeadCardAdmin;