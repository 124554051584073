import {companySlice, getCompanyToChange, resetCompanyToChange} from "./companySlice";
import {AppDispatch} from "../../../../../redux/store";
import {
  addCompanySLice, getCompanyButtonsArray,
  getCompanyLocationId,
  resetLocals,
  uploadMainImage
} from "./addCompany.slice";
import PRIVATE_API, {PUBLIC_API} from "../../../../../api";
import {ICompany, RootCompany} from "../../../../../models/company";
import toast from "react-hot-toast";
import {promiseMsgs} from "../../../../../config/toast";
import {companyDetailSlice} from "./companyDetailSlice";
import {buttonsSlice} from "../button-links/reducer/companyButtons.slice";
import {gallerySLice} from "../upload-photo/reducer/gallery.slice";
import {videoSLice} from "../youtube-link/reducer/youtube.slice";
import {ICorp} from "../../../../admin/CompanyDashboard/reducer/types";


export const addCompany = (value: any, company_id: number, redirect: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(addCompanySLice.actions.addCompany())
    const promiseAl = !!company_id ?
      PRIVATE_API.patch(`api/v1/users/companies/${company_id}/`, value) :
      PRIVATE_API.post('api/v1/users/companies/', value)

    toast.promise(promiseAl, promiseMsgs)
      .then(() => {
        redirect()
        dispatch(addCompanySLice.actions.addedCompany(null))
      })
      .catch(error => {
        dispatch(addCompanySLice.actions.addedCompany(error))
      })
  } catch (e: any) {
    dispatch(addCompanySLice.actions.addedCompany(e.response?.data?.message))
  }
}


export const getCompanies = (card_id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(companySlice.actions.getCompanies())
    const response = await PUBLIC_API.get<RootCompany[]>(`api/v1/users/${card_id}/companies/`)
    dispatch(companySlice.actions.getCompaniesSuccess(response.data))
  } catch (e: any) {
    dispatch(companySlice.actions.getCompaniesError(e.response?.data?.message))
  }
}

export const getCompanyById =
  (card_id: string, company_id: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companyDetailSlice.actions.getCompanyLoading());
      const {data} = await PUBLIC_API.get<RootCompany>(
        `api/v1/users/${card_id}/companies/${company_id}/`
      );
      dispatch(companyDetailSlice.actions.getCompanySuccess(data));
    } catch (e: any) {
      dispatch(companyDetailSlice.actions.getCompanyError(e.message));
    }
  };

export const getCorpCompany =
  (slug: string,) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companyDetailSlice.actions.getCompanyLoading());
      const {data} = await PUBLIC_API.get<ICompany>(`api/v1/corp/${slug}/`)
      dispatch(companyDetailSlice.actions.getCompanySuccess(data));
    } catch (e: any) {
      dispatch(companyDetailSlice.actions.getCompanyError(e.message));
    }
  };


export const deleteCompany = (card_id: string, company_id: number) => async (dispatch: AppDispatch) => {
  try {
    const promise = PRIVATE_API.delete(
      `api/v1/users/companies/${company_id}/`
    ).then(() => dispatch(getCompanies(card_id)))
    toast.promise(promise, {
      loading: 'Loading',
      success: 'company deleted',
      error: 'An error has occurred'
    })
    dispatch(resetAllFields())
  } catch (e) {
    console.log(e)
  }
}


export const copyCompanyToChange = (company: ICompany) => (dispatch: AppDispatch) => {
  console.log('aaa', company)
  dispatch(getCompanyToChange(company))
  dispatch(buttonsSlice.actions.getButtonsCompany(company.menu_buttons))
  dispatch(gallerySLice.actions.getImagesToChange(company.images))
  dispatch(videoSLice.actions.getVideoToChange(company.videos))
  dispatch(getCompanyButtonsArray(company.menu_buttons))
  if (!!company.locations[0]?.id) {
    dispatch(getCompanyLocationId(Number(company.locations[0]?.id)))
  }
  dispatch(uploadMainImage(String(company.preview || '')))
}


export const resetAllFields = () => (dispatch: AppDispatch) => {
  dispatch(resetCompanyToChange())
  dispatch(resetLocals())
  dispatch(buttonsSlice.actions.resetButtons())
  dispatch(gallerySLice.actions.resetImages())
  dispatch(videoSLice.actions.resetVideo())
}


