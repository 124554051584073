import {AppDispatch} from "../../../../redux/store";
import {memberSlice, SearchPayload} from "./member.slice";
import PRIVATE_API from "../../../../api";
import {memberDetailSlice} from "./memberDetail.slice";
import {IMember, IMemberCategory, RootMember} from "../../../../models/member";

export const getMembers = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(memberSlice.actions.getMembers())
    const response = await PRIVATE_API.get<RootMember>('api/v1/users/members/')
    dispatch(memberSlice.actions.getMembersSuccess(response.data))
  }catch (e: any) {
    dispatch(memberSlice.actions.getMembersError(e.response?.data?.message))
  }
}

export const searchMembers = (search: string) => async (dispatch: AppDispatch) => {
  try {
    const {data} = await PRIVATE_API.get<RootMember>(`api/v1/users/members/?search=${search}`)
    const result: SearchPayload = {
      data, search
    }
    dispatch(memberSlice.actions.getSearchResult(result))
  }catch (e: any) {
    dispatch(memberSlice.actions.getMembersError(e.response?.data?.message))
  }
}

export const getMembersDetail = (idx: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(memberDetailSlice.actions.getDetail())
    // const response = await PRIVATE_API.get(`api/v1/users/members/${id}/`)
    // dispatch(memberDetailSlice.actions.getDetailSuccess(response.data))

    const response = await PRIVATE_API.get<RootMember>('api/v1/users/members/')
    const data: IMember = response.data[idx]
    dispatch(memberDetailSlice.actions.getDetailSuccess(data))

  }catch (e: any) {
    dispatch(memberDetailSlice.actions.getDetailError(e.response?.data?.message))
  }
}

export const getMemberCategories = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(memberSlice.actions.getMembers())
    const response = await PRIVATE_API.get<IMemberCategory[]>('api/v1/users/members/categories/')
    dispatch(memberSlice.actions.getCategories(response.data))
  }catch (e: any) {
    dispatch(memberSlice.actions.getMembersError(e.response?.data?.message))
  }
}