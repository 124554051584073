import React, {useRef} from 'react';
import {IContactButton} from "../../../../../models/profile";
import {Box, Flex, Text, Tooltip, useDisclosure, useOutsideClick} from "@chakra-ui/react";
import {navigateToUrl} from "../../../../../config/helpers";

const ContactButtonCard = ({button}: { button: IContactButton }) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const handleClick = () => {
    if (button?.value && button.value.length > 1) {
      onOpen()
    } else {
      navigateToUrl(button.offer + button.value.join("").trim(), button.name === "Mobile");
    }
  };

  const ref = useRef<any>(null)
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  return (
    <>
      <Tooltip label={button.type}>
        <Flex
          onClick={handleClick}
          cursor="pointer"
          bg="#FFFFFF0D"
          position="relative"
          justifyContent="center"
          alignItems="center" overflow='hidden'
          h='52px' rounded='24.5px' p='0'
        >
          <Flex justifyContent='center' alignItems='center' color='#000000FF' w='100%' h='100%' bg='#FFFFFF0D'
                fontSize="1.6rem">
            {button.icon}
          </Flex>
          <Flex
            justifyContent='center' alignItems='center' color="#141414FF" w='100%'
            h='100%' fontFamily='Poppins' fontWeight='500'
            fontSize="15px" bg='rgba(255, 255, 255, 1)'>
            {button.name}
          </Flex>
        </Flex>
      </Tooltip>


      <Flex
        mx='auto' w='100%' position='absolute' hidden={!isOpen}
        left='0' top={`-${button.value.length * 90 + 30}px`} right='0' px={4} zIndex='30'
      >
        <Box bg='#2E2E2E' w='100%' ref={ref} rounded='16px' px='9px' pb='14.93px'>
          {button?.value.map((contact, idx) => (
            <Flex
              key={idx}
              onClick={() => navigateToUrl(button?.offer + contact.trim(), button.name === 'Mobile')}
              justifyContent='space-between' alignItems='center'
              h='90px' borderBottom='1px solid #3D3D3D' cursor='pointer'
              _active={{bg: '#3D3D3D'}} rounded='10px'
            >
              <Box
                borderRight='1px solid #3D3D3D' minW='130px'
                pr={5} pl='2.5' h='33px' noOfLines={1} lineHeight='27px'
                fontFamily='Poppins' fontSize='18px' color='#1894EE' fontWeight='400'
              >{button?.type} {idx + 1}</Box>
              <Text fontFamily='Inter' w='60%' noOfLines={1} fontSize='20px' pr='15px' color='#1894EE'
                    fontWeight='400' lineHeight='24.2px'>
                {contact}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </>
  )
}

export default ContactButtonCard;