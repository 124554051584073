import {AddCompanyReducer} from "./addCompany.slice";
import {ButtonsReducer} from "../button-links/reducer/companyButtons.slice";
import {CompanyReducer} from "./companySlice";
import {GalleryReducer} from "../upload-photo/reducer/gallery.slice";
import {LocationReducer} from "../location/reducer/location.slice";
import {VideosReducer} from "../youtube-link/reducer/youtube.slice";

export const CompanyReducers = {
  CompanyReducer,
  AddCompanyReducer,
  ButtonsReducer,
  GalleryReducer,
  LocationReducer,
  VideosReducer,
}