import React from 'react';

const InstagramSvg = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.8501 19.3087H4.23125C2.02961 19.3087 0.244141 17.5266 0.244141 15.3388V4.2042C0.244141 2.01646 2.03409 0.234375 4.23125 0.234375H14.8501C17.0518 0.234375 18.8373 2.01646 18.8373 4.2042V15.3388C18.8373 17.5311 17.0473 19.3087 14.8501 19.3087ZM4.23125 1.86606C2.91564 1.86606 1.84615 2.91435 1.84615 4.19964V15.3343C1.84615 16.6241 2.91564 17.6679 4.23125 17.6679H14.8501C16.1613 17.6679 17.2352 16.6196 17.2352 15.3343V4.2042C17.2352 2.9189 16.1657 1.87061 14.8501 1.87061H4.23125V1.86606Z"
            fill="#FFFFFF"/>
      <path d="M9.5394 14.7247C6.85895 14.7247 4.67969 12.505 4.67969 9.77494C4.67969 7.04483 6.86343 4.8252 9.5394 4.8252C12.2199 4.8252 14.3991 7.04483 14.3991 9.77494C14.3991 12.5005 12.2199 14.7247 9.5394 14.7247ZM9.5394 6.45688C7.74498 6.45688 6.28617 7.94271 6.28617 9.77494C6.28617 11.6026 7.74498 13.0884 9.5394 13.0884C11.3338 13.0884 12.7971 11.6026 12.7971 9.77494C12.7971 7.94271 11.3338 6.45688 9.5394 6.45688Z"
            fill="#FFFFFF"/>
      <path d="M15.8943 4.56588C15.8943 5.20397 15.3887 5.72355 14.7577 5.72355C14.1312 5.72355 13.6211 5.20397 13.6211 4.56588C13.6211 3.92779 14.1312 3.4082 14.7577 3.4082C15.3842 3.4082 15.8943 3.92779 15.8943 4.56588Z"
            fill="#FFFFFF"/>
    </svg>

  );
};

export default InstagramSvg;