import React from 'react';

const ThreadsSvg = () => {
  return (
    <svg width="19" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3815 9.75473C14.2933 9.71218 14.205 9.67279 14.1136 9.6334C13.9561 6.73099 12.3709 5.07022 9.70802 5.05289C9.69542 5.05289 9.68439 5.05289 9.67178 5.05289C8.07877 5.05289 6.75519 5.73201 5.93899 6.96892L7.4028 7.97263C8.01101 7.04928 8.96745 6.85232 9.67178 6.85232C9.67966 6.85232 9.68754 6.85232 9.69542 6.85232C10.5715 6.85862 11.2333 7.11231 11.6619 7.61022C11.9739 7.97263 12.1818 8.47212 12.2843 9.10397C11.5075 8.97161 10.666 8.93064 9.7679 8.98264C7.23578 9.12918 5.60967 10.6056 5.71839 12.6556C5.77354 13.6955 6.29194 14.5921 7.17748 15.1767C7.92593 15.6714 8.89024 15.9125 9.89395 15.8573C11.2175 15.7849 12.2559 15.2791 12.9807 14.3557C13.5306 13.6545 13.8789 12.7454 14.0333 11.5999C14.6635 11.9812 15.1315 12.4822 15.3899 13.0841C15.8296 14.1083 15.8548 15.7912 14.4823 17.162C13.2801 18.3643 11.8336 18.8842 9.64815 18.9C7.22475 18.8827 5.39065 18.1043 4.19943 16.59C3.08385 15.1719 2.50715 13.122 2.48509 10.4984C2.50715 7.87494 3.08385 5.82655 4.19943 4.40686C5.39065 2.89105 7.22475 2.11424 9.64815 2.0969C12.0905 2.11581 13.9545 2.89578 15.193 4.41789C15.7996 5.16476 16.2566 6.10387 16.5591 7.19897L18.275 6.74044C17.9095 5.39323 17.3343 4.23196 16.5512 3.26921C14.9645 1.31694 12.6451 0.316382 9.65445 0.295898H9.64185C6.6575 0.316382 4.36331 1.32009 2.82229 3.28024C1.45144 5.02452 0.743961 7.4495 0.71875 10.4906V10.4984V10.5063C0.742385 13.5474 1.44987 15.9739 2.82229 17.7167C4.36331 19.6752 6.6575 20.6805 9.64185 20.701H9.65445C12.3063 20.6821 14.1767 19.9888 15.7177 18.4493C17.733 16.4356 17.6715 13.913 17.0082 12.3625C16.5323 11.2532 15.6231 10.3503 14.3815 9.75473ZM9.80099 14.0611C8.69171 14.1241 7.53831 13.6262 7.48158 12.5594C7.43904 11.7685 8.0441 10.8861 9.86874 10.7805C10.0783 10.7679 10.2831 10.7632 10.4833 10.7632C11.1466 10.7632 11.7659 10.8278 12.33 10.9507C12.1204 13.5742 10.8882 14.0012 9.80099 14.0611Z"
            fill="#FFFFFF"/>
    </svg>

  );
};

export default ThreadsSvg;