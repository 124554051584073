import React from 'react';
import {
  Box,
  Button,
  Center,
  // Divider,
  Flex,
  // Modal, ModalContent, ModalFooter, ModalHeader,
  // ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {BsPlusLg} from "react-icons/bs";
import {FileInput} from "../ui";
// import {urlToFile, useFileDecoder} from "../../config/hooks/file-decoder";
// import TitleComponents from "../ui/text/TitleComponents";
import {uploadAvatarFromAdmin, uploadFiles} from "./UploadFile-Reducer/actions";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {BiSolidTrashAlt} from "react-icons/bi";
// import heic2any from "heic2any";
// import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {useLocation} from "react-router-dom";

interface Props {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGradient: (e: React.ChangeEvent<HTMLInputElement>) => void;
  aspect: number;
  uploader: (payload: string) => void;
  is_gradient?: boolean;
  removeImage: () => void;
  image: string;
  inAdmin?: boolean;
}

// interface ISize {
//   width: number;
//   height: number;
//   x: number;
//   y: number
// }


const CropperImage = ({uploader, handleGradient, aspect, is_gradient, removeImage, image, inAdmin}: Props) => {
  const {onClose} = useDisclosure();

  const dispatch = useAppDispatch();
  const {isLoading} = useAppSelector(state => state.UploadFileReducer);
  // const [handleChangeFile, fileUrl] = useFileDecoder();

  // const [fileImg, setFileImg] = useState<File[] | null>(null);
  // const [load, setLoad] = useState<boolean>(false);

  // useEffect(() => {
  //   if (fileImg) {
  //     handleChangeFile(fileImg);
  //     onOpen();
  //   }
  // }, [fileImg]);

  // const convertImages = async (fileArr: File[]) => {
  //   setLoad(true);
  //   const selectedImage = fileArr?.[0];
  //   const mimeType = selectedImage.type;
  //   if (mimeType.toLowerCase().includes('heic') || mimeType.toLowerCase().includes('heif') || !mimeType) {
  //     const heicBuffer = await selectedImage?.arrayBuffer();

  //     const jpegBlob = await heic2any({
  //       blob: new Blob([heicBuffer], {type: mimeType}),
  //       toType: "image/jpeg",
  //     });

  //     if (Array.isArray(jpegBlob)) {
  //       const option = {
  //         type: jpegBlob[0].type,
  //         lastModified: Date.now(),
  //       };
  //       const file = new File([jpegBlob[0]], `${selectedImage.name}.jpeg`, option);
  //       setLoad(false);
  //       setFileImg([file]);
  //     } else {
  //       const option = {
  //         type: jpegBlob.type,
  //         lastModified: Date.now(),
  //       };
  //       const file = new File([jpegBlob], `${selectedImage.name}.jpeg`, option);
  //       setLoad(false);
  //       setFileImg([file]);
  //     }
  //   } else {
  //     setFileImg(fileArr)
  //     setLoad(false);
  //   }
  // };

  // const cropperRef  = useRef<ReactCropperElement>(null)

  const handleChange = (e: React.ChangeEvent<any>) => {
    const value = e.target.files;
    if (value && value.length > 0) {
      // convertImages(value)
      if (!inAdmin) {
        dispatch(uploadFiles(value[0], uploader, onClose))
      }else {
        dispatch(uploadAvatarFromAdmin(value[0], uploader, onClose))
      }
    }
  };

  // const onCrop = () => {
  //   if (cropperRef.current?.cropper !== undefined){
  //     const cropper = cropperRef.current.cropper;
  //     const base64 = cropper.getCroppedCanvas().toDataURL()
  //     dispatch(uploadFiles(urlToFile(base64), uploader, onClose))
  //   }
  // };

  return (
    <Box w='100%' mb={5}>
      <Center w='100%' h='56px' mt='-28px'>
        <Stack as='label' w='56px' h='56px' rounded='50%' zIndex='2' position='relative' display='inline-block'>
          <FileInput handleChange={handleChange} accept={['.png', '.jpg', '.HEIC', '.jpeg', '.svg', '.gif']} multi={false}/>
          <Button
            as='span'
            position='relative'
            display='inline-block'
            cursor='pointer' w='100%' h='100%'
            outline='none' variant='none'
            rounded='50%' bg='white' mx='auto' p='0'
            _focus={{border: 'none'}}
            _active={{bg: '#0080DD'}}
          ><Button
            // isLoading={load || isLoading}
            isLoading={isLoading}
            variant='none' as='div'
            color='#0080DD' fontSize='2.2rem'
            _active={{color: '#FFFFFF'}}
            w='100%' h='100%' justifyContent='center'
            alignItems='center' rounded='50%'>
            <BsPlusLg/></Button>
          </Button>
        </Stack>
      </Center>
      <Flex justifyContent='space-between' w='full'>
        {!!image.length && <Button
          onClick={removeImage}
          // isDisabled={load}
          variant='none' fontSize='1.6rem' p='0'
          w='37px' h='37px' rounded='4px'
          bg='#FFFFFF1A' color='#D92C41' _active={{bg: '#FFFFFF4C'}}
        ><BiSolidTrashAlt/>
        </Button>}
        <Box/>
        <Flex flexDirection='column' alignItems='center'>
          <Switch onChange={handleGradient} isChecked={is_gradient} size='lg'/>
          <Text fontFamily='Poppins' color='#FFFFFF' fontSize='12px' lineHeight='16px'
                mt={1} letterSpacing='0.4px'
                fontWeight='400'>Gradient</Text>
        </Flex>
      </Flex>

      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay bg={'rgba(0,0,0,0.8)'}/>
        <ModalContent bg='#161616' w='100%' h={{sm: 'auto', base: '100%'}} overflowY='auto' className='unscroll'>
          <ModalHeader display={{sm: 'none', base: 'block'}}>
            <TitleComponents>edit</TitleComponents>
          </ModalHeader>
          <Box w={'100%'} h={{sm: 'auto', base: '100%'}} maxH={{sm: 'auto', base: 600}} px={3} pt={{sm: 3, base: 0}}
               position='relative' display='flex' flexDirection='column' justifyContent={'center'}>
            <Cropper
              src={fileUrl}
              style={{ height: 'auto', width: "100%" }}
              initialAspectRatio={aspect}
              aspectRatio={aspect}
              guides={false}
              ref={cropperRef}
            />
          </Box>
          <Box px={3}> <Divider/></Box>
          <ModalFooter display='flex' w='100%' pb={2} px={3} >
            <DefButton handleClick={onClose} border={true}>Undo editing</DefButton>
            <Box w='18px'/>
            <DefButton
              handleClick={onCrop}
              isLoading={isLoading}
            >Save</DefButton>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  );
};

export default CropperImage;
