import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

//local
import { NFCIconSvg } from "../../assets/svg/NFCIconSvg";

const HomeNav: FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      py="5px"
      cursor="pointer"
      onClick={() => {
        window.scroll(0, 0);
        navigate("/");
      }}
    >
      <NFCIconSvg />
    </Box>
  );
};

export default HomeNav;
