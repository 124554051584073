import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import Galochka from "../../../../assets/svg/Galochka";

const TextDot: FC = () => {
  return (
    <Box
      minW="14px"
      h="14px"
      borderRadius="50%"
      fontSize="20px"
      lineHeight="20px"
      color="rgba(255, 255, 255, 1)"
      display="center"
      justifyContent="center"
      alignItems="center"
      bg="rgba(0, 132, 255, 1)"
      fontFamily="Poppins"
      mr="8px"
    >
      <Galochka />
    </Box>
  );
};

export default TextDot;
