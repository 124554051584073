import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowUp } from "react-icons/md";

const FAQuations: FC = () => {
  const SpanText = ({ text }: { text: string }) => {
    return <span style={{ fontWeight: "600", margin: "0 5px" }}>{text}</span>;
  };
  const accordionFAQ = [
    {
      text: "How to use Difital Business Cards?",
      desc: (
        <Text>
          Simply tap your card to the phone, and your digital business card will
          appear on the screen. This makes it easy for your contact to save your
          information, including all relevant details about you and your
          company, with just two clicks. No more manual contact entry required,
          and you can also receive your contact's information in return.
        </Text>
      ),
    },
    {
      text: "In which phones does a smart business card work?",
      desc: (
        <>
          <Box>
            <Text fontWeight="600" pb="5px" fontSize="16px">
              iPhone:
            </Text>
            <Text>iPhone 7, 7 Plus</Text>
            <Text>iPhone 8, 8 Plus</Text>
            <Text>iPhone X</Text>
            <Text>iPhone XR</Text>
            <Text>iPhone XS, XS Max</Text>
            <Text>iPhone 11, 11 Pro and 11 Pro Max</Text>
            <Text>iPhone SE 2020 (2nd generation)</Text>
            <Text>iPhone 12, Mini, 12 Pro and Pro Max</Text>
            <Text>iPhone 13, Mini, 13 Pro and Pro Max</Text>
            <Text>All future iphone models</Text>
            <Text>
              Note: On iPhone 7, 7 Plus, 8, 8 Plus, X, to read the NFC tag, you
              need to select the NFC tag scanner in the control center.
            </Text>
          </Box>
          <Box pt="10px">
            <Text fontWeight="600" pb="5px" fontSize="16px">
              Android:
            </Text>
            <Text>
              All phone models with contactless payment support (Google Play,
              NFC)
            </Text>
            <Text>
              Note: on some Android smartphones, you must first enable NFC in
              the settings.
            </Text>
          </Box>
        </>
      ),
    },
    {
      text: "Does it require an internet connection to work?",
      desc: (
        <>
          <Text>
            Our cards function even
            <SpanText text="without an internet connection." />
            When you tap the card on your phone without an internet connection,
            the link is automatically saved in both the notification center and
            the browser. Once your phone reconnects to the internet, accessing
            your business card is effortless for the user.
          </Text>
          <Text mt="5px">
            <SpanText text=" Second option:" />a built-in personal QR code that
            operates without an internet connection. Simply scanning it allows
            your customer to directly save your contact information on their
            phone.
          </Text>
        </>
      ),
    },
    {
      text: "Can I feature multiple companies on a single card?",
      desc: (
        <Text>
          Yes. We offer a unique feature that allows you to consolidate
          <SpanText text="all your businesses or companies into a single card." />
          Clients can save all your data and contacts simply by saving your
          business card.
        </Text>
      ),
    },
    {
      text: "Is there a performance guarantee?",
      desc: (
        <Text>
          Our warranty is unlimited and lifelong. We provide a
          <SpanText text="100% guarantee" />
          for both the software and the physical card itself.
        </Text>
      ),
    },
    {
      text: "What do we need from the client to get started?",
      desc: (
        <Text>
          Our clients only need to specify the content they want to engrave on
          the card itself, such as their logo, name, and position. You will
          configure all other personal information by yourself in your personal
          profile.
        </Text>
      ),
    },
    {
      text: "Is it a one-time payment or a subscription-based service?",
      desc: (
        <Text>
          When you purchase a card, you make a
          <SpanText text="single payment," />
          and no further payments are needed.
        </Text>
      ),
    },
    {
      text: "Where is the user's data stored and how is it secured?",
      desc: (
        <Text>
          Rest assured that all your data is securely safeguarded and stored on
          <SpanText text="Amazon databases located in the UAE." />
          Our solution adheres to the current legislation of the UAE,
          guaranteeing compliance and peace of mind for your business. Trust in
          our secure infrastructure to keep your information safe.
        </Text>
      ),
    },
    {
      text: "How fast is the delivery?",
      desc: (
        <Text>
          The card is delivered within 3 hours after order confirmation.
        </Text>
      ),
    },
  ];
  return (
    <>
      <Accordion allowToggle pb="15px">
        {accordionFAQ.map((el, idx) => (
          <AccordionItem key={idx} border="none" mb="20px" fontFamily="Poppins">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  fontSize="28px"
                  color="#989898"
                  bg="#EDEDF2"
                  borderRadius="10px"
                  _hover={{ background: "#FFFFFF" }}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="center"
                    fontSize="16px"
                    fontWeight="500"
                    color="#424242"
                  >
                    {`${el.text}`}
                  </Box>
                  {isExpanded ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowRight />
                  )}
                </AccordionButton>
                <AccordionPanel
                  pb={4}
                  textAlign="justify"
                  fontWeight="400"
                  color="#FFFFFF"
                  fontFamily="Poppins"
                  fontSize="14px"
                >
                  {el.desc}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FAQuations;
