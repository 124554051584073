import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

//local
import { SectionComponent } from "../../../../components/ui";
import CompanyDetailSvg from "./svg/CompanyDetailSvg";
import SocialNetworkSvg from "./svg/SocialNetworkSvg";
import MessengersSvg from "./svg/MessengersSvg";
import VideoSvg from "./svg/VideoSvg";
import StatisticsSvg from "./svg/StatisticsSvg";

interface IContact {
  id: number;
  name: string;
  icon: JSX.Element;
}

const contacts: IContact[] = [
  {
    id: 1,
    name: "Company details",
    icon: <CompanyDetailSvg />,
  },
  {
    id: 2,
    name: "Social networks",
    icon: <SocialNetworkSvg />,
  },
  {
    id: 3,
    name: "Messengers",
    icon: <MessengersSvg />,
  },
  {
    id: 4,
    name: "Photo / Video",
    icon: <VideoSvg />,
  },
  {
    id: 5,
    name: "Statistics / Analytics",
    icon: <StatisticsSvg />,
  },
  {
    id: 6,
    name: "Social networks",
    icon: <SocialNetworkSvg />,
  },
  {
    id: 7,
    name: "Messengers",
    icon: <MessengersSvg />,
  },
];

const Features: FC = () => {
  return (
    <Box
      mt={{ md: "100px", base: "90px" }}
      mb={{ md: "80px" }}
      overflow="hidden"
    >
      <SectionComponent>
        <Flex
          w="100%"
          px={2}
          flexDirection="column"
          alignItems="center"
          bg="rgba(44, 72, 172, 1)"
          rounded={{ md: "20px", base: "10px" }}
          pt={{ md: "21px", base: "30px" }}
          pb={"42px"}
          fontFamily="Poppins"
        >
          <Text
            fontWeight="400"
            fontSize={{ md: "56px", base: "32px" }}
            color="rgba(255, 255, 255, 1)"
            textAlign="center"
          >
            Card features
          </Text>
          <Text
            fontWeight="300"
            fontSize="15px"
            color="rgba(255, 255, 255, 1)"
            textAlign="center"
          >
            Our software already integrates solutions for direct access to:{" "}
          </Text>
        </Flex>
      </SectionComponent>

      <Box
        mt={{ md: "44px", base: "20px" }}
        display="flex"
        className="slider-track1"
      >
        {contacts.map((el) => (
          <SliderCard el={el} key={el.id} />
        ))}
        {contacts.map((el) => (
          <SliderCard el={el} key={el.id} />
        ))}
      </Box>

      <Box
        mt={{ md: "44px", base: "20px" }}
        display="flex"
        className="slider-track2"
      >
        {contacts.reverse().map((el) => (
          <SliderCard el={el} key={el.id} />
        ))}
        {contacts.reverse().map((el) => (
          <SliderCard el={el} key={el.id} />
        ))}
      </Box>
    </Box>
  );
};

const SliderCard = ({ el }: { el: IContact }) => {
  return (
    <Box
      mr="8px"
      h={{ md: "64px", base: "40px" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      bg="rgba(29, 28, 32, 1)"
      fontFamily="Poppins"
      mx={2}
      px={{ md: "35px", base: "20px" }}
      py={{ md: "14px", base: "8.3px" }}
      rounded={{ md: "72px", base: "42px" }}
      color="rgba(255, 255, 255, 1)"
      _hover={{ bg: "rgba(52,52,56,0.8)" }}
      transition=".2s"
    >
      <Box mr="10px" transform={{ md: "1", base: "scale(.7)" }}>
        {el.icon}
      </Box>
      <Text
        fontFamily="Poppins"
        fontWeight="300"
        fontSize={{ md: "15px", base: "8.89px" }}
        letterSpacing="1px"
        whiteSpace="nowrap"
        mr="10px"
      >
        {el.name}
      </Text>
    </Box>
  );
};

export default Features;
