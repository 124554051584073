import React, {useEffect, useState} from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {ErrorText} from "../index";

export interface IInputProps {
  name: string;
  placeholder: string;
  cleaner: (name: string) => void;
  type?: string;
  value?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  font?: string;
  error?: string | undefined;
  validation?: boolean;
  required?: boolean;
}

const EditInput = ({
                     name,
                     placeholder,
                     value,
                     handleChange,
                     cleaner,
                     validation,
                     type = "text",
                     font = "Poppins",
                     required = true,
                   }: IInputProps) => {

  const [errorText, setError] = useState('')
  const [isFocus, setFocus] = useState(false)

  function setErrorText() {
    if (!value?.trim().length && required) {
      setError('obligatory field')
    } else if (!!value && value.trim().length >= 60) {
      setError('The number of characters must not exceed 60')
    } else {
      setError('')
    }
  }

  useEffect(() => {
    if (isFocus || validation) setErrorText()
  }, [value, validation])

  return (
    <Box mb='18px'>
      <InputGroup position='relative'>

        <Input
          onChange={handleChange}
          value={value}
          variant='none'
          name={name}
          type={type}
          placeholder={placeholder}
          h='56px' w='100%'
          opacity='0.38'
          borderTopRadius='4px'
          borderBottomRadius='0'
          border='none' bg={'#2E2E2E'}

          fontSize='16px' letterSpacing='0.5px'
          fontWeight='400' fontFamily={font}
          lineHeight='24px' color='#FFFFFF'
          _placeholder={{color: 'white', opacity: '0.38'}}
          onFocus={() => setFocus(true)}
        />
        {!!value?.trim() && <InputRightElement display='flex' alignItems='center' h='full'>
          <Box
            onClick={() => cleaner(name)}
            color='#49454F' fontSize='1.8rem' cursor='pointer'
          ><IoMdCloseCircleOutline/></Box>
        </InputRightElement>}
      </InputGroup>
      <ErrorText>{errorText}</ErrorText>
    </Box>
  );
};

// #15151560

export default EditInput;