export const DeleteIconSvg = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72704 15.1111C1.72704 16.15 2.61759 17 3.70603 17H11.622C12.7104 17 13.601 16.15 13.601 15.1111V3.77778H1.72704V15.1111ZM14.5905 0.944444H11.1272L10.1378 0H5.19028L4.20078 0.944444H0.737549V2.83333H14.5905V0.944444Z"
        fill="#D92C41"
      />
    </svg>
  );
};
