import { Image } from "@chakra-ui/image";
import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

//local
import { useCompanyDetail } from "../../editing/Company/companyReducer/hooks";
import { API_ADDRESS } from "../../../../api";
import DefaultCompanySvg from "../../../../assets/svg/DefaultCompanySvg";
import { INNER_WIDTH } from "../../../../config/_variables";
import { SkeletonCompany } from "../../../../components/skeletons";
import { ArrowRightSvg } from "../../../../assets/svg/ArrowRightSvg";
import { ArrowLeftSvg } from "../../../../assets/svg/ArrowLeftSvg";
import { useEncryption } from "../../../../config/helpers";

interface IProps {
  handleNext: () => void;
  handlePrev: () => void;
  prev: boolean;
  next: boolean;
}

const CompanyInfo: React.FC<IProps> = ({
  handleNext,
  handlePrev,
  next,
  prev,
}) => {
  const { company, isLoading } = useCompanyDetail();

  const { preview, is_gradient, description } = company;

  const [name, font] = useEncryption(company.name);

  const DefaultImage = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        w="100%"
        bg="#A9A9A9"
      >
        <DefaultCompanySvg />
      </Flex>
    );
  };

  if (!!isLoading) {
    return <SkeletonCompany />;
  }

  return (
    <Box pb="0.4rem">
      <Box
        w="full"
        rounded="10px"
        overflow="hidden"
        position="relative"
        h={{ sm: "280px", base: ((INNER_WIDTH - 32) / 5) * 3 + "px" }}
      >
        {!!preview ? (
          <Image
            objectFit="cover"
            borderRadius={10}
            w="100%"
            h="100%"
            src={API_ADDRESS + preview.slice(1)}
            alt="company_image"
          />
        ) : (
          <DefaultImage />
        )}
        <Box
          position="absolute"
          right="0"
          top="40%"
          translateY="-45%"
          display={!!next ? "none" : "block"}
          zIndex="1"
          p="5px"
          cursor="pointer"
          onClick={handleNext}
        >
          <ArrowRightSvg />
        </Box>
        <Box
          position="absolute"
          left="0"
          top="40%"
          translateY="-45%"
          display={!!prev ? "none" : "block"}
          zIndex="1"
          p="5px"
          cursor="pointer"
          onClick={handlePrev}
        >
          <ArrowLeftSvg />
        </Box>
        <Flex
          hidden={!is_gradient}
          w="100%"
          h="152px"
          position="absolute"
          left="0"
          right="0"
          bottom="0"
          bg="linear-gradient(#15151500, #000)"
        />
      </Box>
      <Text
        fontSize="16px"
        textAlign="center"
        lineHeight="22.5px"
        letterSpacing="1.5px"
        fontWeight="600"
        mt="20px"
        mb="10px"
        color="#ffffff"
        fontFamily={font}
        textTransform="uppercase"
      >
        {name}
      </Text>
      <Text
        fontSize="15px"
        textAlign="center"
        lineHeight="22.5px"
        color="#C8C8C8"
        fontWeight="275"
        mb="15px"
      >
        {description}
      </Text>
    </Box>
  );
};

export default CompanyInfo;
