import React, { FC } from "react";
import { Box, Heading } from "@chakra-ui/react";

//local
import { SectionComponent } from "../../../../components/ui";
import { useGetOurClientListQuery } from "../../../../redux/shopApi";
import ClientSlider from "./ClientsSlider";

const OurClients: FC = () => {
  const { data } = useGetOurClientListQuery();

  return (
    <SectionComponent>
      {data?.length && (
        <Box pb="15px" pt={{ base: "80px", md: "0" }}>
          <Heading
            mb={"30px"}
            color={"rgba(255, 255, 255, 1)"}
            fontSize={"36px"}
            fontFamily={"Poppins"}
            fontWeight="400"
            textAlign={{ base: "center", md: "left" }}
          >
            Our clients
          </Heading>
          <Box
            position="relative"
            overflow="hidden"
            borderRadius={{ md: "20px", base: "10px" }}
            mb={{ base: "30px", md: "40px" }}
          >
            <Box className="background__gradient"></Box>
            <ClientSlider ourClient={data} />
          </Box>
        </Box>
      )}
    </SectionComponent>
  );
};

export default OurClients;
