import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

//local
import {useAppSelector} from "../../../redux/store";
import { SectionComponent } from "../../../components/ui";
import SubmitButton from "../components/ui/SubmitButton";
import BasketCard from "./BasketCard";

const BasketPage: FC = () => {
  const navigate = useNavigate();
  const {cart} = useAppSelector(state => state.CartShopReducer)

  const totalPrice = cart.reduce((acc: number, el ) => {
    return acc + el.quantity * el.price
  }, 0)

  return (
    <SectionComponent>
    <Box my="15px" pt={{ md: "90px", base: "70px" }}>
      <Box>
        {cart.map(el => (
          <BasketCard
            key={el.id}
            product={el}
          />
        ))}
      </Box>
      <Box
        bg="#303030"
        my="20px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{sm: 'row', base: 'column'}}
        p="1rem"
        fontFamily="Poppins"
        borderRadius="14px"
      >
        <Text color="white">Total: {totalPrice} AED</Text>
        <Box w={{sm: '221px', base: '100%'}} mt={{sm: '0', base: '4'}} color='black'>
          <SubmitButton
            isDisabled={!cart.length}
            handleClick={() => navigate("/checkout")}
          >Checkout</SubmitButton>
        </Box>
      </Box>
    </Box>
    </SectionComponent>
  );
};

export default BasketPage;
