import React, { FC, useEffect } from "react";
import { Box, Center } from "@chakra-ui/react";

import TitleComponents from "../../../components/ui/text/TitleComponents";
import { useLeads } from "../../../redux/leads/hook";
import { useAppDispatch } from "../../../redux/store";
import { getLeads } from "../../../redux/leads/action";
import LeadCard from "./LeadCard";
import { SkeletonLead } from "../../../components/skeletons";
import NoLeads from "./NoLeads";

const Leads: FC = () => {
  const dispatch = useAppDispatch();
  const { leads, isLoading } = useLeads();

  const repeatedElements = Array.from({ length: 3 }, (_, index) => (
    <SkeletonLead key={index} />
  ));

  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);

  return (
    <Box w="full" pt="68px" h="100%">
      <Box
        bg="black"
        position="fixed"
        zIndex="2"
        top="0"
        left="0"
        right="0"
        pt={6}
        pb={1}
      >
        <Center>
          <TitleComponents>Leads</TitleComponents>
        </Center>
      </Box>
      <Box
        borderTopRadius="25px"
        bg="#151515"
        minH="100vh"
        className="unscroll"
        pt="20.74px"
      >
        {!!isLoading ? (
          repeatedElements
        ) : (
          <Box borderTopRadius="25px" px="2.5" pb={4}>
            {leads.length > 0 ? (
              leads.map((lead) => <LeadCard key={lead.id} lead={lead} />)
            ) : (
              <NoLeads />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Leads;
