import {IContactButton, IParentButtons} from "../../../models/profile";
import {API_ADDRESS} from "../../../api";
import CallSvg from "./ButtonsSvg/CallSvg";
import WhatsAppSvg from "./ButtonsSvg/WhatsAppSvg";
import EmailSvg from "./ButtonsSvg/EmailSvg";
import WebSiteSvg from "./ButtonsSvg/WebSiteSvg";
import LinkSvg from "./ButtonsSvg/LinkSvg";
import DocumentSvg from "./ButtonsSvg/DocumentSvg";
import InstagramSvg from "./ButtonsSvg/InstagramSvg";
import TelegramSvg from "./ButtonsSvg/TelegramSvg";
import FacebookSvg from "./ButtonsSvg/FacebookSvg";
import LinkedinSvg from "./ButtonsSvg/LinkedinSvg";
import TwitterSvg from "./ButtonsSvg/TwitterSvg";
import SnapchatSvg from "./ButtonsSvg/SnapchatSvg";
import YoutubeSvg from "./ButtonsSvg/YoutubeSvg";
import TiktokSvg from "./ButtonsSvg/TiktokSvg";
import ThreadsSvg from "./ButtonsSvg/ThreadsSvg";





const contacts: IContactButton[] = [
  {
    type_id: 1,
    icon: <CallSvg/>,
    name: 'Mobile',
    placeholder: '+971',
    type: 'Phone Number',
    type_input: 'tel',
    value: [],
    href: "",
    offer: "tel:",
  },
  {
    type_id: 2,
    icon: <WhatsAppSvg/>,
    name: 'WhatsApp',
    placeholder: '+971',
    type: 'WhatsApp',
    type_input: 'tel',
    value: [],
    href: "https://wa.me/",
    offer: "",
  },
  {
    type_id: 3,
    icon: <EmailSvg/>,
    name: 'Email',
    placeholder: 'example@gmail.com',
    type: 'eMail',
    type_input: 'email',
    value: [],
    href: "",
    offer: "mailto:",
  },
]

const web_links: IContactButton[] = [
  {
    type_id: 4,
    icon: <WebSiteSvg/>,
    name: 'WWW',
    placeholder: 'website link',
    type: 'Web site',
    type_input: 'url',
    value: [],
    href: "",
    offer: "",
  },
  {
    type_id: 5,
    icon: <LinkSvg/>,
    name: 'Link',
    placeholder: 'Paste link',
    type: 'Web link',
    type_input: 'url',
    value: [],
    href: "",
    offer: ""
  },
  {
    type_id: 6,
    icon: <DocumentSvg/>,
    name: 'Doc.name',
    placeholder: '(PDF, Word)',
    type: 'Document',
    type_input: 'file',
    value: [],
    href: `${API_ADDRESS}`,
    offer: ""
  },
]

const social_network: IContactButton[] = [
  {
    type_id: 7,
    icon: <InstagramSvg/>,
    name: 'Instagram',
    placeholder: 'nickname',
    type: 'Instagram',
    type_input: 'text',
    value: [],
    href: "https://instagram.com/",
    offer: ""
  },
  {
    type_id: 8,
    icon: <TelegramSvg/>,
    name: 'Telegram',
    placeholder: '@account name',
    type: 'Telegram',
    type_input: 'text',
    value: [],
    href: "https://t.me/",
    offer: ""
  },
  {
    type_id: 9,
    icon: <FacebookSvg/>,
    name: 'Facebook',
    placeholder: '@account name',
    type: 'Facebook',
    type_input: 'text',
    value: [],
    href: "https://facebook.com/",
    offer: ""
  },
  {
    type_id: 10,
    icon: <LinkedinSvg/>,
    name: 'Linkedin',
    placeholder: 'Paste link',
    type: 'Linkedin',
    type_input: 'url',
    value: [],
    href: "",
    offer: ""
  },
  {
    type_id: 11,
    icon: <TwitterSvg/>,
    name: 'Twitter',
    placeholder: '@account name',
    type: 'Twitter',
    type_input: 'text',
    value: [],
    href: "https://twitter.com/",
    offer: ""
  },

  {
    type_id: 12,
    icon: <SnapchatSvg/>,
    name: 'Snapchat',
    placeholder: 'account name',
    type: 'Snapchat',
    type_input: 'text',
    value: [],
    href: "https://www.snapchat.com/add/",
    offer: ""
  },
  {
    type_id: 13,
    icon: <YoutubeSvg/>,
    name: 'YouTube',
    placeholder: 'Paste link',
    type: 'YouTube',
    type_input: 'url',
    value: [],
    href: "",
    offer: ""
  },
  {
    type_id: 14,
    icon: <TiktokSvg/>,
    name: 'TikTok',
    placeholder: '@account name',
    type: 'TikTok',
    type_input: 'text',
    value: [],
    href: "https://tiktok.com/",
    offer: ""
  },
  {
    type_id: 15,
    icon: <ThreadsSvg/>,
    name: 'Threads',
    placeholder: '@account name',
    type: 'Threads',
    type_input: 'text',
    value: [],
    href: "https://www.threads.net/",
    offer: ""
  },
]


export const contact_buttons: IParentButtons[] = [
  {
    _id: 101,
    name: 'CONTACTS',
    value: contacts
  },
  {
    _id: 102,
    name: 'WEB-LINKS',
    value: web_links
  },
  {
    _id: 103,
    name: 'SOCIAL  NETWORK',
    value: social_network
  },
]

export const allButtonData:IContactButton[] = [...contacts, ...web_links, ...social_network]
