import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";

//local
import { setIsMessageEmail } from "../../redux/loginFormInterface/action";
import { useAppDispatch } from "../../redux/store";
import { WhiteButton } from "../ui";

type Props = {
  text: string;
  onClose?: () => void;
};

const MessageAfterEmail: FC<Props> = ({ text, onClose }) => {
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Text
        color="#FFFFFF"
        textAlign="center"
        fontFamily="Poppins"
        fontSize="15px"
        lineHeight="22.5px"
        py="1.4rem"
      >
        {text}
      </Text>
      <WhiteButton
        children="OK"
        handleClick={
          onClose ? onClose : () => dispatch(setIsMessageEmail(false))
        }
      />
    </Box>
  );
};

export default MessageAfterEmail;
