import React, {useEffect, useState} from 'react';
import {Box, Container, Flex} from "@chakra-ui/react";
import {
  DefButton,
  EditInputFont,
  EditTextarea,
} from "../../../../components/ui";
import YoutubeLink from "./youtube-link";
import UploadPhoto from "./upload-photo";
import CropperImage from "../../../../components/Cropper";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {addCompany, getCompanies, resetAllFields} from "./companyReducer/actions";
import {toEncryption, useEncryption} from "../../../../config/helpers";
import {removeImage, uploadMainImage} from "./companyReducer/addCompany.slice";
import ButtonLinks from "./button-links";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {loadImage} from "../../../../components/Cropper/UploadFile-Reducer/actions";
import CompanyPreview from "../../../../components/ui/CompanyPreview";
import {INTERFACE_WIDTH} from "../../../../config/_variables";
import {editCorp, getCorpSlug} from "../../../admin/CompanyDashboard/reducer/actions";

interface Props {
  inAdmin?: boolean
  onCloseAdmin?: () => void
}

const CompanyEdit = ({inAdmin = false, onCloseAdmin}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {user} = useAppSelector((state) => state.ProfileReducer);
  const {company_name_font} = useAppSelector(state => state.FontReducer)
  const {
    id: company_id,
    name: company_name,
    description,
    is_gradient
  } = useAppSelector(state => state.CompanyReducer.company_to_change)
  const [name, fontName] = useEncryption(company_name)
  const {videos} = useAppSelector(state => state.VideosReducer)
  const {images} = useAppSelector(state => state.GalleryReducer)
  const {locations, preview, isLoading, buttons} = useAppSelector(state => state.AddCompanyReducer)
  const [validation, setValid] = useState(false)
  const [value, setValue] = useState({
    preview,
    name,
    description,
    is_gradient,
    menu_buttons: [],
    locations: [],
    images: [],
    videos: [],
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }
  const cleaner = (name: string) => {
    setValue({...value, [name]: ''})
  }
  const handleGradient = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, is_gradient: e.target.checked})
  }

  const redirect = async () => {
    await dispatch(resetAllFields())
    if (!inAdmin) {
      navigate('/profile')
      dispatch(getCompanies(user.card_id))
    } else {
      if (onCloseAdmin) {
        onCloseAdmin()
      }
    }
  }

  const onSubmit = () => {
    if (!value.name.trim()) {
      setValid(true)
      toast('Please fill in the required fields', {icon: '⚠'})
    } else {
      setValid(false)
      const data = {
        ...value, locations, preview,
        menu_buttons: buttons,
        images: images.map(el => el.id),
        videos: videos.map(el => el.id),
        name: toEncryption(value.name, company_name_font),
      }

      if (!inAdmin) {
        dispatch(addCompany(data, company_id, redirect))
      } else {
        dispatch(editCorp(data, redirect))
      }
    }
  }


  const uploader = (payload: string) => {
    dispatch(uploadMainImage(payload))
  }

  useEffect(() => {
    dispatch(loadImage(preview))
  }, [preview])

  return (
    <Box w='full' mx='0' pb="90px">
      <CompanyPreview preview={preview} is_gradient={value.is_gradient}/>
      <CropperImage
        handleGradient={handleGradient}
        removeImage={() => dispatch(removeImage())}
        aspect={5 / 3}
        uploader={uploader}
        is_gradient={value.is_gradient}
        image={preview}
        inAdmin={inAdmin}
      />

      <EditInputFont
        handleChange={handleChange}
        cleaner={cleaner}
        name={'name'}
        placeholder={'Company Name'}
        value={value.name}
        default_font={fontName}
        validation={validation}
      />
      <EditTextarea
        handleChange={handleChange}
        cleaner={cleaner}
        name={'description'}
        placeholder={'Text'}
        value={value.description}
        validation={validation}
        required={false}
      />

      <ButtonLinks inAdmin={inAdmin}/>

      <YoutubeLink inAdmin={inAdmin}/>

      <UploadPhoto inAdmin={inAdmin}/>

      <Flex position="fixed" bottom="0" left="0" right="0" py="5px" zIndex="4">
        <Container maxW={INTERFACE_WIDTH}>
          <Box boxShadow="0 -15px 30px 0 rgba(0,0,0,0.3)" rounded="8px">
            <DefButton isLoading={isLoading} handleClick={onSubmit} mb={0}>
              {!!name ? "Update" : "SAVE"}
            </DefButton>
          </Box>
        </Container>
      </Flex>
    </Box>
  );
};

export default CompanyEdit;