import {ICompanyImages} from "../../../../../../models/company";
import {AppDispatch} from "../../../../../../redux/store";
import {uploadSlice} from "../../../../../../components/Cropper/UploadFile-Reducer/upload.slice";
import PRIVATE_API from "../../../../../../api";
import {gallerySLice} from "./gallery.slice";
import {toastCreator} from "../../../../../../config/toast";
import toast from "react-hot-toast";
import PRIVATE_API_ADMIN from "../../../../../../api/admin/API";

export const uploadImages = (files: any[], value: ICompanyImages, onClose: () => void, inAdmin: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(uploadSlice.actions.uploadFile())
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    Promise.all(files.map(async (file) => {
      let formData = new FormData()
      formData.append('file', file)
      const res = inAdmin ? await PRIVATE_API_ADMIN.post('api/v1/users/upload/', formData, config) :
        await PRIVATE_API.post('api/v1/users/upload/', formData, config)
      return res.data.path
    })).then(image => {
      if (inAdmin) {
        dispatch(addGalleryAdmin({...value, image}))
      } else {
        dispatch(addGallery({...value, image}))
      }
    }).catch(e => {
      dispatch(uploadSlice.actions.uploadFileError(e.message))
    }).then(() => {
      dispatch(uploadSlice.actions.uploadFileSuccess())
      onClose()
    })
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(uploadSlice.actions.uploadFileError(e.response?.data?.message))
  }
}


export const addGallery = (value: ICompanyImages) => async (dispatch: AppDispatch) => {
  try {
    const data = {
      ...value,
      image: JSON.stringify(value.image)
    }
    dispatch(gallerySLice.actions.addGallery())
    const response = value.id ?
      await PRIVATE_API.put(`api/v1/users/company_images/${data.id}/`, data) :
      await PRIVATE_API.post('api/v1/users/company_images/', data)
    dispatch(gallerySLice.actions.addedGallery(null))
    dispatch(gallerySLice.actions.getImages(response.data))
    toastCreator('success')
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(gallerySLice.actions.addedGallery(e.message))
  }
}


export const addGalleryAdmin = (value: ICompanyImages) => async (dispatch: AppDispatch) => {
  try {
    const data = {
      ...value,
      image: JSON.stringify(value.image)
    }
    dispatch(gallerySLice.actions.addGallery())
    const response = value.id ?
      await PRIVATE_API_ADMIN.put(`api/v1/users/company_images/${data.id}/`, data) :
      await PRIVATE_API_ADMIN.post('api/v1/users/company_images/', data)
    dispatch(gallerySLice.actions.addedGallery(null))
    dispatch(gallerySLice.actions.getImages(response.data))
    toastCreator('success')
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(gallerySLice.actions.addedGallery(e.message))
  }
}

export const deleteImages = (id: number, inAdmin: boolean) => async (dispatch: AppDispatch) => {
  try {
    inAdmin ? await PRIVATE_API_ADMIN.delete(`api/v1/users/company_images/${id}/`) :
    await PRIVATE_API.delete(`api/v1/users/company_images/${id}/`)
    dispatch(gallerySLice.actions.removeImage(id))
    toast.success('Images deleted')
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
  }
}